<div class="contenido">
    <img src="assets/fr2.png" class=" d-block w-100 " alt="...">

    <img class="card-img-top rounded espacio" src="assets/Menu1-izq.png" alt="Card image cap 1 izquierda ">
    <img class="card-img-top rounded espacio" src="assets/Menu1-der.png" alt="Card image cap 1 derecha ">
    <img class="card-img-top rounded espacio" src="assets/Menu2-izq.png" alt="Card image cap 2 izquierda">
    <img class="card-img-top rounded espacio" src="assets/Menu2-der.png" alt="Card image cap 2 derecha">
    <img class="card-img-top rounded espacio" src="assets/Menu3-izq.png" alt="Card image cap 3 izquierda">
    <img class="card-img-top rounded espacio" src="assets/Menu3-der.png" alt="Card image cap 3 derecha">

</div>


<app-footer></app-footer>