<h2 mat-dialog-title>Ensalada</h2>

<mat-dialog-content class="pt-3" [formGroup]="comandaform">

    <h4>Selecciona tu tamaño</h4>
   
        <mat-radio-group  formControlName="tamano" aria-label="Select an option">
             <div class="mb-2">
                <mat-radio-button value="chica">Chica</mat-radio-button>
                <mat-radio-button value="mediana">Mediana</mat-radio-button>
                <mat-radio-button value="grande">Grande</mat-radio-button>
            </div>
        </mat-radio-group>
    <h4>Selecciona tus proteina</h4>
    <mat-radio-group formControlName="proteina" aria-label="Select an option">
        <div class="mb-2">
             <mat-radio-button value="pollo natural">Pollo natural</mat-radio-button>
            <mat-radio-button value="pollo bbq">Pollo bbq</mat-radio-button>
            <mat-radio-button value="jamón">Jamón</mat-radio-button>
            <mat-radio-button value="pollo frito">Pollo frito</mat-radio-button>
            <mat-radio-button value="pollo especies">pollo especies</mat-radio-button>
            <mat-radio-button value="atún">Atún</mat-radio-button>
        </div>
       
    </mat-radio-group>

    <h4>Selecciona tus ingredientes</h4>
    <div class="ingredientes" *ngFor="let control of ingredientesArray.controls; let i = index;">
        <mat-checkbox [checked]="todos" (change)="getselectedingredientes()" [formControl]="control" type="checkbox">{{ingredientess[i]}}</mat-checkbox>
    </div>

    <h4>Elige un aderezo</h4>

    <mat-radio-group formControlName="aderezos" aria-label="Select an option">
        <div class="mb-2">
            <mat-radio-button value="ranch">Ranch</mat-radio-button>
            <mat-radio-button value="mil islas">Mil islas</mat-radio-button>
            <mat-radio-button value="cesar">Cesar</mat-radio-button>
            <mat-radio-button value="italiano">Italiano</mat-radio-button>
            <mat-radio-button value="mostaza miel">Mostaza miel</mat-radio-button>
            <mat-radio-button value="chimichurry">Chimichurry</mat-radio-button>
        </div>
    </mat-radio-group>
    <h4>Elige un Complemento</h4>

    <mat-radio-group formControlName="complementos" aria-label="Select an option">
        <div class="mb-2">
            <mat-radio-button value="parmesano">Parmesano</mat-radio-button>
            <mat-radio-button value="crotones">Crotones</mat-radio-button>
            <mat-radio-button value="chilequebrado">Chile</mat-radio-button>
            <mat-radio-button value="almendras">Almendras</mat-radio-button>
          <mat-radio-button value="aceitunas">Aceitunas</mat-radio-button>
        </div>
    </mat-radio-group>

    <h4>Agrega Extras</h4>
    <!--    <mat-form-field appearance="fill">
        <mat-label>Extras</mat-label>
        <mat-select formControlName="extras" multiple>

            <mat-option *ngFor="let complementos of TodosExtra" [value]="complementos">
                {{complementos.viewValue}} ${{complementos.precio}}
            </mat-option>
        </mat-select>
    </mat-form-field>
     -->
    <mat-form-field appearance="fill">
        <mat-label>Extras</mat-label>
        <mat-select formControlName="extras" multiple>

            <mat-optgroup *ngFor="let group of TodosExtra01" [label]="group.nombre">

                <mat-option *ngFor="let complementos of group.extra" [value]="complementos">
                    {{complementos.viewValue}} ${{complementos.precio}}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="comandaform.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>