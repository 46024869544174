import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-quesadillas',
  templateUrl: './dialog-quesadillas.component.html',
  styleUrls: ['./dialog-quesadillas.component.css']
})
export class DialogQuesadillasComponent implements OnInit {
  quesadilasForm: FormGroup;
  ingredientess: Array<any> = [
    'Zanahoria',
    'Tomate',
    'Betabel',
    'Champinon',
    'Jalapeno',
    'Cebolla',
    'Aceituna',
    'Piña',
    'Morron',
    'Panela',
    'Pasta',
    'Apio',
    'Colmorado',
    'Pepino',
    'Espinaca',
    'Germinado',
    'Elote',
    'Brocoli'
  ];
  toggleVeg = false;
  toggleFran = false;
  get ingredientesArray(): any{
    return this.quesadilasForm.get('ingredientes')as FormArray;
  }
  selectedingredientes = [] as any;

  constructor(public fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogQuesadillasComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {

      this.quesadilasForm = this.fb.group({
        tipo: new FormControl('', Validators.required),
        ingredientes : this.addingredientes(),
        seleccionados: new FormControl(''),
      });
      this.selectedingredientes = [] as any ;
     }

  ngOnInit(): void {
    this.quesadilasForm = this.fb.group({
      tipo: new FormControl('', Validators.required),
      ingredientes : this.addingredientes(),
      seleccionados: new FormControl(''),
    });
  }
  addingredientes(): any{
    const arr = this.ingredientess.map( element => {
      return this.fb.control(false);
    });

    return this.fb.array(arr);

  }
  getselectedingredientes(): any{
    this.selectedingredientes = [] as any ;
    this.ingredientesArray.controls.forEach((control: any, i: any) => {
      if (control.value){
        if (this.selectedingredientes.length < 4){
           this.selectedingredientes.push(this.ingredientess[i]);
        }

      }
    });
  

    this.quesadilasForm.controls['seleccionados'].setValue(this.selectedingredientes.join(', '));
    

  }
  comprobarBagVegetaria(i: any): any{

    const b = this.quesadilasForm.value.tipo;

    if (b !== 'vegetariano'){
       this.toggleVeg = false;
      }else{
         this.toggleVeg = true;
      }

  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.quesadilasForm.value.tipo === '' ){
      console.log('no hay datos: secundario');
    }

    if (this.quesadilasForm.value){
      this.dialogRef.close(this.quesadilasForm.value);
    }

  }

}
