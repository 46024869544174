import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-licuados',
  templateUrl: './dialog-licuados.component.html',
  styleUrls: ['./dialog-licuados.component.css']
})
export class DialogLicuadosComponent implements OnInit {

  licuadosForm:FormGroup;
  constructor(public fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogLicuadosComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) { 
      
      this.licuadosForm = this.fb.group({
        tipo: new FormControl('', Validators.required)
      });
    }

  ngOnInit(): void {
    
    this.licuadosForm = this.fb.group({
      tipo: new FormControl('', Validators.required)
    });
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.licuadosForm.value.tipo === '' ){
      console.log('no hay datos: secundario');
    }

    if (this.licuadosForm.value){
      this.dialogRef.close(this.licuadosForm.value);
    }

  }

}
