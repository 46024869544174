import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenutoggleService {

 
  isSidebarVisible: boolean;

  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();


  constructor() {
    this.isSidebarVisible = false;
  }
  toggleSidebarVisibilty() {
    this.isSidebarVisible = !this.isSidebarVisible;
    this.sidebarVisibilityChange.next(this.isSidebarVisible);
  }
}
