import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  formulario: FormGroup;
  mensajeCorreo:string;
  clienteCorreo:string;
  espacio="%20";
  mensajeAcomodo:string;
  emailCorreo = 'sahid_jimenez95@hotmail.com';
  emailFresh = 'freshngreencampeche@hotmail.com';
  motivoCorreo ='Correo%20desde%20la%20pagina%20de%20fresh'; 
  constructor(public fb: FormBuilder) { 

    this.mensajeCorreo='';
    this.clienteCorreo='';

    this.mensajeAcomodo ='Este es un correo enviado por: ';
    
    this.formulario = this.fb.group({
      nombre: new FormControl('',[Validators.required]),
     /*  correo: new FormControl('',[Validators.required,Validators.email]), */
      texto: new FormControl('',[Validators.required]),
      
     });

  }

  ngOnInit(): void {
  }

  enviar(mensaje:any){

    console.log(mensaje);

  }

}
