import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-omelet',
  templateUrl: './dialog-omelet.component.html',
  styleUrls: ['./dialog-omelet.component.css']
})
export class DialogOmeletComponent implements OnInit {

  omeletForm: FormGroup;
  ingredientess: Array<any> = [
    'Zanahoria',
    'Tomate',
    'Betabel',
    'Champinon',
    'Jalapeno',
    'Cebolla',
    'Aceituna',
    'Piña',
    'Morron',
    'Panela',
    'Pasta',
    'Apio',
    'Colmorado',
    'Pepino',
    'Espinaca',
    'Germinado',
    'Elote',
    'Brócoli',
    'Jamón',
    'Chorizo',
    'Queso manchego'
  ];
  selectedingredientes = [] as any;
  get ingredientesArray(): any{

    return this.omeletForm.get('ingredientes')as FormArray;

  }
  constructor(public fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogOmeletComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {

  this.omeletForm = this.fb.group({
    ingredientes: this.addingredientes(),
    seleccionados: new FormControl('', Validators.required)
  });
}

  ngOnInit(): void {
    this.omeletForm = this.fb.group({
      ingredientes: this.addingredientes(),
      seleccionados: new FormControl('', Validators.required)
    });

    
  }
  getselectedingredientes(): any{
    this.selectedingredientes = [] as any ;
    this.ingredientesArray.controls.forEach((control: any, i: any) => {
      if (control.value){
        if (this.selectedingredientes.length < 4){
        this.selectedingredientes.push(this.ingredientess[i]);
        }
      }
    });
    this.omeletForm.controls['seleccionados'].setValue(this.selectedingredientes.join(', '));

   /*  console.log(this.selectedingredientes.join(',')); */
  }
  addingredientes(): any{
    const arr = this.ingredientess.map( element => {
      return this.fb.control(false);
    });
    return this.fb.array(arr);
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.omeletForm.value.seleccionados === '' ){
      console.log('no hay datos: secundario');
    }

    if (this.omeletForm.value){
      this.dialogRef.close(this.omeletForm.value);
    }

  }

}
