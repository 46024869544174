import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-chilaquiles',
  templateUrl: './dialog-chilaquiles.component.html',
  styleUrls: ['./dialog-chilaquiles.component.css']
})
export class DialogChilaquilesComponent implements OnInit {

  chilaquilesForm: FormGroup;
  constructor(public fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogChilaquilesComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {


    this.chilaquilesForm = this.fb.group({
      tipo: new FormControl('', Validators.required)
    });

  }

  ngOnInit(): void {
    this.chilaquilesForm = this.fb.group({
      tipo: new FormControl('', Validators.required)
    });
  }

  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.chilaquilesForm.value.tipo === '' ){
      console.log('no hay datos: secundario');
    }

    if (this.chilaquilesForm.value){
      this.dialogRef.close(this.chilaquilesForm.value);
    }

  }

}
