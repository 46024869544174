<h2 mat-dialog-title>jugos de frutas combinada</h2>

<mat-dialog-content class="pt-3" [formGroup]="JugofrutascombinadaForm">
    <h3>Tamaño</h3>
    <mat-radio-group formControlName="tamano" aria-label="Select an option">
        <mat-radio-button value="copa">Copa</mat-radio-button>
        <mat-radio-button value="jarra">Jarra</mat-radio-button>

    </mat-radio-group>
    <br><br>
    <h3>Combinaciones</h3>
    <mat-radio-group formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="jugo verde">jugo verde</mat-radio-button>
        <mat-radio-button value="jugo de zanahoria">jugo de zanahoria</mat-radio-button>
        <mat-radio-button value="naranja/zanahoria">Naranja/zanahoria</mat-radio-button>
        <mat-radio-button value="betabel/zanahoria">Betabel/zanahoria</mat-radio-button>
        <mat-radio-button value="naranja/ betabel">Naranja/ betabel</mat-radio-button>
        
    </mat-radio-group>

</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="JugofrutascombinadaForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>