<h2 mat-dialog-title>Licuados</h2>

<mat-dialog-content class="pt-3" [formGroup]="licuadosForm">

    <mat-radio-group formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="melón">Melón</mat-radio-button>
        <mat-radio-button value="Papaya">Papaya</mat-radio-button>
        <mat-radio-button value="avena">Avena</mat-radio-button>
        <mat-radio-button value="platano">Platano</mat-radio-button>
        <mat-radio-button value="fresa">Fresa</mat-radio-button>
        <mat-radio-button value="chocomil">Chocomilk</mat-radio-button>
       
    </mat-radio-group>

</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="licuadosForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>