import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  irPedido(){
    this.router.navigate(['/ordenar']);
  }

  irMenu(){
    this.router.navigate(['/menu']);
  }
  irnosotros(){
    this.router.navigate(['/nosotros']);
  }
  ircontacto(){
    this.router.navigate(['/contacto']);
  }

}
