<h2 mat-dialog-title>Aguas de fruta</h2>

<mat-dialog-content class="pt-3" [formGroup]="jugoNaranjaForm">
    <h3>Tamaño</h3>

    <mat-radio-group formControlName="tamano" aria-label="Select an option">
        <mat-radio-button value="copa">Copa</mat-radio-button>
        <mat-radio-button value="jarra">Jarra</mat-radio-button>
    </mat-radio-group>

</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="jugoNaranjaForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>