import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Compoenentes */
import { IndexComponent } from './index/index.component';
import { indexRoutes } from './index/index.routes';

const routes: Routes = [
  {
    path: '', component: IndexComponent,
    children: indexRoutes,

  },
  {path: '**', redirectTo: ''}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
