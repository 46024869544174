<h2 mat-dialog-title>Refrescos embotellados</h2>

<mat-dialog-content class="pt-3" [formGroup]="refrescosEmbotelladosForm">

    <mat-radio-group formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="medio">Agua 1/2 L</mat-radio-button>
        <mat-radio-button value="litro">Agua 1 L</mat-radio-button>
        <mat-radio-button value="mineral">Agua mineral</mat-radio-button>
        <mat-radio-button value="cocacola">Cocacola</mat-radio-button>

    </mat-radio-group>

</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="refrescosEmbotelladosForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>