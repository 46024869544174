<h2 mat-dialog-title>Molletes</h2>
<div>
    <mat-checkbox class="example-margin" (change)="combinar()">Hacer mitad y mitad</mat-checkbox>
</div>

<mat-dialog-content class="pt-3" [formGroup]="molletesForm">
    <h3 *ngIf="combinado">Primer tipo Molletes</h3>
    <h3 *ngIf="combinado == false">Tipos de Molletes</h3>
    <mat-radio-group formControlName="tipo1" aria-label="Select an option">
        <mat-radio-button value="naturales">Naturales (solo queso)</mat-radio-button>
        <mat-radio-button value="chorizo">Chorizo</mat-radio-button>
        <mat-radio-button value="pollo deshebrado">Pollo deshebrado</mat-radio-button>
        <mat-radio-button value="champiñon">Champiñon</mat-radio-button>
        <mat-radio-button value="jamon">Jamon</mat-radio-button>
    </mat-radio-group>

    <div *ngIf="combinado">
        <h3>Segundo tipo Molletes</h3>
        <mat-radio-group formControlName="tipo2" aria-label="Select an option">
            <mat-radio-button value="naturales">Naturales (solo queso)</mat-radio-button>
            <mat-radio-button value="chorizo">Chorizo</mat-radio-button>
            <mat-radio-button value="pollo deshebrado">Pollo deshebrado</mat-radio-button>
            <mat-radio-button value="champiñon">Champiñon</mat-radio-button>
            <mat-radio-button value="jamon">Jamon</mat-radio-button>
        </mat-radio-group>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="molletesForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>