<div class="contenido">
    <img src="assets/fr2.png" class=" d-block w-100 " alt="...">

    <!-- centro -->
    <div class="container">

        <div class="espacio">

            <!-- GUADALAJARA -->
            <div class="row justify-content-center">
                <div class="col-auto">
                    <i class="fab fa-envira tamanoFlo"></i>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <h2>Guadalajara</h2>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <p>interior Mdo. Libertad Local 2018</p>
                </div>
            </div>
            <div class="row justify-content-center">

                <div class="col-auto">

                    <a mat-flat-button class="verde boton" href="tel:333-618-8112"><i class="fas fa-phone tamanoTel"></i> Contactar</a>
                </div>
            </div>
        </div>

        <div class="espacio">
            <!-- CAMPECHE -->
            <div class="row justify-content-center">
                <div class="col-auto">
                    <i class="fab fa-envira tamanoFlo"></i>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <h2>Campeche</h2>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <p>Calle 59, #5 Col. Centro</p>
                </div>
            </div>
            <div class="row justify-content-center">

                <div class="col-auto">

                    <a mat-flat-button class="verde boton" href="tel:981-127-1513"><i class="fas fa-phone tamanoTel"></i> Contactar</a>
                </div>
            </div>
        </div>
        <div class="espacio">
            <!-- CHIHUAHUA -->
            <div class="row justify-content-center">
                <div class="col-auto">
                    <i class="fab fa-envira tamanoFlo"></i>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <h2>Chihuahua</h2>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <p>Cd Judicial, Local 1 Cafeteria</p>
                </div>
            </div>
            <div class="row justify-content-center">

                <div class="col-auto">

                    <a mat-flat-button class="verde boton" href="tel:614-415-2673"><i class="fas fa-phone tamanoTel"></i> Contactar</a>
                </div>
            </div>
        </div>
        <div class="espacio">
            <!-- MONTERREY -->
            <div class="row justify-content-center">
                <div class="col-auto">
                    <i class="fab fa-envira tamanoFlo"></i>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <h2>Monterrey</h2>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <p>Centro Coahutemoc (POR APERTURAR)</p>
                </div>
            </div>
            <div class="row justify-content-center">

                <div class="col-auto">

                    <a mat-flat-button class="verde boton" href="#"><i class="fas fa-phone tamanoTel"></i> Contactar</a>
                </div>
            </div>
        </div>
        <div class="espacio">
            <!-- CDMX -->
            <div class="row justify-content-center">
                <div class="col-auto">
                    <i class="fab fa-envira tamanoFlo"></i>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <h2>CDMX</h2>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <p>Galeria de las Estrellas, Local j14</p>
                </div>
            </div>
            <div class="row justify-content-center">

                <div class="col-auto">

                    <a mat-flat-button class="verde boton" href="#"><i class="fas fa-phone tamanoTel"></i> Contactar</a>
                </div>
            </div>
        </div>

    </div>

</div>






<app-footer></app-footer>