<h2 mat-dialog-title>Alitas</h2>
<div>
    <mat-checkbox class="example-margin" (change)="combinar()">Hacer mitad y mitad</mat-checkbox>
</div>

<mat-dialog-content class="pt-3" [formGroup]="alitasForm">

    <h3>Salsa</h3>
    <mat-radio-group formControlName="tipo1" aria-label="Select an option">
        <mat-radio-button value="bufalo">Bufalo</mat-radio-button>
        <mat-radio-button value="bbq">BBQ</mat-radio-button>
        <!-- <mat-radio-button value="bbq chipotle">BBQ Chipotle</mat-radio-button>
        <mat-radio-button value="tamaridno">Tamaridno</mat-radio-button>
        <mat-radio-button value="mango habanero">Mango Habanero</mat-radio-button> -->
    </mat-radio-group>

    <div *ngIf="combinado">
        <h3>Salsa 2</h3>
        <mat-radio-group formControlName="tipo2" aria-label="Select an option">
            <mat-radio-button value="bufalo">Bufalo</mat-radio-button>
            <mat-radio-button value="bbq">BBQ</mat-radio-button>
            <!-- <mat-radio-button value="bbq chipotle">BBQ Chipotle</mat-radio-button>
            <mat-radio-button value="tamaridno">Tamaridno</mat-radio-button>
            <mat-radio-button value="mango habanero">Mango Habanero</mat-radio-button> -->
        </mat-radio-group>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="alitasForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>
