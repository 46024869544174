<h2 mat-dialog-title>Hot cakes</h2>

<mat-dialog-content class="pt-3" [formGroup]="hotcakeForm">
    <h3>Complemento </h3>
    <mat-radio-group formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="lechera">Lechera</mat-radio-button>
        <mat-radio-button value="Miel">Miel</mat-radio-button>
        <mat-radio-button value="Maple">Maple</mat-radio-button>

    </mat-radio-group>

</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="hotcakeForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>