<h2 mat-dialog-title>Chilaquiles</h2>

<mat-dialog-content class="pt-3" [formGroup]="chilaquilesForm">
   <!--  <h3>Tipo de Chilaquiles</h3> -->
    <mat-radio-group formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="verde">Verde</mat-radio-button>
        <mat-radio-button value="rojo">Rojo</mat-radio-button>
        <mat-radio-button value="mole">Mole</mat-radio-button>

    </mat-radio-group>

</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="chilaquilesForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>