import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogBaguetteComponent } from '../forms/dialog-baguette/dialog-baguette.component';
import { DialogOrdComponent } from '../forms/dialog-ord/dialog-ord.component';
import { DialogCarritoComponent } from '../forms/dialog-carrito/dialog-carrito.component';
import { DialogEnsaladaComponent } from '../forms/dialog-ensalada/dialog-ensalada.component';
import { DialogChilaquilesComponent } from '../forms/dialog-chilaquiles/dialog-chilaquiles.component';
import { DialogOmeletComponent } from '../forms/dialog-omelet/dialog-omelet.component';
import { DialogWrapsComponent } from '../forms/dialog-wraps/dialog-wraps.component';
import { DialogTendersComponent } from '../forms/dialog-tenders/dialog-tenders.component';
import { DialogAlitasComponent } from '../forms/dialog-alitas/dialog-alitas.component';
import { DialogTacosfreshComponent } from '../forms/dialog-tacosfresh/dialog-tacosfresh.component';
import { DialogHuevosComponent } from '../forms/dialog-huevos/dialog-huevos.component';
import { DialogMolletesComponent } from '../forms/dialog-molletes/dialog-molletes.component';
import { DialogQuesadillasComponent } from '../forms/dialog-quesadillas/dialog-quesadillas.component';
import Swal from 'sweetalert2';
import { DialogPastaComponent } from '../forms/dialog-pasta/dialog-pasta.component';
import { DialogAguaFrutasComponent } from '../forms/dialog-agua-frutas/dialog-agua-frutas.component';
import { DialogAguaFrutastemporadaComponent } from '../forms/dialog-agua-frutastemporada/dialog-agua-frutastemporada.component';
import { DialogLicuadosComponent } from '../forms/dialog-licuados/dialog-licuados.component';
import { DialogAguaFrutascombinadaComponent } from '../forms/dialog-agua-frutascombinada/dialog-agua-frutascombinada.component';
import { DialogJugonaranjaComponent } from '../forms/dialog-jugonaranja/dialog-jugonaranja.component';
import { DialogJugofrutascombinadaComponent } from '../forms/dialog-jugofrutascombinada/dialog-jugofrutascombinada.component';
import { DialogRefrescosembotelladosComponent } from '../forms/dialog-refrescosembotellados/dialog-refrescosembotellados.component';
import { DialogPaqueteDesayunoComponent } from '../forms/dialog-paquete-desayuno/dialog-paquete-desayuno.component';
import { DialogCoctelfrutasComponent } from '../forms/dialog-coctelfrutas/dialog-coctelfrutas.component';
import { HostListener } from '@angular/core';
import { DialogEnchiladaComponent } from '../forms/dialog-enchilada/dialog-enchilada.component';
import { DialogHotcakesComponent } from '../forms/dialog-hotcakes/dialog-hotcakes.component';


@Component({
  selector: 'app-pedidos2',
  templateUrl: './pedidos2.component.html',
  styleUrls: ['./pedidos2.component.css']
})
export class Pedidos2Component implements OnInit {
/* Formularios */

carritoTexto = [] as any;
carritoPrecio = [] as any;
precioExtrasEnsalada = [] as any;
nombreExtrasEnsalada = [] as any;
mensajeExtra: any;
precio1:any;
precio2:any;


  constructor(public fb: FormBuilder, public dialog: MatDialog, ) {

    this.precio1 = this.carritoPrecio.reduce((a: number, b: number) => a + b, 0);
    this.precio2 = this.carritoPrecio.lenght;
  }


  ngOnInit(): void {
    /* Alert para mostrar que  aun esta en desarrollo*/
      /* Swal.fire({
        title: 'warning!',
        text: 'Proyecto aun en beta, gracias por tu comprención',
        icon: 'warning',
        timer: 2000,
        confirmButtonText: 'Gracias!'
      }); */

      /* Agregar que la caducidad sea de 12 hrs eso pasarlo  a milisegundos 18000000 milisegunods*/

      let fechaDeHoy= new Date();
      let fechaDehoyTimestamp  = fechaDeHoy.getTime();
      var nombreLocal:any = localStorage.getItem('LocalCaducidad')

      if(fechaDehoyTimestamp<(parseInt(nombreLocal)+18000000)){

        var nombreLocal:any = localStorage.getItem('LocalNombreProducto')
        var precioLocal:any = localStorage.getItem('LocalPrecioProducto')

       /* If para comporbar que existan los valores  */
        if(nombreLocal && precioLocal ){

          this.carritoTexto = nombreLocal.split(',,');
          var valoresPrecioLocal = precioLocal.split(',');
          for (let x = 0; x < valoresPrecioLocal.length; x++) {

            var number = valoresPrecioLocal[x]

            this.carritoPrecio.push(parseInt(number));
          }
        }

      }else{
        localStorage.removeItem('LocalNombreProducto');
        localStorage.removeItem('LocalPrecioProducto');
        localStorage.removeItem('LocalCaducidad');


      }



      /* AQUI AGARRA LOS VALORES DE LOCAL STORAGE PARA RECUPERAR LA ORDEN QUE SE CREABA */




  }

  /* Funciones creadas alert y varias */

  suma(algo:any){
    var final = algo.reduce((a: number, b: number) => a + b, 0);
    return final;
  }
  alertaceptado(): any{
    Swal.fire({
      text: 'Agregado al carrito',
      icon: 'success',
      timer: 1500,
      width: '150px' ,

    });
  }

  agregarLocalStorageSimple(texto:any,precio:any){

    let fechaDeHoy= new Date();
    let fechaDeHoyLocalStorage = fechaDeHoy.getTime();
    texto = texto + ','
    var caducidadLocalStoage:any = localStorage.getItem('caducidad');

    var nombreLocalStoage:any = localStorage.getItem('LocalNombreProducto');
    var precioLocalStoage:any = localStorage.getItem('LocalPrecioProducto');

    nombreLocalStoage = nombreLocalStoage ? nombreLocalStoage.split(','):[];

    precioLocalStoage = precioLocalStoage ? precioLocalStoage.split(','):[];

    nombreLocalStoage.push(texto);
    precioLocalStoage.push(precio);

    localStorage.setItem('LocalCaducidad',fechaDeHoyLocalStorage.toString());
    localStorage.setItem('LocalNombreProducto',nombreLocalStoage.toString());
    localStorage.setItem('LocalPrecioProducto',precioLocalStoage.toString());

  }



  /* ------------------------------------ Entradas -------------------------------- */
  openGuacamole(): any{
    const texto = 'Guacamole';
    const precio = 110;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple('Guacamole','110');
    this.alertaceptado();


  }
  opedDePanTostado(){
    const texto = 'Orden Pan tostado';
    const precio = 90;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple('Orden Pan tostado','90');
    this.alertaceptado();

  }
  openDeFrijol(){
    const texto = 'Orden de frijol';
    const precio = 60;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple('Orden de frijol','60')
    this.alertaceptado();
    ;
  }
  openTostadaFrancesa(){
    const texto = 'Tostadas francesas';
    const precio = 110;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple('Tostadas francesas','110');
    this.alertaceptado();

  }
  /* ------------------------------------ Desayunos -------------------------------- */

  openOmelet(): any{
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.width = '400px';
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogOmeletComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.seleccionados === '' ){
          /* console.log('no hay datos: principal'); */
        }
        if (data.seleccionados ){
          /* console.log('Dialog output:', data); */
          const texto = 'Omelet ' + data.seleccionados;
          const precio = 110;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );
  }
  /* aqui pa abajo */
  openChilaquiles(): any{

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogChilaquilesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.tipo === '' ){
          /* console.log('no hay datos: principal'); */
        }
        if (data.tipo ){
         /*  console.log('Dialog output:', data); */
          const texto = 'Chilaquiles ' + data.tipo;
          const precio = 110;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );

  }
  openHotcakes(): any{
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogHotcakesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.tipo === '' ){
          /* console.log('no hay datos: principal'); */
        }
        if (data.tipo ){
         /*  console.log('Dialog output:', data); */
          const texto = 'Hot cakes con ' + data.tipo;
          const precio = 110;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );

  }
  openHotcakesChico(): any{
    const texto = 'Hot Cakes';
    const precio = 100;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();

  }
  openHuevo(): any{
    let valores: any;
    let textoExtra: string;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogHuevosComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){
        if (data.tipo === ''){
        /* console.log('no hay datos: principal'); */
        }
        if (data.tipo){

         /* console.log(data.tipo); */
         if ( data.tipo === 'revueltos'){
             valores =  data.seleccionados ;
             textoExtra = ' con ' + valores;
          }else{
            valores = '';
            textoExtra = '';
          }

         /* console.log('Dialog output:', data); */
        /* seleccionados   if data */
         const texto = 'Huevo ' + data.tipo + textoExtra;

         const precio = 110;
         this.carritoPrecio.push(precio);
         this.carritoTexto.push( texto);
         this.agregarLocalStorageSimple( texto,precio);
         this.alertaceptado();
        }
      }
    }
    );
  }
  openMolletes(): any{
    let tipo2:any ;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogMolletesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.tipo1 === '' ){
          /* console.log('no hay datos: principal'); */
        }
        if (data.tipo1 ){

          if(data.tipo2){
            tipo2 =" y "+ data.tipo2;
            /* console.log(tipo2); */
          }else{
            tipo2= "";
          }

          /* console.log('Dialog output:', data); */
          const texto = 'Molletes ' + data.tipo1 + tipo2;
          const precio = 110;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
      }

    );

  }
  openTostadas(): any{
    const texto = 'Tostadas';
    const precio = 110;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  /* por terminar */

  openQuesadillas(): any{
    let valores: any;
    let textoExtra: string;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogQuesadillasComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){
        if (data.tipo === ''){
        /* console.log('no hay datos: principal'); */
        }
        if (data.tipo){

         /* console.log(data.tipo); */
         if ( data.tipo === 'vegetariano'){
             valores =  data.seleccionados ;
             textoExtra = ' con ' + valores;
          }else{
            valores = '';
            textoExtra = '';
          }

         /* console.log('Dialog output:', data); */
        /* seleccionados   if data */
         const texto = 'Quesadilla ' + data.tipo + textoExtra;

         const precio = 110;
         this.carritoPrecio.push(precio);
         this.carritoTexto.push( texto);
         this.agregarLocalStorageSimple( texto,precio);
         this.alertaceptado();
        }
      }
    }
    );
  }

  openEnchiladas(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogEnchiladaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.tipo === '' ){
          /* console.log('no hay datos: principal'); */
        }
        if (data.tipo ){
         /*  console.log('Dialog output:', data); */
          const texto = 'Enchiladas ' + data.tipo;
          const precio = 110;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );
  }
  openSandwich(): any{
    const texto = 'Sandwich';
    const precio = 110;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }

/* ------------------------------------ Ensalada -------------------------------- */
  openEnsalada(): any{
    const coma = ',';
    const espacio = ' ';
    const espacioWhats = '%20';

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogEnsaladaComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.tamano === '' && data.proteina === '' && data.aderezos === '' && data.complementos === '' && data.seleccionados === '' ){
        /* console.log('no hay datos: principal'); */
        }
        if (data.tamano && data.proteina && data.aderezos && data.complementos && data.seleccionados && data.ingredientes){
        /* console.log('Dialog output:', data); */

        /* Crear/obtener Texto */
        const texto = 'Texto ensalada';
        const tamanoTexto = data.tamano;
        const proteinaTexto = data.proteina;
        const ingreSeleccionados = data.seleccionados;
        const aderezoTexto = data.aderezos;
        const complementos = data.complementos;

        /* Crear/obtener Precio */
        const precios = data.extras;
        if (precios != null){
          for (let i = 0; i < precios.length; i++) {
                    const elementPrecio =  data.extras[i].precio;
                    const elementNombre =  data.extras[i].viewValue;
                    this.precioExtrasEnsalada.push(elementPrecio);
                    this.nombreExtrasEnsalada.push(elementNombre);
          }
        }

        let  precioExtras = 0;
        precioExtras = this.precioExtrasEnsalada.reduce((a: number, b: number) => a + b, 0);


        /* LOS EXTRAS EN TEXTO */

        const extras = this.nombreExtrasEnsalada.join(', ');

        const mensajeTamano = espacio + 'Ensalada' + espacio + tamanoTexto + coma;
        const mensajeProteina = espacio + proteinaTexto + coma + espacio;
        const mensajeIngredientes = ingreSeleccionados;
        const mensajeAderezos = coma + espacio +  aderezoTexto;
        const mensajeComplementos = coma + espacio + complementos + espacio;

        if (this.nombreExtrasEnsalada.length < 1){

          this.mensajeExtra = '';
        }else{
          this.mensajeExtra = 'Extras:' + extras + espacio;
        }

        const mensajeEnsalada = mensajeTamano +
                         mensajeProteina +
                         mensajeIngredientes +
                         mensajeAderezos +
                         mensajeComplementos +
                         this.mensajeExtra;

        /* console.log(mensajeEnsalada);
        console.log(precioExtras);
        console.log(extras); */

        /* OBTENER EL PRECIO DEL TAMANO CON IF ELSE IF */

        const tamano = data.tamano;
        let precio = 0;
        if (tamano === 'chica'){
          precio = 130;
        }else if (tamano === 'mediana'){
          precio = 140;
        }else if (tamano === 'grande'){
          precio = 150;
        }

        const precioTotal = precio + precioExtras;

        this.carritoPrecio.push(precioTotal);
        this.carritoTexto.push(mensajeEnsalada);
        this.agregarLocalStorageSimple(mensajeEnsalada,precioTotal);
        this.precioExtrasEnsalada = [];
        this.nombreExtrasEnsalada = [];
        this.alertaceptado();
        }
      }
      }
  );


  }
  openEnsaladaGour62proAtun(){
    const texto = 'Ensalada gourmet 62 pro Atun';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push(texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  openEnsaladaGour62proPollo(){
    const texto = 'Ensalada gourmet 62 pro Pollo';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push(texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  openEnsaladaGourHot(){
    const texto = 'Ensalada gourmet hot';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push(texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  openEnsaladaGourDulce(){
    const texto = 'Ensalada gourmet dulce';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push(texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  openEnsaladaGour3Quesos(){
    const texto = 'Ensalada gourmet 3 quesos';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push(texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  openEnsaladaGourcCesar(){
    const texto = 'Ensalada gourmet cesar';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push(texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  openEnsaladaGourGreca(){
    const texto = 'Ensalada gourmet greca ';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push(texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }

  /* ------------------------------------ Pastas -------------------------------- */

  openPasta():any{
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;


    const dialogRef = this.dialog.open(DialogPastaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.tipo === '' ){
          /* console.log('no hay datos: principal'); */
        }
        if (data.tipo ){
          /* console.log('Dialog output:', data); */
          const texto = 'Pasta ' + data.tipo;
          const precio = 110;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );

  }
  OpenPastaBolognesa(){
    const texto = 'Pastaboloñesa';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  OpenPasta3Quesos(){
    const texto = 'Pasta 3 quesos';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  OpenPastaTariyaki(){
    const texto = 'Pasta estilo tariyaki';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  OpenPastaEspaguetti(){
    const texto = 'Pasta espagueti';
    const precio = 70;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }

  /* ------------------------------------ Baguette -------------------------------- */
  openDialogBaguette(): any{

    const dialogConfig = new MatDialogConfig();
    let proteina = '';
    const extra = 40;
    let precio = 110;
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogBaguetteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        data => {

          if(data != undefined){


          if (data.tipo === '' ){
            /* console.log('no hay datos: principal'); */
          }
          if (data.tipo ){
           /*  console.log('Dialog output:', data); */
            if (data.proteina){
              proteina = ' con ' + data.proteina;
            }else{
              proteina = '';
            }
            const texto = 'Baguette ' + data.tipo + proteina;
            if (data.proteina){

              precio = 110 + extra;
            }
            if (data.tipo === "frances"){

              precio = 110 + 0;
            }

            this.carritoTexto.push(texto);
            this.carritoPrecio.push(precio);
            this.agregarLocalStorageSimple( texto,precio);
            this.alertaceptado();
          }

          }
        }

    );

  }
  /* ------------------------------------ especialidades -------------------------------- */

  openDialogOrd(): any{

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogOrdComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        data => {
          if(data != undefined){

            if (data.proteina === '' && data.aderezo === '' ){
         /*    console.log('no hay datos: principal'); */
            }
            if (data.proteina && data.aderezo ){
            /* console.log('Dialog output:', data); */
            const texto = 'Orden de ' + data.proteina + ' con aderezo ' + data.aderezo;
            const precio = 140;
            this.carritoPrecio.push(precio);
            this.carritoTexto.push( texto);
            this.agregarLocalStorageSimple( texto,precio);
            this.alertaceptado();
            }
          }
        }
    );
  }
  openTenders(): any{
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogTendersComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
          if(data != undefined){

            if (data.aderezos === '' ){
              /* console.log('no hay datos: principal'); */
            }
            if (data.aderezos ){
              /* console.log('Dialog output:', data); */
              const texto = 'Tenders con ' + data.aderezos;
              const precio = 130;
              this.carritoTexto.push(texto);
              this.carritoPrecio.push(precio);
              this.agregarLocalStorageSimple( texto,precio);
              this.alertaceptado();
            }
          }
      }

    );

  }
  openDialogWrap(): any{
    let valores: any;
    let textoExtra: string;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogWrapsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){
          if (data.tipo === '' && data.aderezo === ''){
          /* console.log('no hay datos: principal'); */
          }
          if (data.tipo && data.aderezo){

           /* console.log(data.tipo); */
           if ( data.tipo === 'vegetariano'){
               valores =  data.seleccionados ;
               textoExtra = ' con ' + valores;
            }else{
              valores = '';
              textoExtra = '';
            }

           /* console.log('Dialog output:', data); */
          /* seleccionados   if data */
           const texto = 'Wrap ' + data.tipo + textoExtra + ' con aderezo ' + data.aderezo;

           const precio = 130;
           this.carritoPrecio.push(precio);
           this.carritoTexto.push( texto);
           this.agregarLocalStorageSimple( texto,precio);
           this.alertaceptado();
          }
        }
      }
    );

  }
  openAlitas(): any{
    let tipo2:any ;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogAlitasComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.tipo1 === ''  ){
          /* console.log('no hay datos: principal'); */
        }
        if (data.tipo1){

          if(data.tipo2){
            tipo2 =" y "+ data.tipo2;
            /* console.log(tipo2); */
          }else{
            tipo2= "";
          }

          /* console.log(data.tipo1 + ' espacio ' +data.tipo2)


          console.log('Dialog output:', data); */
          const texto = 'Alitas ' + data.tipo1 + tipo2;
          const precio = 130;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );

  }
  openRamen(){
    const texto = 'Ramen';
    const precio = 110;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();

  }
  /* TacoFresh version Dialogo */
  openTacofresh(): any{
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogTacosfreshComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.tipo === '' ){
          /* console.log('no hay datos: principal'); */
        }
        if (data.tipo ){
          /* console.log('Dialog output:', data); */
          const texto = 'Tacos de' + data.tipo;
          const precio = 110;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );
  }
  /* TacoFresh version corta */
  openTacoFresh(): any{
    const texto = 'Taco Fresh';
    const precio = 110;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();
  }
  openAlambre(){
    const texto = 'Alambre de pollo';
    const precio = 140;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push( texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();

  }
  /* ------------------------------------  Bebidas ------------------------------------ */

  openAguadefrutas(){
    const dialogConfig = new MatDialogConfig();
    let tamano:string;
    let precio:number;

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogAguaFrutasComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.tipo === '' && data.tamano ===''   ){
          /* console.log('no hay datos: principal'); */
        }
        if(data.tamano ==='copa'){
          tamano = 'Copa';
          precio = 55;
        }else{
          tamano ='Vaso'
          precio = 45;
        }
        if (data.tipo ){
          /* console.log('Dialog output:', data); */
          const texto = tamano + ' agua de ' + data.tipo;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );

  }
  openAguadefrutasdetemporada(){
    const dialogConfig = new MatDialogConfig();

    let tamano:string;
    let precio:number;
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogAguaFrutastemporadaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){
          if (data.tipo === '' && data.tamano ==='' ){
            /* console.log('no hay datos: principal');  */
          }
          if(data.tamano ==='copa'){
            tamano = 'Copa';
            precio = 60;
          }else{
            tamano ='Jarra'
            precio = 120;
          }
          if (data.tipo ){
            /* console.log('Dialog output:', data); */
            const texto = tamano +' de '+ data.tipo +' Agua de temporada';
            this.carritoTexto.push(texto);
            this.carritoPrecio.push(precio);
            this.agregarLocalStorageSimple( texto,precio);
            this.alertaceptado();
          }
        }
    }
    );
  }
  openLicuados(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogLicuadosComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.tipo === '' && data.tamano ===''   ){
          /* console.log('no hay datos: principal'); */
        }

        if (data.tipo ){
          /* console.log('Dialog output:', data); */
          const texto = 'Licuado de ' + data.tipo;
          const precio = 60;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );
  }
  openAguadefrutascombinada(){
    const dialogConfig = new MatDialogConfig();

    let tamano:string;
    let precio:number;
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogAguaFrutascombinadaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){
          if (data.tipo === '' && data.tamano ==='' ){
            /* console.log('no hay datos: principal');  */
          }
          if(data.tamano ==='copa'){
            tamano = 'Copa';
            precio = 70;
          }else{
            tamano ='Jarra'
            precio = 130;
          }
          if (data.tipo ){
            /* console.log('Dialog output:', data); */
            const texto = tamano +' de '+ data.tipo +' Agua de fruta combinada';
            this.carritoTexto.push(texto);
            this.carritoPrecio.push(precio);
            this.agregarLocalStorageSimple( texto,precio);
            this.alertaceptado();
          }
        }
    }
    );
  }
  openJugodenaranja(){
    const dialogConfig = new MatDialogConfig();

    let tamano:string;
    let precio:number;
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogJugonaranjaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){
          if (data.tamano === '' ){
            /* console.log('no hay datos: principal');  */
          }
          if(data.tamano ==='copa'){
            tamano = 'Copa';
            precio = 70;
          }else{
            tamano ='Jarra'
            precio = 140;
          }
          if (data.tamano ){
            /* console.log('Dialog output:', data); */
            const texto = tamano + ' de naranja';
            this.carritoTexto.push(texto);
            this.carritoPrecio.push(precio);
            this.agregarLocalStorageSimple( texto,precio);
            this.alertaceptado();
          }
        }
    }
    );
  }
  openJugodefrutascombinado(){
    const dialogConfig = new MatDialogConfig();

    let tamano:string;
    let precio:number;
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogJugofrutascombinadaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){
          if (data.tipo === '' && data.tamano ==='' ){
            /* console.log('no hay datos: principal');  */
          }
          if(data.tamano ==='copa'){
            tamano = 'Copa';
            precio = 80;
          }else{
            tamano ='Jarra'
            precio = 150;
          }
          if (data.tipo ){
            /* console.log('Dialog output:', data); */
            const texto = tamano +' de '+ data.tipo +' jugo de fruta combinada';
            this.carritoTexto.push(texto);
            this.carritoPrecio.push(precio);
            this.agregarLocalStorageSimple( texto,precio);
            this.alertaceptado();
          }
        }
    }
    );
  }
  openRefrescosembotellados(){
    const dialogConfig = new MatDialogConfig();

    let opcion:string;
    let precio:number;
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogRefrescosembotelladosComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

        if(data != undefined){
          if (data.tipo === '' ){
            /* console.log('no hay datos: principal');  */
          }
          if(data.tipo ==='medio'){
            opcion = 'Agua nat. 1/2 L';
            precio = 20;
          }else if(data.tipo ==='litro'){
            opcion ='Agua nat. 1 L'
            precio = 25;
          }else if(data.tipo ==='mineral'){
            opcion ='Agua mineral'
            precio = 30;
          }else if(data.tipo === 'cocacola'){
            opcion ='Coca cola'
            precio = 30;
          }
          if (data.tipo ){
            /* console.log('Dialog output:', data); */
            let texto = opcion;
            this.carritoTexto.push(texto);
            this.carritoPrecio.push(precio);
            this.agregarLocalStorageSimple( texto,precio);
            this.alertaceptado();
          }
        }
    }
    );
  }
  openCocteldefrutas(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogCoctelfrutasComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.seleccionados === '' && data.tipo){
          /* console.log('no hay datos: principal'); */
        }
        if (data.seleccionados && data.tipo){
          /* console.log('Dialog output:', data); */
          const texto = 'Coctel de ' + data.seleccionados +' con '+ data.tipo;
          const precio = 80;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );

  }
  opencafe(){
    const texto = 'Café Americano';
    const precio = 25;
    this.carritoPrecio.push(precio);
    this.carritoTexto.push(texto);
    this.agregarLocalStorageSimple( texto,precio);
    this.alertaceptado();

  }

  openPaquetedesayuno(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogPaqueteDesayunoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined){

        if (data.desayuno === '' && data.bebida  ){
          /* console.log('no hay datos: principal'); */
        }
        if (data.desayuno && data.bebida ){
          /* console.log('Dialog output:', data); */
          const texto ='PAQUETE DESAYUNO : '+  data.desayuno  + ' y '+ data.bebida ;
          const precio = 130;
          this.carritoTexto.push(texto);
          this.carritoPrecio.push(precio);
          this.agregarLocalStorageSimple( texto,precio);
          this.alertaceptado();
        }
      }
    }

    );
  }

  /* CARRITO */
  openCarrito(): any{
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '300px';
      /* dialogConfig.data = this.carritoTexto; */
      dialogConfig.data = {Texto: this.carritoTexto, Precio: this.carritoPrecio};
      const dialogRef = this.dialog.open(DialogCarritoComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if(data != undefined){

          /* hay que verificar que solo se haga una vez
            Aqui se va a comprobar si se necesita sumar y restar los datos
          */
          if (data === '' ){
            /* console.log('no hay datos: principal'); */
          }
          if (data ){
            /* console.log(data);
            console.log(this.carritoTexto);
            console.log(this.carritoPrecio); */
          }
        }
      }
      );
  }


  scrollTop(element: HTMLElement): any {
    element.scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  scroll(element: HTMLElement): any {
    element.scrollIntoView({block: 'center', behavior: 'smooth'});
  }


}
