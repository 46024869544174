<h2 mat-dialog-title>Quesadillas</h2>

<mat-dialog-content class="pt-3" [formGroup]="quesadilasForm">

    <mat-radio-group (change)="comprobarBagVegetaria($event)" formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="naturales">Naturales (solo queso)</mat-radio-button>
        <mat-radio-button value="chorizo">Chorizo</mat-radio-button>
        <mat-radio-button value="pollo deshebrado">Pollo deshebrado</mat-radio-button>
        <mat-radio-button value="champinon">Champiñon</mat-radio-button>
        <mat-radio-button value="jamón">Jamón</mat-radio-button>
        <mat-radio-button value="vegetariano">Vegetariano</mat-radio-button>
    </mat-radio-group>
    <div *ngIf="toggleVeg == true">
        <h4>Selecciona tus 4 ingredientes</h4>

        <div class="ingredientes" *ngFor="let control of ingredientesArray.controls; let i = index;">

            <!-- AQUI HAY QUE HACE RQUE SE DEJEN DE MARCAR CUANDO YA HAY MAS DE 4  -->
            <mat-checkbox (change)="getselectedingredientes()" [formControl]="control" type="checkbox">{{ingredientess[i]}}</mat-checkbox>
        </div>
        <div *ngIf="selectedingredientes.length > 1">
            <small class="text-center ">{{selectedingredientes}}</small>
        </div>
        <div *ngIf="selectedingredientes.length >= 4">
            <small class="text-center text-danger">Solo se permite 4 ingredientes</small>
        </div>
        <br>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="quesadilasForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>
