import { Component, OnInit } from '@angular/core';
import { MenutoggleService } from '../menutoggle.service';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
    open = false;
    asideVisible: boolean;
    _asideSubscription: any;
    constructor(private menuServices: MenutoggleService) {
      this.asideVisible = menuServices.isSidebarVisible;
      this._asideSubscription = menuServices.sidebarVisibilityChange.subscribe((value) => {
          this.asideVisible = value;
      });


   }

  ngOnInit(): void {
   
  }

  contrario(): any{
    this.asideVisible = false;
  }

  toggleSidebar() {
    this.menuServices.toggleSidebarVisibilty();
    console.log(this.asideVisible);
  }

}
