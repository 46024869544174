<div class="contenido">
    <img src="assets/fr2.png" class=" d-block w-100 " alt="...">

    <!-- centro -->
    <div class="row justify-content-center">

        <div class="col-lg-5 col-md-5 col-sm-4 col-8 mb-2">

            <h1 class="text-center">Contacto</h1>
            <p class="text-center">Queremos escuchar tu opinión, tu correo será atendido a la mayor brevedad posible.</p>

        </div>
    </div>
    <form [formGroup]="formulario" (ngSubmit)="enviar(formulario)">
        <div class="row parent">
            <div class="col">
                <div style="text-align:center;">
                    <mat-form-field class="input">
                        <mat-label>Cliente</mat-label>
                        <input class="textoVerde" minlength="5" matInput formControlName="nombre" [(ngModel)]="clienteCorreo">
                    </mat-form-field>
                </div>
            </div>
            <!-- <div class="col">
            <div style="text-align:center;">
                <mat-form-field>
                    <mat-label>Correo Electrónico</mat-label>
                    <input matInput formControlName="correo">
                </mat-form-field>
            </div>
        </div> -->
            <div class="col">
                <div style="text-align:center;">
                    <mat-form-field class="textoarea">
                        <mat-label>Escribe tu mensaje Aquí</mat-label>
                        <textarea rows="10" minlength="10" matInput formControlName="texto" [(ngModel)]="mensajeCorreo"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="text-center">
                <a mat-stroked-button [disabled]="formulario.invalid" target="_blank" href="mailto:{{emailFresh}}?subject={{motivoCorreo}}&body={{mensajeAcomodo+clienteCorreo+espacio+mensajeCorreo}}">Enviar correo</a>
            </div>
        </div>
    </form>
</div>

<app-footer></app-footer>