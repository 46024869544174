<p> Haz tu Ensalada</p>

<form [formGroup]="comandaform" (ngSubmit)="guardar(comandaform)">

    <hr>
    <h4>Seleccion de ensalada</h4>

    <div formGroupName="tamano">
        <mat-checkbox class="checkboxEspacio" (change)="comprobarChica()" [disabled]="chicaa" formControlName="chica">Chica</mat-checkbox>
        <mat-checkbox class="checkboxEspacio" (change)="comprobarMediana()" [disabled]="medianaa" formControlName="mediana">Mediana</mat-checkbox>
        <mat-checkbox class="checkboxEspacio" (change)="comprobarGrande()" [disabled]="grandee" formControlName="grande">Grande</mat-checkbox>

    </div>
    <hr>
    <h4>Selecciona tu proteina</h4>
    <div formGroupName="proteina">
        <mat-checkbox (change)="comprobarpollonatural()" [disabled]="pollonaturall" formControlName="pollonatural">Pollo natural</mat-checkbox>
        <mat-checkbox (change)="comprobarpollobbq()" [disabled]="pollobbqq" formControlName="pollobbq">Pollo BBQ</mat-checkbox>
        <mat-checkbox (change)="comprobarjamon()" [disabled]="jamonn" formControlName="jamon">Jamón</mat-checkbox>
        <mat-checkbox (change)="comprobarpollofrito()" [disabled]="pollofritoo" formControlName="pollofrito">Pollo frito</mat-checkbox>
        <mat-checkbox (change)="comprobarpolloespecies()" [disabled]="polloespeciess" formControlName="polloespecies">Pollo especies</mat-checkbox>
        <mat-checkbox (change)="comprobaratunn()" [disabled]="atunn" formControlName="atun">Atún</mat-checkbox>
    </div>

    <hr>
    <h4>Selecciona tus ingredientes
        <mat-checkbox (change)="marcarIngredientesAll()" formControlName="ingredientesAll"> </mat-checkbox>

    </h4>

    <!-- <div formGroupName="ingredientes">

        <mat-checkbox formControlName="zanahoria">Zanahoria</mat-checkbox>
        <mat-checkbox formControlName="tomate">Tomate</mat-checkbox>
        <mat-checkbox formControlName="betabel">Betabel</mat-checkbox>
        <mat-checkbox formControlName="champinon">Champinon</mat-checkbox>
        <mat-checkbox formControlName="jalapeno">Jalapeno</mat-checkbox>
        <mat-checkbox formControlName="cebolla">Cebolla</mat-checkbox>
        <mat-checkbox formControlName="aceituna">Aceituna</mat-checkbox>
        <mat-checkbox formControlName="pina">Piña</mat-checkbox>
        <mat-checkbox formControlName="morron">Morron</mat-checkbox>
        <mat-checkbox formControlName="panela">Panela</mat-checkbox>
        <mat-checkbox formControlName="pasta">Pasta</mat-checkbox>
        <mat-checkbox formControlName="apio">Apio</mat-checkbox>
        <mat-checkbox formControlName="colmorado">Colmorado</mat-checkbox>
        <mat-checkbox formControlName="pepino">Pepino</mat-checkbox>
        <mat-checkbox formControlName="espinaca">Espinaca</mat-checkbox>
        <mat-checkbox formControlName="germinado">Germinado</mat-checkbox>
        <mat-checkbox formControlName="elote">Elote</mat-checkbox>
        <mat-checkbox formControlName="brocoli">Brocoli</mat-checkbox>
    </div> -->

    <div class="ingredientes" *ngFor="let control of ingredientesArray.controls; let i = index;">

        <mat-checkbox [checked]="todos" (change)="getselectedingredientes()" [formControl]="control" type="checkbox">{{ingredientess[i]}}</mat-checkbox>


    </div>

    <hr>
    <h4>Elige un aderezo</h4>
    <div formGroupName="aderezos">
        <mat-checkbox (change)="comprobarranch()" [disabled]="ranchh" formControlName="ranch">Ranch</mat-checkbox>
        <mat-checkbox (change)="comprobarmillislas()" [disabled]="millislass" formControlName="millislas">Mil islas</mat-checkbox>
        <mat-checkbox (change)="comprobarcesar()" [disabled]="cesarr" formControlName="cesar">Cesar</mat-checkbox>
        <mat-checkbox (change)="comprobaritaliano()" [disabled]="italianoo" formControlName="italiano">Italiano</mat-checkbox>
        <mat-checkbox (change)="comprobarmostazamiel()" [disabled]="mostazamiell" formControlName="mostazamiel">Mostazamiel</mat-checkbox>
        <mat-checkbox (change)="comprobarvinagreta()" [disabled]="vinagretaa" formControlName="vinagreta">Vinagreta</mat-checkbox>
        <mat-checkbox (change)="comprobarchimchurry()" [disabled]="chimichurry" formControlName="chimchurry">Chimichurry</mat-checkbox>
    </div>
    <hr>
    <h4>Elige un Complemento</h4>
    <div formGroupName="complementos">
        <mat-checkbox (change)="comprobarparmesano()" [disabled]="parmesanoo" formControlName="parmesano">Parmesano</mat-checkbox>
        <mat-checkbox (change)="comprobarcrotones()" [disabled]="crotoness" formControlName="crotones">Crotones</mat-checkbox>
        <mat-checkbox (change)="comprobarchilequebrado()" [disabled]="chilequebradoo" formControlName="chilequebrado">Chile quebrado </mat-checkbox>
        <mat-checkbox (change)="comprobaralmendras()" [disabled]="almendrass" formControlName="almendras">Almendras</mat-checkbox>
        <mat-checkbox (change)="comprobaraceitunas()" [disabled]="aceitunass" formControlName="aceitunas">Aceitunas</mat-checkbox>
    </div>
    <hr>




    <h4>Incrementa tu ensalada</h4>

    <button type="button" (click)="addExtra()" mat-stroked-button>Agregar</button>

    <br>
    <mat-form-field formArrayName="alternateExtras" *ngFor="let extrasss of alternateExtras.controls; let i = index" appearance="fill">

        <mat-label>Extra</mat-label>
        <mat-select [formControlName]="i">
            <mat-option *ngFor="let complementos of TodosExtra" [value]="complementos" (click)="tomarprecio(complementos.precio)">
                {{complementos.viewValue}} ${{complementos.precio}}
            </mat-option>

        </mat-select>
        <hr>
        <br>

        <button type="button" (click)="eliminarExtra(i)" mat-icon-button color="warm">
        <mat-icon>clear</mat-icon>
        </button>

    </mat-form-field>

    <!-- 
        <mat-checkbox (change)="comprobarextporcion()" [disabled]="extporcionn" formControlName="extporcion">Extra de proteina</mat-checkbox>
        <mat-checkbox (change)="comprobarextaderezo()" [disabled]="extaderezoo" formControlName="extaderezo">Extra de aderezo </mat-checkbox>
        <mat-checkbox (change)="comprobarxtcomplemento()" [disabled]="extcomplementoo" formControlName="extcomplemento">Extra de complemento </mat-checkbox>
 -->


    <hr><br>

    <div class="complementos">

        <mat-form-field>
            <mat-label>Cliente</mat-label>
            <input matInput formControlName="nombre">
        </mat-form-field>


        <mat-form-field>
            <mat-label>Teléfono</mat-label>

            <input formControlName="telefono" type="tel" matInput placeholder="555-555-1234">

        </mat-form-field>
        <br>

        <mat-checkbox (change)="mostrardireccion()" formControlName="mostrarDireccion">
            <div *ngIf="!mostrarDireccionView">
                Enviar / Agregar direccion
            </div>
            <div *ngIf="mostrarDireccionView">
                Pasar a buscar
            </div>
        </mat-checkbox>

        <mat-form-field class="full" *ngIf="mostrarDireccionView">
            <mat-label>Direccion</mat-label>

            <input formControlName="direccion" matInput placeholder="Avenida... Calle... Lote...">

        </mat-form-field>

        <br>
        <mat-form-field>

            <textarea formControlName="especificaciones" rows="4" matInput placeholder="Notas..."></textarea>
        </mat-form-field>


        <!-- <input required type="text" class="nombreCliente" size="45" formControlName="nombre" placeholder="Nombre Cliente" name="nombre"> -->

        <!--  <textarea name="especifiaciones" formControlName="especificaciones" id="especificacioes" cols="48" rows="5" placeholder="Agrega tus especifiaciones..."></textarea>
 -->
        <mat-checkbox required formControlName="aceptarcondiciones">Aceptar condiciones</mat-checkbox>
        <mat-form-field>
            <mat-label>Con cuento se pagara</mat-label>

            <input formControlName="pagocliente" matInput placeholder=" ">

        </mat-form-field>
        <p *ngIf="preciofinal">Costo de la ensalda: ${{preciofinal}}</p>

        <br>

        <button type="submit" [disabled]="comandaform.invalid" mat-raised-button>Aceptar</button>
    </div>


</form>