<div class="contenido">
    <img  src="assets/fr2.png" class=" w-100 espacio" alt="...">


    <!-- Carousel -->
    <div class="row justify-content-center carrou">
        <div class="col-lg-8">
            <div id="carouselExampleSlidesOnly1" class="carousel carousel-dark slide carousel-fade " data-bs-ride="carousel">

                <div class="carousel-indicators pt-5">

                    <button type="button" data-bs-target="#carouselExampleSlidesOnly1" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleSlidesOnly1" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleSlidesOnly1" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleSlidesOnly1" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleSlidesOnly1" data-bs-slide-to="4" aria-label="Slide 5"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="3000">
                        <img src="assets/L1.jpg" class="d-block w-100 " alt="...">
                    </div>
                    <div class="carousel-item" data-bs-interval="3000">
                        <img src="assets/L2.jpg" class="d-block w-100 rounded" alt="...">
                    </div>
                    <div class="carousel-item" data-bs-interval="3000">
                        <img src="assets/L3.jpg" class="d-block w-100 rounded" alt="...">
                    </div>
                    <div class="carousel-item" data-bs-interval="3000">
                        <img src="assets/L4.jpg" class="d-block w-100 rounded" alt="...">
                    </div>
                    <div class="carousel-item" data-bs-interval="3000">
                        <img src="assets/L5.jpg" class="d-block w-100 rounded" alt="...">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-lg-12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7505.91975076336!2d-90.53549320072018!3d19.841650621478845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85f833f1071891f3%3A0x810711aa7b1ed720!2sFresh&#39;n%20Green!5e0!3m2!1ses-419!2smx!4v1628714906005!5m2!1ses-419!2smx"
                width="100%" height="290" style="border:0;" allowfullscreen="" loading="lazy">
        </iframe>
        </div>
    </div>
</div>


<app-footer></app-footer>
