<h2 mat-dialog-title>Coctel de frutas</h2>

<mat-dialog-content class="pt-3" [formGroup]="coctelFrutasForm">
    <p>Escoge 1 o todas</p>
    <div class="ingredientes" *ngFor="let control of ingredientesArray.controls; let i = index;">

        <mat-checkbox 
        [checked]="todos" 
        (change)="getselectedingredientes()" 
        [formControl]="control" 
        type="checkbox">
        {{ingredientess[i]}}</mat-checkbox>
    </div>
    <br><br>
    <mat-radio-group formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="yogurt">Yogurt</mat-radio-button>
        <mat-radio-button value="Miel">Miel</mat-radio-button>
        <mat-radio-button value="granola">Granola</mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="coctelFrutasForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>