<!-- <img src="assets/fr2.png" class=" d-block w-100 " alt="...">
 -->



<div class="row">
    <div class="col centro">
        <video autoplay loop>
            <source src="assets/ensaladaVideo.mp4" type="video/mp4">
        </video>
    </div>
</div>

<h2 class="text-center">Haz tu ensalada</h2>

<p class="text-center">
    Crea <span class="text-verde">tu</span> ensalada como <span class="text-verde">quieras</span> , con todos los <span class="text-verde">ingredientes</span> que quieras pídela para llevar o para pasar a buscar

</p>

<form [formGroup]="comandaformulario" (ngSubmit)="guardar(comandaformulario)">


    <div formArrayName="alternateTodos">

        <div *ngFor="let tamano of alternateTodos.controls; let i=index">


            <div [formGroupName]="i">
                <!-- 
                <input type="radio" formControlName="tamanoPrueba2" value="1">
                <input type="radio" formControlName="tamanoPrueba2" value="2"> -->
                Ensala numero :{{i+1}}
                <hr>

                <mat-radio-group formControlName="tamano" aria-label="Select an option">
                    <mat-radio-button (change)="agregarPrecioC($event)" value="chica">Chica</mat-radio-button>
                    <mat-radio-button (change)="agregarPrecioM($event)" value="mediana">Mediana</mat-radio-button>
                    <mat-radio-button (change)="agregarPrecioG($event)" value="grande">Grande</mat-radio-button>
                </mat-radio-group>


            </div>

            <div [formGroupName]="i">
                <hr>
                <h4>Selecciona tu proteina</h4>

                <mat-radio-group formControlName="proteina" aria-label="Select an option">
                    <mat-radio-button value="pollo natural">Pollo natural</mat-radio-button>
                    <mat-radio-button value="pollo bbq">Pollo bbq</mat-radio-button>
                    <mat-radio-button value="jamon">jamon</mat-radio-button>
                    <mat-radio-button value="pollo frito">Pollo frito</mat-radio-button>
                    <mat-radio-button value="pollo especies">pollo especies</mat-radio-button>
                    <mat-radio-button value="atun">Atun</mat-radio-button>
                </mat-radio-group>

            </div>

            <div [formGroupName]="i">
                <hr>
                <h4>Selecciona tus ingredientes</h4>
                <ng-container formGroupName=ingredientes>
                    <mat-checkbox (change)="comprobarzanahoria(i)" formControlName="zanahoria">Zanahoria</mat-checkbox>
                    <mat-checkbox (change)="comprobartomate(i)" formControlName="tomate">Tomate</mat-checkbox>
                    <mat-checkbox (change)="comprobarbetabel(i)" formControlName="betabel">Betabel</mat-checkbox>
                    <mat-checkbox (change)="comprobarchampinon(i)" formControlName="champinon">Champinon</mat-checkbox>
                    <mat-checkbox (change)="comprobarjalapeno(i)" formControlName="jalapeno">Jalapeno</mat-checkbox>
                    <mat-checkbox (change)="comprobarcebolla(i)" formControlName="cebolla">Cebolla</mat-checkbox>
                    <mat-checkbox (change)="comprobaraceituna(i)" formControlName="aceituna">Aceituna</mat-checkbox>
                    <mat-checkbox (change)="comprobarpina(i)" formControlName="pina">Piña</mat-checkbox>
                    <mat-checkbox (change)="comprobarmorron(i)" formControlName="morron">Morron</mat-checkbox>
                    <mat-checkbox (change)="comprobarpanela(i)" formControlName="panela">Panela</mat-checkbox>
                    <mat-checkbox (change)="comprobarpasta(i)" formControlName="pasta">Pasta</mat-checkbox>
                    <mat-checkbox (change)="comprobarapio(i)" formControlName="apio">Apio</mat-checkbox>
                    <mat-checkbox (change)="comprobarcolmorado(i)" formControlName="colmorado">Colmorado</mat-checkbox>
                    <mat-checkbox (change)="comprobarpepino(i)" formControlName="pepino">Pepino</mat-checkbox>
                    <mat-checkbox (change)="comprobarespinaca(i)" formControlName="espinaca">Espinaca</mat-checkbox>
                    <mat-checkbox (change)="comprobargerminado(i)" formControlName="germinado">Germinado</mat-checkbox>
                    <mat-checkbox (change)="comprobarelote(i)" formControlName="elote">Elote</mat-checkbox>
                    <mat-checkbox (change)="comprobarbrocoli(i)" formControlName="brocoli">Brocoli</mat-checkbox>
                </ng-container>
            </div>

            <div [formGroupName]="i">
                <hr>
                <h4>Elige un aderezo</h4>

                <mat-radio-group formControlName="aderezos" aria-label="Select an option">
                    <mat-radio-button value="ranch">ranch</mat-radio-button>
                    <mat-radio-button value="mil islas">mil islas</mat-radio-button>
                    <mat-radio-button value="cesar">cesar</mat-radio-button>
                    <mat-radio-button value="italiano">italiano</mat-radio-button>
                    <mat-radio-button value="mostaza miel">mostaza miel</mat-radio-button>
                    <mat-radio-button value="chimchurry">chimchurry</mat-radio-button>
                </mat-radio-group>

            </div>
            <div [formGroupName]="i">
                <hr>
                <h4>Elige un Complemento</h4>

                <mat-radio-group formControlName="complementos" aria-label="Select an option">
                    <mat-radio-button value="parmesano">Parmesano</mat-radio-button>
                    <mat-radio-button value="crotones">Crotones</mat-radio-button>
                    <mat-radio-button value="chilequebrado">Chile</mat-radio-button>
                    <mat-radio-button value="almendras">Almendras</mat-radio-button>
                    <mat-radio-button value="aceitunas">Aceitunas</mat-radio-button>

                </mat-radio-group>

            </div>
            <div [formGroupName]="i">
                <hr>
                <h4>Agrega Extras</h4>
                <mat-form-field appearance="fill">
                    <mat-label>Extras</mat-label>
                    <mat-select formControlName="extras" multiple>
                        <mat-option *ngFor="let complementos of TodosExtra" [value]="complementos">
                            {{complementos.viewValue}} ${{complementos.precio}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="text-center">
                <button mat-raised-button (click)="removeSkill(i)">Remove</button>

            </div>


        </div>

        <!-- fin de alter -->
    </div>
    <br>


    <div class="text-center" *ngIf="alternateTodos.controls.length === 0">
        <button type="button" mat-raised-button [disabled]="comandaformulario.get('alternateTodos')?.invalid" *ngIf="mostrarBotonesMas" (click)="addtamano() ">Ordenar</button>

    </div>

    <div class="text-center" *ngIf="alternateTodos.controls.length > 0">
        <button type="button" mat-raised-button [disabled]="comandaformulario.get('alternateTodos')?.invalid" *ngIf="mostrarBotonesMas" (click)="addtamano() ">Agregar ensalada</button>

        <button type="button" mat-raised-button [disabled]="comandaformulario.get('alternateTodos')?.invalid" (click)="terminar()">Terminar</button>

    </div>

    <br>
    <div class="complementos" *ngIf="mostrarComplementos">
        <hr>
        <mat-form-field>
            <mat-label>Cliente</mat-label>
            <input matInput formControlName="nombre">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Teléfono</mat-label>

            <input formControlName="telefono" type="tel" matInput placeholder="555-555-1234">

        </mat-form-field>
        <br>
        <mat-checkbox (change)="mostrardireccion()" formControlName="mostrarDireccion">
            <div *ngIf="!mostrarDireccionView">
                Enviar / Agregar direccion
            </div>
            <div *ngIf="mostrarDireccionView">
                Pasar a buscar
            </div>
        </mat-checkbox>
        <mat-form-field class="full" *ngIf="mostrarDireccionView">
            <mat-label>Direccion</mat-label>

            <input formControlName="direccion" matInput placeholder="Avenida... Calle... Lote...">

        </mat-form-field>

        <br>
        <mat-form-field>

            <textarea formControlName="especificaciones" rows="4" matInput placeholder="Notas..."></textarea>
        </mat-form-field>
        <mat-checkbox formControlName="aceptarcondiciones">Aceptar condiciones</mat-checkbox>
        <mat-form-field>
            <mat-label>Con cuento se pagara</mat-label>

            <input formControlName="pagocliente" matInput placeholder=" ">

        </mat-form-field>



    </div>

    <div *ngIf="mostrarBotonesEnviar">


        <button type="submit" [disabled]="comandaformulario.invalid" mat-raised-button>Pedir</button>
    </div>




</form>


<app-footer></app-footer>
<!-- 
{{this.comandaformulario.value | json}} -->