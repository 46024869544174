import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-alitas',
  templateUrl: './dialog-alitas.component.html',
  styleUrls: ['./dialog-alitas.component.css']
})

export class DialogAlitasComponent implements OnInit {
  alitasForm: FormGroup;
  combinado:boolean;
  constructor(public fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogAlitasComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
    this.combinado= false;
    this.alitasForm = this.fb.group({
      tipo1: new FormControl('', Validators.required),
      tipo2: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.alitasForm = this.fb.group({
      tipo1: new FormControl('', Validators.required),
      tipo2: new FormControl('')
    });
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.alitasForm.value.tipo1 === '' ){
      console.log('no hay datos: secundario');
    }

    if (this.alitasForm.value){
      this.dialogRef.close(this.alitasForm.value);
    }

  }
  combinar(){
    this.combinado = !this.combinado;
    this.alitasForm.controls.tipo2.setValue('');


  }

}
