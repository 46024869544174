<h2 mat-dialog-title>Tenders</h2>

<mat-dialog-content class="pt-3" [formGroup]="tendersForm">
<!--     <h3>Tipo de Baguette</h3> -->
    <mat-radio-group formControlName="aderezos" aria-label="Select an option">
        <mat-radio-button value="ranch">Ranch</mat-radio-button>
        <mat-radio-button value="mil islas">Mil islas</mat-radio-button>
        <mat-radio-button value="cesar">Cesar</mat-radio-button>
        <mat-radio-button value="italiano">Italiano</mat-radio-button>
        <mat-radio-button value="mostaza miel">Mostaza miel</mat-radio-button>
        <mat-radio-button value="chimchurry">Chimchurry</mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="tendersForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>