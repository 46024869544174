import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-hotcakes',
  templateUrl: './dialog-hotcakes.component.html',
  styleUrls: ['./dialog-hotcakes.component.css']
})
export class DialogHotcakesComponent implements OnInit {

  hotcakeForm:FormGroup;
  constructor(public fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogHotcakesComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.hotcakeForm = this.fb.group({
        tipo: new FormControl('', Validators.required)
      });

     }

  ngOnInit(): void {
    this.hotcakeForm = this.fb.group({
      tipo: new FormControl('', Validators.required)
    });
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.hotcakeForm.value.tipo === '' ){
      console.log('no hay datos: secundario');
    }

    if (this.hotcakeForm.value){
      this.dialogRef.close(this.hotcakeForm.value);
    }

  }

}
