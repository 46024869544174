<h2 mat-dialog-title>
    Ver su orden
</h2>


<!-- probar -->
<mat-dialog-content >
    <p *ngIf="data.Precio.length == 0 ">
        Carrito vacio
    </p>
    <ol class="color">
        <div *ngFor="let item of data.Texto; index as i">
            <div class="d-flex justify-content-between pb-2">
                <div class="align-self-center row">
                    <div class="col-8 expandir">
                        <li class="d-inline">
                            {{item}}
                        </li>
                    </div>
                    <div class="col-1">
                        <p class="d-inline ">${{data.Precio[i]}}</p>
                    </div>
                </div>
                
                <button (click)="delete(i)" class="btn btn-danger borrar"><mat-icon>clear</mat-icon></button>
            </div>
        </div>
        <div class="text-end precio">
            <p class="fw-bold">Total: ${{precio}}</p>
        </div>
    </ol>
    <form *ngIf="mostrarComplementos" class="text-center" [formGroup]="comandaformulario" (ngSubmit)="guardar(comandaformulario)">
            <div class="complementos" *ngIf="mostrarComplementos || data.Precio.length >= 0">
                <hr>
                <mat-form-field>
                    <mat-label>Cliente</mat-label>
                    <input matInput type="text" formControlName="nombre">
                    <mat-error >Ingresa un nombre correcto</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Teléfono</mat-label>

                    <input formControlName="telefono" type="tel" matInput placeholder="555-555-1234">
                    <mat-error >Ingresa un número de teléfono correcto</mat-error>
                </mat-form-field>
                <br>
                <mat-radio-group (change)="mostrardireccion()" class="opcionEnvio" formControlName="opcionEnvio" aria-label="Select an option">
                    <mat-radio-button class="p-2" value="1">Comer aquí</mat-radio-button>
                    <mat-radio-button class="p-2" value="2">Pasar a buscar</mat-radio-button>
                    <mat-radio-button class="p-2" value="3">Llevar</mat-radio-button>
                </mat-radio-group>
                 <div #mapDiv *ngIf="mostrarDireccionView" id='mapa'>
                </div>
                <mat-form-field class="full" *ngIf="mostrarDireccionView">
                    <mat-label>Dirección</mat-label>
                    

                    <input formControlName="direccion" type="text" matInput placeholder="Avenida... Calle... Lote...">
                    <mat-error> Ingresa una dirección correcta</mat-error>
                </mat-form-field>
               
                <br>
                <mat-form-field>

                    <textarea formControlName="especificaciones" rows="4" matInput placeholder="Notas..."></textarea>
                    <mat-error> Escribe el texto correctamente</mat-error>
                </mat-form-field>
               <!--  <mat-checkbox formControlName="aceptarcondiciones">Aceptar condiciones</mat-checkbox> -->
                <mat-form-field>
                    <mat-label>Con cuanto se pagará</mat-label>



                    <!-- CHECAR QUE FUNCIONE EL MAT ERROR -->

                    <input formControlName="pagocliente" matInput placeholder=" ">
 
                    <mat-hint class="text-danger" *ngIf="comandaformulario.controls.pagocliente.touched && comandaformulario.value.pagocliente <= precio-1" >Ingresa un numero correcto</mat-hint>
                   
                    <span matPrefix>$&nbsp;</span>
                    <span matSuffix>.00</span>
                </mat-form-field>
            </div>
        <button type="submit" class="verde" [disabled]="comandaformulario.invalid" *ngIf="mostrarComplementos" mat-raised-button>Finalizar pedido</button>
    </form>    
</mat-dialog-content>

<mat-dialog-actions align="center">

        <button type="button" *ngIf="!mostrarComplementos && precio" class="mat-raised-button verde m-2" (click)="clickOK()">Finalizar su compra</button>
        <button mat-raised-button type="button" class="mat-raised-button m-2" color="warn" mat-dialog-close>Seguir comprando</button>

</mat-dialog-actions>

<br>
<div class="text-center fw-bold" *ngIf="precio">
    <p>Total: ${{precio}}</p>
</div>
<div #mapDiv >
</div>