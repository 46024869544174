<h2 mat-dialog-title>Paquete desayuno</h2>

<mat-dialog-content [formGroup]="paquetedesayunoForm">
    <div class="desayuno">
        <mat-radio-group  formControlName="desayuno" aria-label="Select an option">
            <h3>Huevo</h3>
            <mat-radio-button value="Huevos estrellado">Estrellado</mat-radio-button>
            <mat-radio-button value="Huevos a la mexicana">A la Mexicana</mat-radio-button>
            <mat-radio-button value="Huevos motulenos">Motuleños</mat-radio-button>
            <mat-radio-button value="Huevos divorciados">Divorciados</mat-radio-button>
            <mat-radio-button value="Huevos rancheros">Rancheros</mat-radio-button>
            <mat-radio-button value="Huevos revueltos">Revueltos</mat-radio-button>
            <mat-radio-button value="Huevos con jamon">Jamón</mat-radio-button>
            <mat-radio-button value="Huevos con chorizo">Chorizo</mat-radio-button>
            <mat-radio-button value="Huevos con manchego">Queso Manchego</mat-radio-button>
            <h3>Quesadillas</h3>
            <mat-radio-button value="Quesadillas naturales">Naturales (solo queso)</mat-radio-button>
            <mat-radio-button value="Quesadillas con chorizo">Chorizo</mat-radio-button>
            <mat-radio-button value="Quesadillas con pollo deshebrado">Pollo deshebrado</mat-radio-button>
            <mat-radio-button value="Quesadillas con champiñones">Champiñon</mat-radio-button>
            <mat-radio-button value="Quesadillas con jamón">Jamón</mat-radio-button>
            <h3>Chilaquiles</h3>
            <mat-radio-button value="Chilaquiles verde">Verde</mat-radio-button>
            <mat-radio-button value="Chilaquiles rojo">Rojo</mat-radio-button>
            <mat-radio-button value="Chilaquiles mole">Mole</mat-radio-button>
            <h3>Molletes</h3>
            <mat-radio-button value="Molletes naturales">Naturales (solo queso)</mat-radio-button>
            <mat-radio-button value="Molletes con chorizo">Chorizo</mat-radio-button>
            <mat-radio-button value="Molletes pollo deshebrado">Pollo deshebrado</mat-radio-button>
            <mat-radio-button value="Molletes con champiñones">Champiñon</mat-radio-button>
            <mat-radio-button value="Molletes con jamón">Jamón</mat-radio-button>
            <h3>Hot cakes</h3>
            <mat-radio-button value="Hot cakes">Hot cakes</mat-radio-button>
            <h3>Enchiladas</h3>
            <mat-radio-button value="Enchiladas verdes">Verde</mat-radio-button>
            <mat-radio-button value="Enchiladas rojos">Rojo</mat-radio-button>
            <mat-radio-button value="Enchiladas moles">Mole</mat-radio-button>
            <h3>Tostadas</h3>
            <mat-radio-button value="Tostadas">Tostadas</mat-radio-button>

        </mat-radio-group>
    </div>
    <br>
    <div class="platofruta">
        <h3>Plato de fruta incluida</h3>
        <mat-radio-button disabled="true" checked="true" value=true>Fruta</mat-radio-button>
    </div>
     <br>
    
    <div class="bebida">
        <mat-radio-group  formControlName="bebida" aria-label="Select an option">
            <h3>Bebida</h3>
            <mat-radio-button value="Jugo">jugo</mat-radio-button>
            <mat-radio-button value="Café">Café</mat-radio-button>
        </mat-radio-group>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="paquetedesayunoForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>