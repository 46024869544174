<div class="contenido">

    <img  #inicio src="assets/fr2.png" class=" d-block w-100 " alt="...">

    <br>


    <h1 class="text-center">Fast'n Green campeche</h1>
    <!-- shopping car -->
    <div class="container">
        <!-- Carrito -->
        <button type="button" (click)="openCarrito()" class="btn carrito position-relative " [class.carritoAnimation]="carritoTexto.length > 0">
        <mat-icon class="icono">shopping_cart</mat-icon>
        <span *ngIf="carritoTexto.length > 0"  class="position-absolute top-0 start-100 translate-middle badge bg-light text-dark">
            {{carritoTexto.length}}
        </span>
        </button>
        <!-- icono peso -->
        <div  class="btn money position-relative">
            <mat-icon >attach_money</mat-icon>
             </div>
        <!--div suma-->
        <div  class="btn precio position-relative">
            <p >{{suma(carritoPrecio)}}</p>
       </div>

        <button (click)="openCarrito()" *ngIf="carritoTexto.length > 0" class="btn terminarAnimation position-relative ">
            Finalizar orden
        </button>
    </div>
    <!-- NAVEGACION vertical -->
    <ul class="nav d-flex flex-nowrap overflow-scroll navegacion">
        <li class="nav-item">
            <a class="nav-link" (click)="scroll(Desayunos)">Desayunos</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="scroll(Entradas)">Entradas</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="scroll(Ensaladas)">Ensaladas</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="scroll(Baguettes)">Baguettes</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="scroll(Especialidades)">Especialidades</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="scroll(Pastas)">Pastas</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="scroll(Bebidas)">Bebidas</a>
        </li>

    </ul>

    <!-- Todas las comidas -->
    <div class="container">
        <div class="list-group">
            <!-- lista de comidas -->
             <!-- ------------------------------------ Desayunos ------------------------------------ -->
             <h3 class="text-center"  #Desayunos>Desayunos</h3>
             <div #omele>
                 <a class="list-group-item list-group-item-action noborder " (click)="openOmelet()" aria-current="true">
                     <div class="d-flex w-100 justify-content-between">
                         <div class="img-pequena">
                             <div class=" me-auto">
                                 <div class="fw-bold">Omelet</div>
                                 <h5>$ 110</h5>
                             </div>

                             <small>Tortilla de huevo rellena de ingredientes al gusto.</small>
                         </div>
                         <div class="img-pequena">
                             <img class="img-fluid" src="assets/omelet.jpg" alt="">
                         </div>
                     </div>
                 </a>
             </div>
             <div #chilaquiles>
                 <a class="list-group-item list-group-item-action noborder " (click)="openChilaquiles()" aria-current="true">
                     <div class="d-flex w-100 justify-content-between">
                         <div class="img-pequena">
                             <div class=" me-auto">
                                 <div class="fw-bold">Chilaquiles</div>
                                 <h5>$ 110</h5>
                             </div>

                             <small>Totopos dorados con pollo o huevo bañado en salsa de su elección.</small>
                         </div>
                         <div class="img-pequena">
                             <img class="img-fluid" src="assets/chilaquiles.jpg" alt="">
                         </div>
                     </div>
                 </a>
             </div>
             <div #hotcakes class="pb-2">
                 <a class="list-group-item list-group-item-action noborder " (click)="openHotcakes()" aria-current="true">
                     <div class="d-flex w-100 justify-content-between">
                         <div class="img-pequena">
                             <div class=" me-auto">
                                 <div class="fw-bold">Hot cakes</div>
                                 <h5>$ 110</h5>
                             </div>

                             <small>Mezcla de harina de hot cakes de la casa con plátano.</small>
                         </div>
                         <div class="img-pequena">
                             <img class="img-fluid" src="assets/hotcakes.jpg" alt="">
                         </div>
                     </div>
                 </a>

             </div>
             <div #huevos>
                 <a class="list-group-item list-group-item-action noborder " (click)="openHuevo()" aria-current="true">
                     <div class="d-flex w-100 justify-content-between">
                         <div class="img-pequena">
                             <div class=" me-auto">
                                 <div class="fw-bold">Huevos al gusto</div>
                                 <h5>$ 110</h5>
                             </div>

                             <!-- <small>Una selección de nuestras especialidades con huevo.</small> -->
                             <small>Estrellado, A la Mexicana, Motuleños, Divorciados, Ranchero, Revueltos, Jamón, Chorizo, Queso Manchego
                            </small>


                         </div>
                         <div class="img-pequena">
                             <img class="img-fluid" src="assets/huevosmotulenos.jpg" alt="">
                         </div>
                     </div>
                 </a>
             </div>
             <div #molletes class="pb-2">
                 <a class="list-group-item list-group-item-action noborder " (click)="openMolletes()" aria-current="true">
                     <div class="d-flex w-100 justify-content-between">
                         <div class="img-pequena">
                             <div class=" me-auto">
                                 <div class="fw-bold">Molletes</div>
                                 <h5>$ 110</h5>
                             </div>

                             <!-- <small>Orden de 4 piezas de pan con frijol y nuestra selección de ingredientes.</small> -->
                             <small>Piezas de pan cubiertos con frijol, queso y nuestra selección de ingredientes</small>
                         </div>
                         <div class="img-pequena">
                             <img class="img-fluid" src="assets/molletes.jpg" alt="">
                         </div>
                     </div>
                 </a>
             </div>
             <div #tostadas class="pb-2">
                 <a class="list-group-item list-group-item-action noborder " (click)="openTostadas()" aria-current="true">
                     <div class="d-flex w-100 justify-content-between">
                         <div class="img-pequena">
                             <div class=" me-auto">
                                 <div class="fw-bold">Tostadas</div>
                                 <h5>$ 110</h5>
                             </div>

                             <small>Tortilla dorada con frijol, pollo molido cubierto de lechuga, zanahoria, queso panela y betabel rayado.</small>
                         </div>
                         <div class="img-pequena">
                             <img class="img-fluid" src="assets/tostadas.jpg" alt="">
                         </div>
                     </div>
                 </a>
             </div>
             <div #quesadillas class="pb-2">
                 <a class="list-group-item list-group-item-action noborder " (click)="openQuesadillas()" aria-current="true">
                     <div class="d-flex w-100 justify-content-between">
                         <div class="img-pequena">
                             <div class=" me-auto">
                                 <div class="fw-bold">Quesadillas</div>
                                 <h5>$ 110</h5>
                             </div>

                             <small>Tortilla de harina rellenas de queso y 6 especialidades a elegir.</small>
                         </div>
                         <div class="img-pequena">
                             <img class="img-fluid" src="assets/quesadillas.jpg" alt="">
                         </div>
                     </div>

                 </a>
             </div>
             <div #enchiladas class="pb-2">
                 <a class="list-group-item list-group-item-action noborder " (click)="openEnchiladas()" aria-current="true">
                     <div class="d-flex w-100 justify-content-between">
                         <div class="img-pequena">
                             <div class=" me-auto">
                                 <div class="fw-bold">Enchiladas</div>
                                 <h5>$ 110</h5>
                             </div>

                             <small>Tortilla rellena de  pollo deshebrado y  bañado con salsa de su elección.</small>
                         </div>
                         <div class="img-pequena">
                             <img class="img-fluid" src="assets/enchiladas.jpg" alt="">
                         </div>
                     </div>
                 </a>
             </div>
             <div #sandwich class="pb-2">
                 <a class="list-group-item list-group-item-action noborder " (click)="openSandwich()" aria-current="true">
                     <div class="d-flex w-100 justify-content-between">
                         <div class="img-pequena">
                             <div class=" me-auto">
                                 <div class="fw-bold">Sandwich</div>
                                 <h5>$ 110</h5>
                             </div>

                             <small>Sandwich integral de tres pisos con huevo,pollo, jamón y verduras.</small>
                         </div>
                         <div class="img-pequena">
                             <img class="img-fluid" src="assets/sandwich.jpg" alt="">
                         </div>
                     </div>
                 </a>
             </div>
             <div #Cocteldefrutas>
                <a class="list-group-item list-group-item-action noborder " (click)="openCocteldefrutas()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Coctel de frutas</div>
                                <h5>$ 80</h5>
                            </div>

                            <small>Sandia, Melon, piña, papaya, platano o fruta de temporada</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/cocteldefrutas.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
             <div #PaqueteDesayuno>
                <a class="list-group-item list-group-item-action noborder " (click)="openPaquetedesayuno()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Paquete desayuno</div>
                                <h5>$ 130</h5>
                            </div>

                            <small>A elegir: huevos, quesadillas, chilaquiles, molletes,hot cakes, enchiladas o tostadas incluye fruta, jugo o café</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/paquetedesayuno.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
             <hr>
            <!-- ------------------------------------ Entradas ------------------------------------ -->
            <h3 class="text-center" #Entradas>Entradas</h3>
            <!-- guacamole -->
            <div #guacamole class="pb-2">
                <a class="list-group-item list-group-item-action noborder " (click)="openGuacamole()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Guacamole</div>
                                <h5>$ 110</h5>
                            </div>

                            <small>Aguacate machacado acompañado con jitomate, limón, cebolla, cilantro, aceite de oliva y totopos.</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/guacamole.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <!-- tostadas francesas -->
            <div #tostadasfrancesa class="pb-2">
                 <a class="list-group-item list-group-item-action noborder " (click)="openTostadaFrancesa()" aria-current="true">
                     <div class="d-flex w-100 justify-content-between">
                         <div class="img-pequena">
                             <div class=" me-auto">
                                 <div class="fw-bold">Tostadas francesas</div>
                                 <h5>$ 110</h5>
                             </div>

                             <small>Pan mojado con una mezcla de huevo y leche, frito acompañado con lechera o miel.</small>
                         </div>
                         <div class="img-pequena">
                             <img class="img-fluid" src="assets/tostadasfrancesas.jpg" alt="">
                         </div>
                     </div>
                 </a>
            </div>
            <!-- orden de pan tostado -->
            <div #ordendepantostado class="pb-2">
                <a class="list-group-item list-group-item-action noborder " (click)="opedDePanTostado()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Orden de pan tostado</div>
                                <h5>$ 90</h5>
                            </div>

                            <small>Pan perfectamente tostado con mermelada de fresa y mantequilla para acompañar. </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/pantostado.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <!-- orden de frijol -->
            <div #ordendefrijol class="pb-2">
                <a class="list-group-item list-group-item-action noborder " (click)="openDeFrijol()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Orden de frijol</div>
                                <h5>$ 60</h5>
                            </div>

                            <small>Rico Frijol de la casa acompañado con tostopos </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/ordendefrijol.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <hr>
            <!-- ------------------------------------ Ensaladas ------------------------------------ -->
            <h3 class="text-center" #Ensaladas>Ensaladas</h3>
            <!-- Ensalada -->
            <div #ensalada>
                <a class="list-group-item list-group-item-action noborder " (click)="openEnsalada()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Ensalada</div>
                                <h5>Ch $130 Me $140 Gr $150</h5>
                            </div>

                            <small>Ensalada de lechuga fresca con 18 ingredientes a elegir  un aderezo y un
                            complemento.
                        </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/ensaladagrande.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Ensaladagourmed62proAtun>
                <a class="list-group-item list-group-item-action noborder " (click)="openEnsaladaGour62proAtun()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Ensalada gourmet 62 pro Atun</div>
                                <h5>$ 140</h5>
                            </div>
                            <small>
                                Combinación de espinacas, lechuga, brócoli, elote, zanahoria y pasta con atún y almendras, aderezada con ranch.
                            </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/Ensaladagourmed62pro.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Ensaladagourmed62proPollo>
                <a class="list-group-item list-group-item-action noborder " (click)="openEnsaladaGour62proPollo()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Ensalada gourmet 62 pro Pollo</div>
                                <h5>$ 140</h5>
                            </div>
                            <small>
                                Combinación de espinacas, lechuga, brócoli, elote, zanahoria y pasta con pollo y almendras, aderezada con ranch.
                            </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/Ensaladagourmed62pro.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Ensaladagourmedhot>
                <a class="list-group-item list-group-item-action noborder " (click)="openEnsaladaGourHot()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Ensalada gourmet hot</div>
                                <h5>$ 140</h5>
                            </div>

                            <small>
                                Exquisito pollo bañado en BBQ con lechuga, pasta, apio, elote, cebolla morada y chile jalapeño, mezclados con aderezo chimichurri.
                            </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/Ensaladagourmedhot.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Ensaladagourmeddulce>
                <a class="list-group-item list-group-item-action noborder " (click)="openEnsaladaGourDulce()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Ensalada gourmet dulce </div>
                                <h5>$ 140</h5>
                            </div>

                            <small>
                                Deliciosa combinación de piña, brócoli, elote, panela y lechuga fresca con pollo empanizado crujiente acompañada con aderezo mostaza y miel
                            </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/Ensaladagourmeddulce.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Ensaladagourmed3quesos>
                <a class="list-group-item list-group-item-action noborder " (click)="openEnsaladaGour3Quesos()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Ensalada gourmet 3 quesos </div>
                                <h5>$ 140</h5>
                            </div>
                            <small>
                                Cama de lechuga con jitomate, pasta, morrón, cebolla morada, parmesano, panela, gouda con pollo sazonado a las finas hiervas con aderezo cesar
                            </small>

                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/Ensaladagourmed3quesos.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Ensaladagourmedcesar>
                <a class="list-group-item list-group-item-action noborder " (click)="openEnsaladaGourcCesar()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Ensalada gourmet cesar </div>
                                <h5>$ 140</h5>
                            </div>

                        <!--<small>Tradicional ensalada de la casa con una deliciosa combinación de lechuga, jitomate, crotones y pollo aderezado con cesar y parmesano.   </small> -->
                            <small>Combinación de lechuga, panela, tomate, crotones y pollo con aderezo césar</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/Ensaladagourmedcesar.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Ensaladagourmedgreca>
                <a class="list-group-item list-group-item-action noborder " (click)="openEnsaladaGourGreca()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Ensalada gourmet greca </div>
                                <h5>$ 140</h5>
                            </div>

                            <small>
                                Sabroso pollo asado con una mezcla de lechuga fresca, jitomate, apio, col morada, panela, aderezado con vinagreta y aceite de oliva.
                            </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/Ensaladagourmedgreca.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <hr>
            <!-- ------------------------------------ Baguettes ------------------------------------ -->
            <h3 class="text-center" #Baguettes>Baguettes</h3>
            <!-- Baguette -->
            <div >
                <a class="list-group-item list-group-item-action noborder " (click)="openDialogBaguette()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Baguette</div>
                                <h5>$ 110</h5>
                            </div>

                            <small>Pan de baguette 12 especialidades a elegir con base de tomate, pepino,
                            cebolla y lechuga.
                        </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/baguette.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <hr>
            <!-- ------------------------------------ Especialidades ------------------------------------ -->
            <h3 class="text-center" #Especialidades>Especialidades</h3>
            <!--  Orden de pollo -->
            <div #ordendepollo>
                <a class="list-group-item list-group-item-action noborder " (click)="openDialogOrd()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Orden de pollo</div>
                                <h5>$ 140</h5>
                            </div>

                            <small>Pollo de su elección acompañado de ensalada verde y espagueti.
                        </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/ordendepollo.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <!-- Tenders -->
            <div #tenders>
                <a class="list-group-item list-group-item-action noborder " (click)="openTenders()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Tenders</div>
                                <h5>$ 130</h5>
                            </div>

                            <small>
                            Tiras de pollo empanizado en una cama de lechuga, tomacte, cebolla y pepino acompañado con champiñones salteados y
                            queso parmesano.
                        </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/tenders.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <!-- Wraps -->
            <div #wraps>
                <a class="list-group-item list-group-item-action noborder " (click)="openDialogWrap()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Wraps</div>
                                <h5>$ 130</h5>
                            </div>

                            <small>
                            Tortilla burrera rellena de pollo, tomate, cebolla y pimiento morrón y un aderezo a elegir.
                        </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/wraps.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <!-- Alitas -->
            <div #alitas>
                <a class="list-group-item list-group-item-action noborder " (click)="openAlitas()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Alitas</div>
                                <h5>$ 130</h5>
                            </div>

                            <small>Alitas de pollo marinadas bañadas con salsa de la casa.</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/alitas.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <!-- Ramen -->
            <div #Ramen class="pb-2">
                <a class="list-group-item list-group-item-action noborder " (click)="openRamen()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Ramen</div>
                                <h5>$ 110</h5>
                            </div>

                            <small>
                            Delicioso Ramen de la casa acompañado con verduras pollo y champiñón
                            salteado.
                        </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/ramen.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <!-- Taco Fresh -->
            <div #tacofresh class="pb-2">
                <a class="list-group-item list-group-item-action noborder " (click)="openTacoFresh()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Taco Fresh</div>
                                <h5>$ 110</h5>
                            </div>

                            <small>5 Tacos de tortilla dorada de pollo molido con base de verduras y aderezo.</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/tacofresh.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <!-- Alambre de pollo -->
            <div #alambre class="pb-2">
                <a class="list-group-item list-group-item-action noborder " (click)="openAlambre()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Alambre de pollo</div>
                                <h5>$ 140</h5>
                            </div>
                            <small>
                            Alambre de pollo natural a la plancha salteado con morrón, cebolla
                            champiñón, piña y gratinado con queso.
                        </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/alambre.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <hr>

            <!-- ------------------------------------ Pastas ------------------------------------ -->
            <h3 class="text-center" #Pastas>Pastas</h3>
            <div #Pastaboloñesa>
                <a class="list-group-item list-group-item-action noborder " (click)="OpenPastaBolognesa()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Pasta boloñesa</div>
                                <h5>$ 140</h5>
                            </div>

                            <small>Salsa de roja italiana con carne molida, acompañada con pimiento, cebolla y champiñones.   </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/Pastaboloñesa.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Pasta3quesos>
                <a class="list-group-item list-group-item-action noborder " (click)="OpenPasta3Quesos()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Pasta 3 quesos</div>
                                <h5>$ 140</h5>
                            </div>

                            <small>Pasta bañada en nuestra salsa de 3 quesos(parmesano, manchego y
                                panela).
                                </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/Pasta3quesos.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Pastaestilotariyaki>
                <a class="list-group-item list-group-item-action noborder " (click)="OpenPastaTariyaki()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Pasta estilo tariyaki </div>
                                <h5>$ 140</h5>
                            </div>

                            <small>Pollo salteado con morrón zanahori, cebolla y espagueti bañado en
                                salsa teriyaki de la casa
                                </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/PASTATERIYAKI.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Pastaespagueti>
                <a class="list-group-item list-group-item-action noborder " (click)="OpenPastaEspaguetti()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Pasta espagueti </div>
                                <h5>$ 70</h5>
                            </div>

                            <small>Pasta bañada con salsa italiana de la casa y queso parmesano. </small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/SPAGUETTI.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>

            <hr>
            <!-- ------------------------------------ Bebidas ------------------------------------ -->
            <h3 class="text-center" #Bebidas>Bebidas</h3>
            <!--  Licuados -->

            <div #AguasDeFrutas>
                <a class="list-group-item list-group-item-action noborder " (click)="openAguadefrutas()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Aguas de frutas </div>
                                <h5>Vaso $ 45 Copa $ 55</h5>
                            </div>

                            <small>Piña, melón, sandia, papaya y limón</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/limonada.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #AguasDeFrutasTemporada>
                <a class="list-group-item list-group-item-action noborder " (click)="openAguadefrutasdetemporada()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Aguas de frutas de temporada </div>
                                <h5>Copa $ 60  Jarra $ 120</h5>
                            </div>

                            <small>Fresa, guayaba, pitahaya, marañon, nance y mango</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/pitahaya.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #Licuado>
                <a class="list-group-item list-group-item-action noborder " (click)="openLicuados()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Licuados</div>
                                <h5>Copa $ 60</h5>
                            </div>

                            <small>Melón, papaya, avena, platano, fresa y chocomilk</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/licuadodeavena.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #AguasDeFrutasCombinada>
                <a class="list-group-item list-group-item-action noborder " (click)="openAguadefrutascombinada()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Aguas de frutas combinada</div>
                                <h5>Copa $ 70  Jarra $ 130</h5>
                            </div>
                            <small>Piña/Apio, Piña/Pepino, Pepino/Limón, Pepino/Chaya, Pepinpo/Perejil</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/pepinoylimon.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #jugonaranja>
                <a class="list-group-item list-group-item-action noborder " (click)="openJugodenaranja()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Jugo de naranja</div>
                                <h5>Copa $ 70  Jarra $ 140</h5>
                            </div>

                            <small></small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/jugonaranja.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #jugoDeFrutasCombinadas>
                <a class="list-group-item list-group-item-action noborder " (click)="openJugodefrutascombinado()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Jugo de frutas combinada</div>
                                <h5>Copa $ 80  Jarra $ 150</h5>
                            </div>

                            <small>jugo verde ,jugo de zanahoria, naranja/zanahoria, betabel/zanahoria, naranja/ betabel</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/betabelnaranjazanahoria.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #cafe>
                <a class="list-group-item list-group-item-action noborder " (click)="opencafe()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Café Americano</div>
                                <h5>$ 25</h5>
                            </div>
                            <small>Taza de café Americano</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/cafe.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div #refrescosembotellados>
                <a class="list-group-item list-group-item-action noborder " (click)="openRefrescosembotellados()" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="img-pequena">
                            <div class=" me-auto">
                                <div class="fw-bold">Refrescos embotellados</div>
                                <h5>$ 20  $ 30</h5>
                            </div>

                            <small>Agua natural,Agua mineral, cocacola</small>
                        </div>
                        <div class="img-pequena">
                            <img class="img-fluid" src="assets/refrescos.jpg" alt="">
                        </div>
                    </div>
                </a>
            </div>


        </div>
    </div>
</div>
<!-- Boton para subir  -->
<button
    type="button"
    class="btn btn-danger btn-floating btn-lg"
    id="btn-back-to-top"
    (click)="scroll(inicio)"
    >
    <i class="fas fa-arrow-up"></i>
</button>
<br>
<br>

<!-- <ul>
    <button mat-flat-button (click)="verCarritoTexto()">Ver carrito Texto</button>
</ul>
<ul>
    <button mat-flat-button (click)="verCarritoPrecio()">Ver carrito Precio</button>
</ul> -->
