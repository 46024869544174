import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-molletes',
  templateUrl: './dialog-molletes.component.html',
  styleUrls: ['./dialog-molletes.component.css']
})
export class DialogMolletesComponent implements OnInit {
  molletesForm: FormGroup;
  combinado:boolean;
  constructor(public fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogMolletesComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {

                this.combinado= false;
                this.molletesForm = this.fb.group({
                  tipo1: new FormControl('', Validators.required),
                  tipo2: new FormControl('')
                });
               }

  ngOnInit(): void {
    this.molletesForm = this.fb.group({
      tipo1: new FormControl('', Validators.required),
      tipo2: new FormControl('')
    });
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.molletesForm.value.tipo1 === '' ){
      console.log('no hay datos: secundario');
    }

    if (this.molletesForm.value){
      this.dialogRef.close(this.molletesForm.value);
    }

  }
  combinar(){
    this.combinado = !this.combinado;
    this.molletesForm.controls.tipo2.setValue('');


  }

}
