import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-pedido3',
  templateUrl: './pedido3.component.html',
  styleUrls: ['./pedido3.component.css']
})
export class Pedido3Component implements OnInit {

  comandaformulario: FormGroup;
  TodosExtra = [

    /* proteina */
    {value: '', viewValue: '*Selecciona*', precio: 0},
    {value: 'pollonatural', viewValue: 'pollo natural', precio: 20},
    {value: 'pollobbq', viewValue: 'pollo BBQ', precio: 20},
    {value: 'jamon', viewValue: 'Jamon', precio: 20},
    {value: 'pollofrito', viewValue: 'Pollo frito', precio: 20},
    {value: 'polloespecies', viewValue: 'Pollo especies', precio: 20},
    {value: 'atun', viewValue: 'Atun', precio: 20},

    /* ingredientes */
    {value: 'zanahoria', viewValue: '*zanahoria*', precio: 10},
    {value: 'tomate', viewValue: 'tomate', precio: 10},
    {value: 'betabel', viewValue: 'betabel', precio: 10},
    {value: 'champinon', viewValue: 'champinon', precio: 10},
    {value: 'jalapeno', viewValue: 'jalapeno', precio: 10},
    {value: 'cebolla', viewValue: 'cebolla', precio: 10},
    {value: 'aceituna', viewValue: 'aceituna', precio: 10},
    {value: 'pina', viewValue: 'pina', precio: 10},
    {value: 'morron', viewValue: 'morron', precio: 10},
    {value: 'panela', viewValue: 'panela', precio: 10},
    {value: 'pasta', viewValue: 'pasta', precio: 10},
    {value: 'apio', viewValue: 'apio', precio: 10},
    {value: 'colmorado', viewValue: 'colmorado', precio: 10},
    {value: 'pepino', viewValue: 'pepino', precio: 10},
    {value: 'espinaca', viewValue: 'espinaca', precio: 10},
    {value: 'germinado', viewValue: 'germinado', precio: 10},
    {value: 'elote', viewValue: 'elote', precio: 10},
    {value: 'brocoli', viewValue: 'brocoli', precio: 10},

    /* Aderezos */
    {value: 'ranch', viewValue: 'Ranch', precio: 10},
    {value: 'millislas', viewValue: 'Millislas', precio: 10},
    {value: 'cesar', viewValue: 'Cesar', precio: 10},
    {value: 'italiano', viewValue: 'Italiano', precio: 10},
    {value: 'mostazamiel', viewValue: 'Mostazamiel', precio: 10},
    {value: 'vinagreta', viewValue: 'Vinagreta', precio: 10},
    {value: 'chimchurry', viewValue: 'Chimchurry', precio: 10},
    /* Complementos */
    {value: 'parmesano', viewValue: 'Parmesano', precio: 10},
    {value: 'chilequebrado', viewValue: 'Chile quebrado', precio: 10},
    {value: 'crotones', viewValue: 'Crotones', precio: 10},
    {value: 'almendras', viewValue: 'Almendras', precio: 10},
    {value: 'aceitunas', viewValue: 'Aceitunas', precio: 10},

  ];
/* tamaño */
chicaa = [] as any;
medianaa = [] as any;
grandee = [] as any;
/* proteina */
pollonaturall = [] as any;
pollobbqq = [] as any;
jamonn = [] as any;
pollofritoo = [] as any;
polloespeciess = [] as any;
atunn = [] as any;


/* ingredientes */
zanahoriaa = [] as any;
tomatee = [] as any;
betabell = [] as any;
champinonn = [] as any;
jalapenoo = [] as any;
cebollaa = [] as any;
aceitunaa = [] as any;
pinaa = [] as any;
morronn = [] as any;
panelaa = [] as any;
pastaa = [] as any;
apioo = [] as any;
colmoradoo = [] as any;
pepinoo = [] as any;
espinacaa = [] as any;
germinadoo = [] as any;
elotee = [] as any;
brocolii = [] as any;

/* aderezos */
ranchh = [] as any;
millislass = [] as any;
cesarr = [] as any;
italianoo = [] as any;
mostazamiell = [] as any;
vinagretaa = [] as any;
chimchurryy = [] as any;
/* complementos */
parmesanoo = [] as any;
crotoness = [] as any;
chilequebradoo = [] as any;
almendrass = [] as any;
aceitunass = [] as any;

/* Extras */
extramostrar: any;
extporcionn: any;
extaderezoo: any;
extcomplementoo: any;
mostrarDireccionView: any;
mostrarBotonesMas: any;
mostrarBotonesTerminar1: any;
mostrarBotonesTerminar2: any;
mostrarComplementos: any;
mostrarBotonesEnviar: any;
/* definidos */
tamano = [] as any;
proteina = [] as any;
ingredientes = [] as any;
aderezos = [] as any;
complementos = [] as any;
extras = [] as any;
/* mensajes */
mensaje: string;
mensajeCliente: any;
mensajeTamano: any;
mensajeProteina: any;
mensajeIngredientes: any;
mensajeAderezos: any;
mensajeComplementos: any;
mensajeExtras: any;
mensajeEspecificaciones: any;
mensajeFinal: any;
mensajeFinalArray = [] as any;
mensajeIngre = [] as any;
mensajesEnsaladas = [] as any;
mensajepagocliente: any;
mensajedireccion: any;
precio =  0;
precioTamano = [] as any;
precioTamanoFinal = [] as any;
precioExtra = 0;
preciofinal = 0;
arrayExtras = [] as any;
todos: any;
linkCompleto: string;
ingredientesArray: Array<string> = ['cebolla', 'tomate', 'pepino'];


  get alternateTodos(): FormArray{

    return this.comandaformulario.get('alternateTodos')as FormArray;

  }
  get alternateIngredientes(): FormArray{

    return this.comandaformulario.get('alternateTodos')as FormArray;

  }

  constructor(public fb: FormBuilder) {

    this.mensaje = '';
    this.linkCompleto = '';
    this.mostrarComplementos = false;
    this.mostrarBotonesEnviar = false;
    /* console.log(this.mostrarComplementos); */


    this.comandaformulario = this.fb.group({

      nombre: ['', Validators.required],
      telefono: ['', Validators.required],
      aceptarcondiciones: ['', Validators.required],
      direccion: [''/* , Validators.required */],
      especificaciones: [''],
      pagocliente: [''],
      mostrarDireccion: [''],

      alternateTodos: this.fb.array([/* this.newEnsalada() */])

     });

  }

  ngOnInit(): void {
    this.mostrarBotonesMas = true;
    this.mostrarBotonesTerminar2 = false;
    this.mostrarBotonesTerminar2 = false;
  }


  addingredientes(): any{
    const arr = this.ingredientesArray.map( element => {
      return this.fb.control(false);
    });
    return this.fb.array(arr);
  }
  agregarPrecioC(event: any): any{
    const check = event.source._checked;

  }

  agregarPrecioM(event: any): any{
    const check = event.source._checked;

  }

  agregarPrecioG(event: any): any{
    const check = event.source._checked;

  }

// tslint:disable-next-line: typedef
  guardar(i: any){

    const nombre = i.value.nombre;
    const telefono = i.value.telefono;
    const especificaciones = i.value.especificaciones;
    const direccion = i.value.direccion;

    const numeroEnvio = 529811028203;
    const numeroFresh = 529812072331;
    const coma = ',';
    const espacio = '%20';
    this.mensajepagocliente = i.value.pagocliente;


/* Aqui abajo tengo que hacer los mensajes por ensaladas y guardarlas una por una y lego leerlas */


    console.log(i.value);
    this.mensajeIngre.shift();
    console.log(this.mensajeIngre);

    for (let x = 0; x < this.alternateTodos.length; x++) {

      let bForm = this.comandaformulario.get('alternateTodos')?.value[x];
      let bExtras = this.comandaformulario.get('alternateTodos')?.value[x].extras;

     /*
          console.log(bForm);
          console.log(bExtras);
     */
      let Tamano = this.comandaformulario.get('alternateTodos')?.value[x].tamano;
      if (Tamano === 'chica'){
            this.precioTamano.push(80);
        }else if (Tamano === 'mediana'){
          this.precioTamano.push(90);
        }else if (Tamano === 'grande'){
          this.precioTamano.push(100);
        }

      if (bExtras){
         for (let i = 0; i < bExtras.length; i++) {
               /*  console.log(this.comandaformulario.get('alternateTodos')?.value[x].extras.length); */
                const element: string = this.comandaformulario.get('alternateTodos')?.value[x].extras[i].value;
                const elementPrecio: string = this.comandaformulario.get('alternateTodos')?.value[x].extras[i].precio;
               /*  console.log(element); */
                this.arrayExtras.push(element);
                this.precioTamano.push(elementPrecio);

               /*  console.log(this.arrayExtras); */
                this.extras = this.arrayExtras.join('%20,');
                /* console.log(this.extras); */
          }
      }
      let total = 0;
      total = this.precioTamano.reduce((a: any, b: any) => a + b, 0);
      console.log(total);
      this.precioTamanoFinal.push(total);

      console.log(this.precioTamanoFinal);
      let Proteina = this.comandaformulario.get('alternateTodos')?.value[x].proteina;
      let Ingredientes = this.mensajeIngre[x].join('%20,');
      let Aderezos = this.comandaformulario.get('alternateTodos')?.value[x].aderezos;
      let Complementos = this.comandaformulario.get('alternateTodos')?.value[x].complementos;

      const numero = x + 1;
      const mensajeTamano = espacio + 'Ensalada' + espacio + numero + ':' + espacio + Tamano + coma;
      const mensajeProteina = espacio + Proteina + coma + espacio;
      const mensajeIngredientes = Ingredientes;
      const mensajeAderezos = espacio + coma + Aderezos;
      const mensajeComplementos = espacio + coma +  Complementos;


      const ensalada = mensajeTamano +
                       mensajeProteina +
                       mensajeIngredientes +
                       mensajeAderezos +
                       mensajeComplementos + '%20Extras:%20'  +
                       this.extras;

      this.mensajeFinalArray.push(ensalada);

      this.arrayExtras = [];
      this.precioTamano = [];
    }
    const totalEnsalada = this.precioTamanoFinal.reduce((a: any, b: any) => a + b, 0);
    const mensajeTotalEnsalada = espacio + '*' + 'Total' + '*' + ':'  + espacio + '$' + totalEnsalada;

    if (especificaciones){

      this.mensajeEspecificaciones = espacio + '*' + 'Nota' + '*' + ':'  + espacio + especificaciones;
    }else{
      this.mensajeEspecificaciones = '';
    }
    if (direccion){

      this.mensajedireccion = espacio + '*' + 'Envio' + '*' + ':' + espacio + direccion;
    }else{
      this.mensajedireccion = '';
    }
    this.mensajepagocliente = i.value.pagocliente;
    if (this.mensajepagocliente){

      this.mensajepagocliente = espacio + '*' +  'Pago' + '*' + ':'  + espacio + '$' + this.mensajepagocliente + espacio;
    }else{
      this.mensajepagocliente = '';
    }


    this.mensajeCliente = '*' + nombre + '*' + coma + espacio + telefono + coma;
    console.log(this.mensajeFinalArray);

    this.mensajeFinal =
      this.mensajeCliente +
      this.mensajeFinalArray.join('%20,') +
      this.mensajeEspecificaciones +
      this.mensajedireccion +
      this.mensajepagocliente +
      mensajeTotalEnsalada;

    console.log(this.mensajeFinal);
    this.linkCompleto =  'https://api.whatsapp.com/send?phone=+' + numeroEnvio + '&text=' + this.mensajeFinal;
    window.location.href = this.linkCompleto;

  }

// tslint:disable-next-line: typedef
  mostrardireccion(){
    const c = this.comandaformulario.value.mostrarDireccion;
/*     console.log(c); */

    if (c === true){
      this.mostrarDireccionView = true;

    }else{
      this.mostrarDireccionView = false;
      this.comandaformulario.controls.direccion.setValue('');

    }
  }
/* Formulario que se agrega por cada ensalada */
  newEnsalada(): FormGroup {
      return this.fb.group({
  
        tamano: new FormControl('', Validators.required),
        proteina: new FormControl('', Validators.required),
        aderezos: new FormControl('', Validators.required),
        complementos: new FormControl('', Validators.required),
  
        /* ingredientes */
         ingredientes : this.fb.group({
          zanahoria: new FormControl(),
          tomate: new FormControl(),
          betabel: new FormControl(),
          champinon: new FormControl(),
          jalapeno: new FormControl(),
          cebolla: new FormControl(),
          aceituna: new FormControl(),
          pina: new FormControl(),
          morron: new FormControl(),
          panela: new FormControl(),
          pasta: new FormControl(),
          apio: new FormControl(),
          colmorado: new FormControl(),
          pepino: new FormControl(),
          espinaca: new FormControl(),
          germinado: new FormControl(),
          elote: new FormControl(),
          brocoli: new FormControl(),
        }),
         extras: new FormControl(),
      });
  }

 // tslint:disable-next-line: typedef
  addtamano() {
    this.alternateTodos.push(this.newEnsalada());
    this.preciofinal += this.precioTamano;
    console.log(this.mostrarBotonesTerminar1);

    if (this.ingredientes  != null){
      this.mensajeIngre.push(this.ingredientes);
      this.ingredientes = [];
    }

  }
// tslint:disable-next-line: typedef
  removeSkill(i: number) {
  this.alternateTodos.removeAt(i);

  }
// tslint:disable-next-line: typedef
  terminar(){
  
    this.mostrarComplementos = true;
    this.mostrarBotonesEnviar = true;
    this.mensajeIngre.push(this.ingredientes);
    this.ingredientes = [];
  }


/* comprobacion tamaño */
comprobarChica(a: number): any{

    const c = this.comandaformulario.value.alternateTodos[a].tamano.chica;

    if (c === true){
      this.chicaa.push(false);
      this.medianaa.push(true);
      this.grandee.push(true);
      this.tamano.push('chica');
     
    }
    if (c === false){

      this.chicaa = [];
      this.medianaa = [];
      this.grandee = [];

      this.tamano.splice(a, 1);

    }
}
comprobarMediana(a: number): any{

  const c = this.comandaformulario.value.alternateTodos[a].tamano.mediana;

  if (c === true){
      this.chicaa.push(true);
      this.medianaa.push(false);
      this.grandee.push(true);
      this.tamano.push('mediana');
     /*  console.log(this.medianaa);
      console.log(this.grandee); */
    }
  if (c === false ){

      this.chicaa = [];
      this.medianaa = [];
      this.grandee = [];
      this.tamano.splice(a, 1);
   /*    console.log(this.medianaa);
      console.log(this.grandee); */

    }

}
comprobarGrande(a: number): any{

  const c = this.comandaformulario.value.alternateTodos[a].tamano.grande;

  if (c === true){
      this.chicaa.push(true);
      this.medianaa.push(true);
      this.grandee.push(false);
      this.tamano.push('grande');
    /*   console.log(this.medianaa);
      console.log(this.grandee); */
    }
  if (c === false){

      this.chicaa = [];
      this.medianaa = [];
      this.grandee = [];
      this.tamano.splice(a, 1);

    /*   console.log(this.medianaa);
      console.log(this.grandee); */

    }
}

/* comprobacion proteina */
comprobarpollonatural(a: number): any{

  const c = this.comandaformulario.value.alternateTodos[a].proteina.pollonatural;

  if (c === true){

      this.pollonaturall.push(false);
      this.pollobbqq.push(true);
      this.jamonn.push(true);
      this.pollofritoo.push(true);
      this.polloespeciess.push(true);
      this.atunn.push(true);
      this.proteina.push('pollo natural');

    }
  if (c === false){

      this.pollonaturall = [];
      this.pollobbqq = [];
      this.jamonn = [];
      this.pollofritoo = [];
      this.polloespeciess = [];
      this.atunn = [];
      this.proteina.splice(a, 1);

    }

}
comprobarpollobbq(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].proteina.pollobbq;

  if (c === true){

      this.pollonaturall.push(true);
      this.pollobbqq.push(false);
      this.jamonn.push(true);
      this.pollofritoo.push(true);
      this.polloespeciess.push(true);
      this.atunn.push(true);
      this.proteina.push('pollo bbq');

    }
  if (c === false){

      this.pollonaturall = [];
      this.pollobbqq = [];
      this.jamonn = [];
      this.pollofritoo = [];
      this.polloespeciess = [];
      this.atunn = [];
      this.proteina.splice(a, 1);


    }

}
comprobarjamon(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].proteina.jamon;

  if (c === true){

      this.pollonaturall.push(true);
      this.pollobbqq.push(true);
      this.jamonn.push(false);
      this.pollofritoo.push(true);
      this.polloespeciess.push(true);
      this.atunn.push(true);
      this.proteina.push('jamon');

    }
  if (c === false){

      this.pollonaturall = [];
      this.pollobbqq = [];
      this.jamonn = [];
      this.pollofritoo = [];
      this.polloespeciess = [];
      this.atunn = [];
      this.proteina.splice(a, 1);

    }
}
comprobarpollofrito(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].proteina.pollofrito;

  if (c === true){

      this.pollonaturall.push(true);
      this.pollobbqq.push(true);
      this.jamonn.push(true);
      this.pollofritoo.push(false);
      this.polloespeciess.push(true);
      this.atunn.push(true);
      this.proteina.push('pollo frito');

    }
  if (c === false){

      this.pollonaturall = [];
      this.pollobbqq = [];
      this.jamonn = [];
      this.pollofritoo = [];
      this.polloespeciess = [];
      this.atunn = [];
      this.proteina.splice(a, 1);

    }
}
comprobarpolloespecies(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].proteina.polloespecies;

  if (c === true){

      this.pollonaturall.push(true);
      this.pollobbqq.push(true);
      this.jamonn.push(true);
      this.pollofritoo.push(true);
      this.polloespeciess.push(false);
      this.atunn.push(true);
      this.proteina.push('pollo especies');

    }
  if (c === false){

      this.pollonaturall = [];
      this.pollobbqq = [];
      this.jamonn = [];
      this.pollofritoo = [];
      this.polloespeciess = [];
      this.atunn = [];
      this.proteina.splice(a, 1);

    }
}
comprobaratunn(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].proteina.atun;

  if (c === true){

      this.pollonaturall.push(true);
      this.pollobbqq.push(true);
      this.jamonn.push(true);
      this.pollofritoo.push(true);
      this.polloespeciess.push(true);
      this.atunn.push(false);
      this.proteina.push('atun');

    }
  if (c === false){

      this.pollonaturall = [];
      this.pollobbqq = [];
      this.jamonn = [];
      this.pollofritoo = [];
      this.polloespeciess = [];
      this.atunn = [];
      this.proteina.splice(a, 1);

    }
}




/* Comprobar ingredientes */
comprobarzanahoria(a: number): any{

  const c = this.comandaformulario.value.alternateTodos[a].ingredientes.zanahoria;

  if (c === true){

    this.ingredientes.push('zanahoria');
   /*  console.log(this.ingredientes); */

  }
  if (c === false){

    const index = this.ingredientes.indexOf('zanahoria');
    this.ingredientes.splice(index, 1);
    /* console.log(index);
    console.log(this.ingredientes); */
  }

}
comprobartomate(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].ingredientes.tomate;

  if (c === true){

    this.ingredientes.push('tomate');
    /* console.log(this.ingredientes); */
  }
  if (c === false){
    const index = this.ingredientes.indexOf('tomate');
    this.ingredientes.splice(index, 1);
  }
}
comprobarbetabel(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].ingredientes.betabel;

  if (c === true){

    this.ingredientes.push('betabel');
    /* console.log(this.ingredientes); */
  }
  if (c === false){
    const index = this.ingredientes.indexOf('betabel');
    this.ingredientes.splice(index, 1);
  }
}
comprobarchampinon(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.champinon;

 if (c === true){

    this.ingredientes.push('champiñon');
    /* console.log(this.ingredientes); */
  }
 if (c === false){
  const index = this.ingredientes.indexOf('champiñon');
  this.ingredientes.splice(index, 1);
  }
}

comprobarjalapeno(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.jalapeno;

 if (c === true){

    this.ingredientes.push('jalapeño');
    /* console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('jalapeño');
  this.ingredientes.splice(index, 1);
  }
}

comprobarcebolla(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.cebolla;

 if (c === true){

    this.ingredientes.push('cebolla');
    /* console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('cebolla');
  this.ingredientes.splice(index, 1);
  }
}

comprobaraceituna(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.aceituna;

 if (c === true){

    this.ingredientes.push('aceituna');
    /* console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('aceituna');
  this.ingredientes.splice(index, 1);
  }
}

comprobarpina(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.pina;

 if (c === true){

    this.ingredientes.push('piña');
    /* console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('piña');
  this.ingredientes.splice(index, 1);
  }
}

comprobarmorron(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.morron;

 if (c === true){

    this.ingredientes.push('morron');
    /* console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('morron');
  this.ingredientes.splice(index, 1);
  }
}

comprobarpanela(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.panela;

 if (c === true){

    this.ingredientes.push('panela');
    /* console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('panela');
  this.ingredientes.splice(index, 1);
  }
}

comprobarpasta(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.pasta;

 if (c === true){

    this.ingredientes.push('pasta');
  /*   console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('pasta');
  this.ingredientes.splice(index, 1);
  }
}

comprobarapio(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.apio;

 if (c === true){

    this.ingredientes.push('apio');
   /*  console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('apio');
  this.ingredientes.splice(index, 1);
  }
}

comprobarcolmorado(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.colmorado;

 if (c === true){

    this.ingredientes.push('col morada');
   /*  console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('col morada');
  this.ingredientes.splice(index, 1);
  }
}

comprobarpepino(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.pepino;

 if (c === true){

    this.ingredientes.push('pepino');
   /*  console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('pepino');
  this.ingredientes.splice(index, 1);
  }
}

comprobarespinaca(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.espinaca;

 if (c === true){

    this.ingredientes.push('espinaca');
   /*  console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('espinaca');
  this.ingredientes.splice(index, 1);
  }
}

comprobargerminado(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.germinado;

 if (c === true){

    this.ingredientes.push('germinado');
   /*  console.log(this.ingredientes); */

  }
 if (c === false){
  const index = this.ingredientes.indexOf('germinado');
  this.ingredientes.splice(index, 1);
  }
}

comprobarelote(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.elote;

 if (c === true){

    this.ingredientes.push('elote');
    /* console.log(this.ingredientes);
    console.log(this.ingredientes); */
  }
 if (c === false){
  const index = this.ingredientes.indexOf('elote');
  this.ingredientes.splice(index, 1);
  }
}

comprobarbrocoli(a: number): any{
 const c = this.comandaformulario.value.alternateTodos[a].ingredientes.brocoli;

 if (c === true){

    this.ingredientes.push('brocoli');
   /*  console.log(this.ingredientes);
    console.log(this.ingredientes); */
  }
 if (c === false){
  const index = this.ingredientes.indexOf('brocoli');
  this.ingredientes.splice(index, 1);
  }
}


/* comprobar aderezos */
comprobarranch(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].aderezos.ranch;

  if (c === true){

      this.ranchh.push(false);
      this.millislass.push(true);
      this.cesarr.push(true);
      this.italianoo.push(true);
      this.mostazamiell.push(true);
      this.vinagretaa.push(true);
      this.chimchurryy.push(true);
      this.aderezos.push('ranch');
    }
  if (c === false){

      this.ranchh = [];
      this.millislass = [];
      this.cesarr = [];
      this.italianoo = [];
      this.mostazamiell = [];
      this.vinagretaa = [];
      this.chimchurryy = [];
      this.aderezos.splice(a, 1);
    }
}
comprobarmillislas(a: number): any{
  console.log('hola');
  const c = this.comandaformulario.value.alternateTodos[a].aderezos.milislas;

  if (c === true){

      this.ranchh.push(true);
      this.millislass.push(false);
      this.cesarr.push(true);
      this.italianoo.push(true);
      this.mostazamiell.push(true);
      this.vinagretaa.push(true);
      this.chimchurryy.push(true);
      this.aderezos.push('mil islas');
    }
  if (c === false){

      this.ranchh = [];
      this.millislass = [];
      this.cesarr = [];
      this.italianoo = [];
      this.mostazamiell = [];
      this.vinagretaa = [];
      this.chimchurryy = [];
      this.aderezos.splice(a, 1);
    }
}
comprobarcesar(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].aderezos.cesar;

  if (c === true){

      this.ranchh.push(true);
      this.millislass.push(true);
      this.cesarr.push(false);
      this.italianoo.push(true);
      this.mostazamiell.push(true);
      this.vinagretaa.push(true);
      this.chimchurryy.push(true);
      this.aderezos.push('cesar');

    }
  if (c === false){
      this.ranchh = [];
      this.millislass = [];
      this.cesarr = [];
      this.italianoo = [];
      this.mostazamiell = [];
      this.vinagretaa = [];
      this.chimchurryy = [];
      this.aderezos.splice(a, 1);
    }
}
comprobaritaliano(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].aderezos.italiano;

  if (c === true){

      this.ranchh.push(true);
      this.millislass.push(true);
      this.cesarr.push(true);
      this.italianoo.push(false);
      this.mostazamiell.push(true);
      this.vinagretaa.push(true);
      this.chimchurryy.push(true);
      this.aderezos.push('italiano');

    }
  if (c === false){

      this.ranchh = [];
      this.millislass = [];
      this.cesarr = [];
      this.italianoo = [];
      this.mostazamiell = [];
      this.vinagretaa = [];
      this.chimchurryy = [];
      this.aderezos.splice(a, 1);
      
    }
}
comprobarmostazamiel(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].aderezos.mostazamiel;

  if (c === true){

      this.ranchh.push(true);
      this.millislass.push(true);
      this.cesarr.push(true);
      this.italianoo.push(true);
      this.mostazamiell.push(false);
      this.vinagretaa.push(true);
      this.chimchurryy.push(true);
      this.aderezos.push('mostaza miel');

    }
  if (c === false){

      this.ranchh = [];
      this.millislass = [];
      this.cesarr = [];
      this.italianoo = [];
      this.mostazamiell = [];
      this.vinagretaa = [];
      this.chimchurryy = [];
      this.aderezos.splice(a, 1);
      
    }
}
comprobarvinagreta(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].aderezos.vinagreta;

  if (c === true){

      this.ranchh.push(true);
      this.millislass.push(true);
      this.cesarr.push(true);
      this.italianoo.push(true);
      this.mostazamiell.push(true);
      this.vinagretaa.push(false);
      this.chimchurryy.push(true);
      this.aderezos.push('vinagreta');


    }
  if (c === false){

      this.ranchh = [];
      this.millislass = [];
      this.cesarr = [];
      this.italianoo = [];
      this.mostazamiell = [];
      this.vinagretaa = [];
      this.chimchurryy = [];
      this.aderezos.splice(a, 1);
    }
}
comprobarchimchurry(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].aderezos.chimchurry;

  if (c === true){

      this.ranchh.push(true);
      this.millislass.push(true);
      this.cesarr.push(true);
      this.italianoo.push(true);
      this.mostazamiell.push(true);
      this.vinagretaa.push(true);
      this.chimchurryy.push(false);
      this.aderezos.push('chimichurry');
      
    }
  if (c === false){

      this.ranchh = [];
      this.millislass = [];
      this.cesarr = [];
      this.italianoo = [];
      this.mostazamiell = [];
      this.vinagretaa = [];
      this.chimchurryy = [];
      this.aderezos.splice(a, 1);
    }
}

/* comprobar complementos */

comprobarparmesano(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].complementos.parmesano;

  if (c === true){

      this.parmesanoo.push(false);
      this.crotoness.push(true);
      this.chilequebradoo.push(true);
      this.almendrass.push(true);
      this.aceitunass.push(true);
      this.complementos.push('parmesano');
     
    }
  if (c === false){

      this.parmesanoo = [];
      this.crotoness = [];
      this.chilequebradoo = [];
      this.almendrass = [];
      this.aceitunass = [];
      this.aderezos.splice(a, 1);
    }
}
comprobarcrotones(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].complementos.crotones;

  if (c === true){

      this.parmesanoo.push(true);
      this.crotoness.push(false);
      this.chilequebradoo.push(true);
      this.almendrass.push(true);
      this.aceitunass.push(true);
      this.complementos.push('crotones');

    }
  if (c === false){

      this.parmesanoo = [];
      this.crotoness = [];
      this.chilequebradoo = [];
      this.almendrass = [];
      this.aceitunass = [];
      this.complementos.splice(a, 1);
    }
}
comprobarchilequebrado(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].complementos.chilequebrado;

  if (c === true){

      this.parmesanoo.push(true);
      this.crotoness.push(true);
      this.chilequebradoo.push(false);
      this.almendrass.push(true);
      this.aceitunass.push(true);
      this.complementos.push('chile quebrado');
      
    }
  if (c === false){

      this.parmesanoo = [];
      this.crotoness = [];
      this.chilequebradoo = [];
      this.almendrass = [];
      this.aceitunass = [];
      this.complementos.splice(a, 1);

    }
  
}
comprobaralmendras(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].complementos.almendras;

  if (c === true){

      this.parmesanoo.push(true);
      this.crotoness.push(true);
      this.chilequebradoo.push(true);
      this.almendrass.push(false);
      this.aceitunass.push(true);
      this.complementos.push('alemdras');
      
    }
  if (c === false){

      this.parmesanoo = [];
      this.crotoness = [];
      this.chilequebradoo = [];
      this.almendrass = [];
      this.aceitunass = [];
      this.complementos.splice(a, 1);
    }
}
comprobaraceitunas(a: number): any{
  const c = this.comandaformulario.value.alternateTodos[a].complementos.aceitunas;

  if (c === true){

      this.parmesanoo.push(true);
      this.crotoness.push(true);
      this.chilequebradoo.push(true);
      this.almendrass.push(true);
      this.aceitunass.push(false);
      this.complementos.push('aceitunas');
    }
  if (c === false){

      this.parmesanoo = [];
      this.crotoness = [];
      this.chilequebradoo = [];
      this.almendrass = [];
      this.aceitunass = [];
      this.complementos.splice(a, 1);
    }
 
}

matchTamano(group: AbstractControl): { [key: string ]: any } | null {

  const chicaControl = group.get('chica');
  const medianaControl = group.get('mediana');
  const grandeControl = group.get('grande');

  if (chicaControl?.value === true) {
   return null;
  }else if (medianaControl?.value === true) {
   return null;
  }else if (grandeControl?.value === true) {
   return null;
  }else{
    return{' tamano ': true};
  }
}
matchProteinas(group: AbstractControl): { [key: string ]: any } | null {

  const pollonaturalControl = group.get('pollonatural');
  const pollobbqControl = group.get('pollobbq');
  const jamonControl = group.get('jamon');
  const pollofritoControl = group.get('pollofrito');
  const polloespeciesControl = group.get('polloespecies');
  const atunControl = group.get('atun');

  if (pollonaturalControl?.value === true) {
   return null;
  }else if (pollobbqControl?.value === true) {
   return null;
  }else if (jamonControl?.value === true) {
   return null;
  }else if (pollofritoControl?.value === true) {
    return null;
  }else if (polloespeciesControl?.value === true) {
    return null;
  }else if (atunControl?.value === true) {
    return null;
  }else{
    return{' proteina ': true};
  }
}
matchAderezos(group: AbstractControl): { [key: string ]: any } | null {

  const ranchControl = group.get('ranch');
  const millislasControl = group.get('millislas');
  const cesarControl = group.get('cesar');
  const italianoControl = group.get('italiano');
  const mostazamielControl = group.get('mostazamiel');
  const vinagretaControl = group.get('vinagreta');
  const chimchurryControl = group.get('chimchurry');

  if (ranchControl?.value === true) {
   return null;
  }else if (millislasControl?.value === true) {
   return null;
  }else if (cesarControl?.value === true) {
   return null;
  }else if (italianoControl?.value === true) {
    return null;
  }else if (mostazamielControl?.value === true) {
    return null;
  }else if (vinagretaControl?.value === true) {
    return null;
  }else if (chimchurryControl?.value === true) {
    return null;
  }else{
    return{' aderezo ': true};
  }
}
matchComplementos(group: AbstractControl): { [key: string ]: any } | null {

  const parmesanoControl = group.get('parmesano');
  const chilequebradoControl = group.get('chilequebrado');
  const crotonesControl = group.get('crotones');
  const almendrasControl = group.get('almendras');
  const aceitunasControl = group.get('aceitunas');
 
  if (parmesanoControl?.value === true) {
   return null;
  }else if (chilequebradoControl?.value === true) {
   return null;
  }else if (crotonesControl?.value === true) {
   return null;
  }else if (almendrasControl?.value === true) {
    return null;
  }else if (aceitunasControl?.value === true) {
    return null;
  }else{
    return{' complemento ': true};
  }
}
matchExtra(group: AbstractControl): { [key: string ]: any } | null {

  const extporcionControl = group.get('extporcion');
  const extaderezoControl = group.get('extaderezo');
  const extcomplementoControl = group.get('extcomplemento');

  if (extporcionControl?.value === true) {
   return null;
  }else if (extaderezoControl?.value === true) {
   return null;
  }else if (extcomplementoControl?.value === true) {
   return null;
  }else{
    return{' extra ': true};
  }
}
}
