import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-jugonaranja',
  templateUrl: './dialog-jugonaranja.component.html',
  styleUrls: ['./dialog-jugonaranja.component.css']
})
export class DialogJugonaranjaComponent implements OnInit {

  jugoNaranjaForm:FormGroup;
  constructor(public fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogJugonaranjaComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) { 
      this.jugoNaranjaForm = this.fb.group({
        tamano: new FormControl('', Validators.required)
      });
    }

  ngOnInit(): void {
    this.jugoNaranjaForm = this.fb.group({
      tamano: new FormControl('', Validators.required)
    });
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.jugoNaranjaForm.value.tamano === '' ){
      console.log('no hay datos: secundario');
    }

    if (this.jugoNaranjaForm.value){
      this.dialogRef.close(this.jugoNaranjaForm.value);
    }

  }

}
