import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';


@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {


  TodosExtra = [

    /* proteina */
    {value: '', viewValue: '*Selecciona*', precio: 0},
    {value: 'pollonatural', viewValue: 'pollo natural', precio: 20},
    {value: 'pollobbq', viewValue: 'pollo BBQ', precio: 20},
    {value: 'jamon', viewValue: 'Jamon', precio: 20},
    {value: 'pollofrito', viewValue: 'Pollo frito', precio: 20},
    {value: 'polloespecies', viewValue: 'Pollo especies', precio: 20},
    {value: 'atun', viewValue: 'Atun', precio: 20},

    /* ingredientes */
    {value: 'zanahoria', viewValue: '*zanahoria*', precio: 10},
    {value: 'tomate', viewValue: 'tomate', precio: 10},
    {value: 'betabel', viewValue: 'betabel', precio: 10},
    {value: 'champinon', viewValue: 'champinon', precio: 10},
    {value: 'jalapeno', viewValue: 'jalapeno', precio: 10},
    {value: 'cebolla', viewValue: 'cebolla', precio: 10},
    {value: 'aceituna', viewValue: 'aceituna', precio: 10},
    {value: 'pina', viewValue: 'pina', precio: 10},
    {value: 'morron', viewValue: 'morron', precio: 10},
    {value: 'panela', viewValue: 'panela', precio: 10},
    {value: 'pasta', viewValue: 'pasta', precio: 10},
    {value: 'apio', viewValue: 'apio', precio: 10},
    {value: 'colmorado', viewValue: 'colmorado', precio: 10},
    {value: 'pepino', viewValue: 'pepino', precio: 10},
    {value: 'espinaca', viewValue: 'espinaca', precio: 10},
    {value: 'germinado', viewValue: 'germinado', precio: 10},
    {value: 'elote', viewValue: 'elote', precio: 10},
    {value: 'brocoli', viewValue: 'brocoli', precio: 10},

    /* Aderezos */
    {value: 'ranch', viewValue: 'Ranch', precio: 10},
    {value: 'millislas', viewValue: 'Millislas', precio: 10},
    {value: 'cesar', viewValue: 'Cesar', precio: 10},
    {value: 'italiano', viewValue: 'Italiano', precio: 10},
    {value: 'mostazamiel', viewValue: 'Mostazamiel', precio: 10},
    {value: 'vinagreta', viewValue: 'Vinagreta', precio: 10},
    {value: 'chimichurry', viewValue: 'Chimichurry', precio: 10},
    /* Complementos */
    {value: 'parmesano', viewValue: 'Parmesano', precio: 10},
    {value: 'chilequebrado', viewValue: 'Chile quebrado', precio: 10},
    {value: 'crotones', viewValue: 'Crotones', precio: 10},
    {value: 'almendras', viewValue: 'Almendras', precio: 10},
    {value: 'aceitunas', viewValue: 'Aceitunas', precio: 10},

  ];

  comandaform: FormGroup;
/* tamaño */
  chicaa: any;
  medianaa: any;
  grandee: any;
/* proteina */
  pollonaturall: any;
  pollobbqq: any;
  jamonn: any;
  pollofritoo: any;
  polloespeciess: any;
  atunn: any;
/* aderezos */
  ranchh: any;
  millislass: any;
  cesarr: any;
  italianoo: any;
  mostazamiell: any;
  vinagretaa: any;
  chimichurry: any;
/* complementos */
  parmesanoo: any;
  crotoness: any;
  chilequebradoo: any;
  almendrass: any;
  aceitunass: any;

 /* Extras */
  extramostrar: any;
  extporcionn: any;
  extaderezoo: any;
  extcomplementoo: any;
  mostrarDireccionView: any;
/* definidos */
  tamano: any;
  proteina: any;
  ingredientes: any;
  aderezos: any;
  complementos: any;
  extras: any;
/* mensajes */
  mensaje: string;
  mensajeCliente: any;
  mensajeTamano: any;
  mensajeProteina: any;
  mensajeIngredientes: any;
  mensajeAderezos: any;
  mensajeComplementos: any;
  mensajeExtras: any;
  mensajeEspecificaciones: any;
  mensajeFinal: any;
  mensajepagocliente: any;
  mensajedireccion: any;
  precio =  0;
  precioExtra = 0;
  preciofinal = 0;
  arrayExtras = [] as any;
  todos: any;
  linkCompleto: string;

  ingredientess: Array<any> = [
    'Zanahoria',
    'Tomate',
    'Betabel',
    'Champinon',
    'Jalapeno',
    'Cebolla',
    'Aceituna',
    'Piña',
    'Morron',
    'Panela',
    'Pasta',
    'Apio',
    'Colmorado',
    'Pepino',
    'Espinaca',
    'Germinado',
    'Elote',
    'Brocoli'
  ];

  selectedingredientes = [] as any;

  get alternateExtras(){

    return this.comandaform.get('alternateExtras')as FormArray;

  }

  constructor(public fb: FormBuilder) {



    this.mensaje = '';
    this.linkCompleto = '';
    this.comandaform = this.fb.group({

      nombre: ['', Validators.required],
      telefono: ['', Validators.required],
      aceptarcondiciones: ['', Validators.required],
      direccion: [''/* , Validators.required */],
      especificaciones: [''],
      pagocliente: [''],
      mostrarDireccion: [''],
      ingredientesAll: [''],
      ingreditenesFor: this.addingredientes(),

 /* tamaños */
      tamano : this.fb.group({
        chica: new FormControl(),
        mediana:  new FormControl(),
        grande: new FormControl(),
      }, { validators: this.matchTamano }),
/* proteina  matchProteinas*/
     proteina: this.fb.group({
      pollonatural: new FormControl(),
      pollobbq: new FormControl(),
      jamon: new FormControl(),
      pollofrito: new FormControl(),
      polloespecies: new FormControl(),
      atun: new FormControl()
     }, { validators: this.matchProteinas }),

/* ingredientes   */
    ingredientes : this.fb.group({
      zanahoria: new FormControl(/*  {disable:true} */ ),
      tomate: new FormControl(),
      betabel: new FormControl(),
      champinon: new FormControl(),
      jalapeno: new FormControl(),
      cebolla: new FormControl(),
      aceituna: new FormControl(),
      pina: new FormControl(),
      morron: new FormControl(),
      panela: new FormControl(),
      pasta: new FormControl(),
      apio: new FormControl(),
      colmorado: new FormControl(),
      pepino: new FormControl(),
      espinaca: new FormControl(),
      germinado: new FormControl(),
      elote: new FormControl(),
      brocoli: new FormControl(),
    }),
/* proteina */
    aderezos: this.fb.group({
      ranch: new FormControl(),
      millislas: new FormControl(),
      cesar: new FormControl(),
      italiano: new FormControl(),
      mostazamiel: new FormControl(),
      vinagreta: new FormControl(),
      chimchurry: new FormControl()
     }, { validators: this.matchAderezos }),
/* complementos */
     complementos: this.fb.group({
      parmesano: new FormControl(),
      chilequebrado: new FormControl(),
      crotones: new FormControl(),
      almendras: new FormControl(),
      aceitunas: new FormControl()
     }, { validators: this.matchComplementos }),

     /* extras: this.fb.group({
      extporcion: new FormControl(),
      extaderezo: new FormControl(),
      extcomplemento: new FormControl(),
      extracomida: new FormControl()
     }), */

      alternateExtras: this.fb.array([])

    });

  }


  ngOnInit(): void {
    this.preciofinal = 0;
  }

  ver(): any{
    /* console.log(this.comandaform.controls); */
  }
  addingredientes(): any{
    const arr = this.ingredientess.map( element => {
      return this.fb.control(false);
    });
    return this.fb.array(arr);
  }

  marcarIngredientesAll(): any{

    const c = this.comandaform.value.ingredientesAll;
    if ( c === true){
       this.todos = true;
       this.ingredientesArray.controls.forEach((control: any, i: any) => {

        control.setValue(true);
        if (control.value){
          this.selectedingredientes.push(this.ingredientess[i]);
        }
        });
       /* console.log(this.comandaform.get('ingreditenesFor')); */
    }else{

      this.todos = false;
      this.ingredientesArray.controls.forEach((control: any, i: any) => {

        control.setValue(false);

        });

    }

  }

getselectedingredientes(): any{
    this.selectedingredientes = [] as any ;
    this.ingredientesArray.controls.forEach((control: any, i: any) => {
      if (control.value){
        this.selectedingredientes.push(this.ingredientess[i]);
      }
    });

   /*  console.log(this.selectedingredientes.join(',')); */
  }

  /* FORMULARIO DE EXTRA */

  addExtra(): any{

    this.alternateExtras.push(this.fb.control(''));
  }

  tomarprecio(precio:number){

  /*   console.log(precio); */

    this.precioExtra =  precio;


    this.preciofinal = this.preciofinal + this.precioExtra;

    }

  eliminarExtra(i: number ) {

    let precioExtra = this.alternateExtras.value[0].precio ;


    this.preciofinal = this.preciofinal - precioExtra;

    this.alternateExtras.removeAt(i);

  }

/*   mostrarExtras(): any{
    const c = this.comandaform.value.extramostrar;

    console.log(c);
    if (c === true){
      this.extramostrar = true;
      this.precioExtra = 0;
      this.preciofinal = this.precio + this.precioExtra;
    }else{

      this.extramostrar = false;
      this.extporcionn = false;
      this.extaderezoo = false;
      this.comandaform.patchValue(  { extras: { extporcion: false }} );
      this.comandaform.patchValue(  { extras: { extaderezo: false }} );
      this.comandaform.patchValue(  { extras: { extcomplemento: false }} );
      this.precioExtra = 0;
      this.extcomplementoo = false;
      this.preciofinal = this.precio + this.precioExtra;
    }

  } */


  mostrardireccion(){
    const c = this.comandaform.value.mostrarDireccion;
/*     console.log(c); */

    if (c === true){
      this.mostrarDireccionView = true;

    }else{
      this.mostrarDireccionView = false;
      this.comandaform.controls.direccion.setValue('');

    }
  }

  get ingredientesArray(): any{
    return  this.comandaform.get('ingreditenesFor') as FormArray;
  }
/* Boton para enviar  */
  guardar(algo: any): any{


    const nombre = algo.value.nombre;
    const telefono = algo.value.telefono;
    const especificaciones = algo.value.especificaciones;
    const direccion = algo.value.direccion;
    const minumero = 529811036172;
    const numeroFresh = 529811028203;
    const coma = ',';
    const espacio = '%20';


  /* Creacion de mensajes por parte */
    this.mensajeCliente =  nombre + coma + espacio + telefono + coma;
    this.mensajeTamano = espacio + 'Ensalada:' + espacio + this.tamano + coma;
    this.mensajeProteina = espacio + this.proteina + coma + espacio;
    this.mensajeIngredientes = this.selectedingredientes.join(', ');
    this.mensajeAderezos = espacio + coma + this.aderezos;
    this.mensajeComplementos = espacio + coma +  this.complementos;
    this.mensajepagocliente = algo.value.pagocliente;


    for (let i = 0; i < algo.value.alternateExtras.length; i++) {
      const element: string = algo.value.alternateExtras[i].value;

      this.arrayExtras.push(element);
      /* console.log(element);
      console.log(this.arrayExtras); */
      this.extras = this.arrayExtras.join(', ');
    }

    if (this.extras){
      this.mensajeExtras = espacio + 'Extra: ' + this.extras;
    }else{
      this.mensajeExtras = '';
    }
    if (especificaciones){

      this.mensajeEspecificaciones = espacio + 'Nota: ' + especificaciones;
    }else{
      this.mensajeEspecificaciones = '';
    }
    if (direccion){

      this.mensajedireccion = espacio + 'SE ENVIARÁ A: ' + direccion;
    }else{
      this.mensajedireccion = '';
    }

    if (this.mensajepagocliente){

      this.mensajepagocliente = espacio + 'Se pagara con: ' + this.mensajepagocliente;
    }else{
      this.mensajepagocliente = '';
    }




    // tslint:disable-next-line: max-line-length
    this.mensajeFinal =
                     this.mensajeCliente +

                     this.mensajeTamano +
                     this.mensajeProteina +
                     this.mensajeIngredientes +
                     this.mensajeAderezos +
                     this.mensajeComplementos +
                     this.mensajeExtras +
                     
                     this.mensajeEspecificaciones +
                     this.mensajedireccion +
                     this.mensajepagocliente;


    this.linkCompleto =  'https://api.whatsapp.com/send?phone=+' + numeroFresh + '&text=' + this.mensajeFinal;

    /* window.location.href = this.linkCompleto; */

    console.log(this.comandaform);
    console.log(this.mensaje);
    console.log(this.mensajeFinal);


  }


  /* Comprobacion de  tamaño */
  comprobarChica(): any{
    const c = this.comandaform.value.tamano.chica;
    if (c === true){
     /*  console.log('se ha seleccionado chica'); */
      this.comandaform.patchValue(  { tamano: { mediana: null }} );
      this.comandaform.patchValue(  { tamano: { grande: null }} );
      this.chicaa = false;
      this.medianaa = true;
      this.grandee = true;
      this.tamano = 'chica';
      this.precio = 80;
      this.preciofinal = this.precio + this.precioExtra;
    }else{
      this.chicaa = false;
      this.medianaa = false;
      this.grandee = false;
      this.tamano = '';
      this.precio = 0;
      this.preciofinal = this.precio + this.precioExtra;
    }

  }
  comprobarMediana(): any{
    const m = this.comandaform.value.tamano.mediana;
    if (m === true){
     /*  console.log('se ha seleccionado mediana'); */
      this.comandaform.patchValue(  { tamano: { chica: null }} );
      this.comandaform.patchValue(  { tamano: { grande: null }} );
      this.chicaa = true;
      this.medianaa = false;
      this.grandee = true;
      this.tamano = 'mediana';
      this.precio = 90;
      this.preciofinal = this.precio + this.precioExtra;
    }else{
      this.chicaa = false;
      this.medianaa = false;
      this.grandee = false;
      this.precio = 0;
      this.preciofinal = this.precio + this.precioExtra;
    }
  }
  comprobarGrande(): any{
    const g = this.comandaform.value.tamano.grande;
    if (g === true){
    /*   console.log('se ha seleccionado grande'); */
      this.comandaform.patchValue(  { tamano: { chica: null }} );
      this.comandaform.patchValue(  { tamano: { mediana: null }} );
      this.chicaa = true;
      this.medianaa = true;
      this.grandee = false;
      this.tamano = 'grande';
      this.precio = 100;
      this.preciofinal = this.precio + this.precioExtra;
    }else{
      this.chicaa = false;
      this.medianaa = false;
      this.grandee = false;
      this.precio = 0;
      this.preciofinal = this.precio + this.precioExtra;

    }
  }

  /* Comprobacion proteina */
  comprobarpollonatural(): any{
    const c = this.comandaform.value.proteina.pollonatural;

    if (c === true){
     /*  console.log('se ha seleccionado pollo natural'); */
      /* Asignacion de valores */
      this.comandaform.patchValue(  { proteina: { pollobbq: null }} );
      this.comandaform.patchValue(  { proteina: { jamon: null }} );
      this.comandaform.patchValue(  { proteina: { pollofrito: null }} );
      this.comandaform.patchValue(  { proteina: { polloespecies: null }} );
      this.comandaform.patchValue(  { proteina: { atun: null }} );
      /* Asignacion de disable */
      this.pollonaturall = false;
      this.pollobbqq = true;
      this.jamonn = true;
      this.pollofritoo = true;
      this.polloespeciess = true;
      this.atunn = true;
      this.proteina = 'Pollo natural';
    }else{
      this.pollonaturall = false;
      this.pollobbqq = false;
      this.jamonn = false;
      this.pollofritoo = false;
      this.polloespeciess = false;
      this.atunn = false;
    }
  }
  comprobarpollobbq(): any{
    let c = this.comandaform.value.proteina.pollobbq;

    if (c === true){
      /* console.log('se ha seleccionado pollo BBQ'); */
      /* Asignacion de valores */
      this.comandaform.patchValue(  { proteina: { pollonatural: null }} );
      this.comandaform.patchValue(  { proteina: { jamon: null }} );
      this.comandaform.patchValue(  { proteina: { pollofrito: null }} );
      this.comandaform.patchValue(  { proteina: { polloespecies: null }} );
      this.comandaform.patchValue(  { proteina: { atun: null }} );
      /* Asignacion de disable */
      this.pollonaturall = true;
      this.pollobbqq = false;
      this.jamonn = true;
      this.pollofritoo = true;
      this.polloespeciess = true;
      this.atunn = true;
      this.proteina = 'Pollo BBQ';
    }else{
      this.pollonaturall = false;
      this.pollobbqq = false;
      this.jamonn = false;
      this.pollofritoo = false;
      this.polloespeciess = false;
      this.atunn = false;
    }

  }
  comprobarjamon(): any{
    let c = this.comandaform.value.proteina.jamon;

    if (c === true){
      /* console.log('se ha seleccionado jamon'); */
      /* Asignacion de valores */
      this.comandaform.patchValue(  { proteina: { pollobbq: null }} );
      this.comandaform.patchValue(  { proteina: { pollonatural: null }} );
      this.comandaform.patchValue(  { proteina: { pollofrito: null }} );
      this.comandaform.patchValue(  { proteina: { polloespecies: null }} );
      this.comandaform.patchValue(  { proteina: { atun: null }} );
      /* Asignacion de disable */
      this.pollonaturall = true;
      this.pollobbqq = true;
      this.jamonn = false;
      this.pollofritoo = true;
      this.polloespeciess = true;
      this.atunn = true;
      this.proteina = 'Jamon';
    }else{
      this.pollonaturall = false;
      this.pollobbqq = false;
      this.jamonn = false;
      this.pollofritoo = false;
      this.polloespeciess = false;
      this.atunn = false;
    }

  }
  comprobarpollofrito(): any{
    let c = this.comandaform.value.proteina.pollofrito;

    if (c === true){
     /*  console.log('se ha seleccionado pollo frito'); */
      /* Asignacion de valores */
      this.comandaform.patchValue(  { proteina: { pollobbq: null }} );
      this.comandaform.patchValue(  { proteina: { pollonatural: null }} );
      this.comandaform.patchValue(  { proteina: { jamon: null }} );
      this.comandaform.patchValue(  { proteina: { polloespecies: null }} );
      this.comandaform.patchValue(  { proteina: { atun: null }} );
      /* Asignacion de disable */
      this.pollonaturall = true;
      this.pollobbqq = true;
      this.jamonn = true;
      this.pollofritoo = false;
      this.polloespeciess = true;
      this.atunn = true;
      this.proteina = 'Pollo Frito';
    }else{
      this.pollonaturall = false;
      this.pollobbqq = false;
      this.jamonn = false;
      this.pollofritoo = false;
      this.polloespeciess = false;
      this.atunn = false;
    }
  }
  comprobarpolloespecies(): any{
    let c = this.comandaform.value.proteina.polloespecies;

    if (c === true){
    /*   console.log('se ha seleccionado pollo especies'); */
      /* Asignacion de valores */
      this.comandaform.patchValue(  { proteina: { pollobbq: null }} );
      this.comandaform.patchValue(  { proteina: { pollonatural: null }} );
      this.comandaform.patchValue(  { proteina: { jamon: null }} );
      this.comandaform.patchValue(  { proteina: { pollofrito: null }} );
      this.comandaform.patchValue(  { proteina: { atun: null }} );

      /* Asignacion de disable */
      this.pollonaturall = true;
      this.pollobbqq = true;
      this.jamonn = true;
      this.pollofritoo = true;
      this.polloespeciess = false;
      this.atunn = true;
      this.proteina = 'Pollo Especies';
    }else{
      this.pollonaturall = false;
      this.pollobbqq = false;
      this.jamonn = false;
      this.pollofritoo = false;
      this.polloespeciess = false;
      this.atunn = false;
    }
  }
  comprobaratunn(): any{
    let c = this.comandaform.value.proteina.atun;

    if (c === true){
      console.log('se ha seleccionado atun');
      /* Asignacion de valores */
      this.comandaform.patchValue(  { proteina: { pollobbq: null }} );
      this.comandaform.patchValue(  { proteina: { pollonatural: null }} );
      this.comandaform.patchValue(  { proteina: { jamon: null }} );
      this.comandaform.patchValue(  { proteina: { pollofrito: null }} );
      this.comandaform.patchValue(  { proteina: { polloespecies: null }} );


      /* Asignacion de disable */
      this.pollonaturall = true;
      this.pollobbqq = true;
      this.jamonn = true;
      this.pollofritoo = true;
      this.polloespeciess = true;
      this.atunn = false;
      this.proteina = 'Pollo atun';
    }else{
      this.pollonaturall = false;
      this.pollobbqq = false;
      this.jamonn = false;
      this.pollofritoo = false;
      this.polloespeciess = false;
      this.atunn = false;
    }
  }


  /* Comprobacion Aderezos */
  comprobarranch(): any{
    const c = this.comandaform.value.aderezos.ranch;

    if (c === true){
    /*   console.log('se ha seleccionado Ranch'); */
      /* Asignacion de valores */

      this.comandaform.patchValue(  { aderezos: { millislas: null }} );
      this.comandaform.patchValue(  { aderezos: { cesar: null }} );
      this.comandaform.patchValue(  { aderezos: { italiano: null }} );
      this.comandaform.patchValue(  { aderezos: { mostazamiel: null }} );
      this.comandaform.patchValue(  { aderezos: { vinagreta: null }} );
      this.comandaform.patchValue(  { aderezos: { chimchurry: null }} );
      /* Asignacion de disable */
      this.ranchh = false;
      this.millislass = true;
      this.cesarr = true;
      this.italianoo = true;
      this.mostazamiell = true;
      this.vinagretaa = true;
      this.chimichurry = true;

      this.aderezos = 'Ranch';
    }else{
      this.ranchh = false;
      this.millislass = false;
      this.cesarr = false;
      this.italianoo = false;
      this.mostazamiell = false;
      this.vinagretaa = false;
      this.chimichurry = false;
    }

  }
  comprobarmillislas(): any{
    const c = this.comandaform.value.aderezos.millislas;

    if (c === true){
     /*  console.log('se ha seleccionado mil islas'); */
      /* Asignacion de valores */

      this.comandaform.patchValue(  { proteina: { ranch: null }} );
      this.comandaform.patchValue(  { aderezos: { cesar: null }} );
      this.comandaform.patchValue(  { aderezos: { italiano: null }} );
      this.comandaform.patchValue(  { aderezos: { mostazamiel: null }} );
      this.comandaform.patchValue(  { aderezos: { vinagreta: null }} );
      this.comandaform.patchValue(  { aderezos: { chimchurry: null }} );
      /* Asignacion de disable */
      this.ranchh = true;
      this.millislass = false;
      this.cesarr = true;
      this.italianoo = true;
      this.mostazamiell = true;
      this.vinagretaa = true;
      this.chimichurry = true;

      this.aderezos = 'Mil islas';
    }else{
      this.ranchh = false;
      this.millislass = false;
      this.cesarr = false;
      this.italianoo = false;
      this.mostazamiell = false;
      this.vinagretaa = false;
      this.chimichurry = false;
    }

  }
  comprobarcesar(): any{
    const c = this.comandaform.value.aderezos.cesar;

    if (c === true){
     /*  console.log('se ha seleccionado Cesar'); */
      /* Asignacion de valores */

      this.comandaform.patchValue(  { proteina: { ranch: null }} );
      this.comandaform.patchValue(  { aderezos: { millislas: null }} );
      this.comandaform.patchValue(  { aderezos: { italiano: null }} );
      this.comandaform.patchValue(  { aderezos: { mostazamiel: null }} );
      this.comandaform.patchValue(  { aderezos: { vinagreta: null }} );
      this.comandaform.patchValue(  { aderezos: { chimchurry: null }} );
      /* Asignacion de disable */
      this.ranchh = true;
      this.millislass = true;
      this.cesarr = false;
      this.italianoo = true;
      this.mostazamiell = true;
      this.vinagretaa = true;
      this.chimichurry = true;

      this.aderezos = 'Cesar';
    }else{
      this.ranchh = false;
      this.millislass = false;
      this.cesarr = false;
      this.italianoo = false;
      this.mostazamiell = false;
      this.vinagretaa = false;
      this.chimichurry = false;
    }

  }
  comprobaritaliano(): any{
    const c = this.comandaform.value.aderezos.italiano;

    if (c === true){
     /*  console.log('se ha seleccionado italiano'); */
      /* Asignacion de valores */

      this.comandaform.patchValue(  { proteina: { ranch: null }} );
      this.comandaform.patchValue(  { aderezos: { millislas: null }} );
      this.comandaform.patchValue(  { aderezos: { cesar: null }} );
      this.comandaform.patchValue(  { aderezos: { mostazamiel: null }} );
      this.comandaform.patchValue(  { aderezos: { vinagreta: null }} );
      this.comandaform.patchValue(  { aderezos: { chimchurry: null }} );
      /* Asignacion de disable */
      this.ranchh = true;
      this.millislass = true;
      this.cesarr = true;
      this.italianoo = false;
      this.mostazamiell = true;
      this.vinagretaa = true;
      this.chimichurry = true;

      this.aderezos = 'Italiano';
    }else{
      this.ranchh = false;
      this.millislass = false;
      this.cesarr = false;
      this.italianoo = false;
      this.mostazamiell = false;
      this.vinagretaa = false;
      this.chimichurry = false;
    }
  }
  comprobarmostazamiel(): any{
    const c = this.comandaform.value.aderezos.mostazamiel;

    if (c === true){
     /*  console.log('se ha seleccionado mostazamiel'); */
      /* Asignacion de valores */

      this.comandaform.patchValue(  { proteina: { ranch: null }} );
      this.comandaform.patchValue(  { aderezos: { millislas: null }} );
      this.comandaform.patchValue(  { aderezos: { cesar: null }} );
      this.comandaform.patchValue(  { aderezos: { italiano: null }} );
      this.comandaform.patchValue(  { aderezos: { vinagreta: null }} );
      this.comandaform.patchValue(  { aderezos: { chimchurry: null }} );
      /* Asignacion de disable */
      this.ranchh = true;
      this.millislass = true;
      this.cesarr = true;
      this.italianoo = true;
      this.mostazamiell = false;
      this.vinagretaa = true;
      this.chimichurry = true;

      this.aderezos = 'Mostazamiel';
    }else{
      this.ranchh = false;
      this.millislass = false;
      this.cesarr = false;
      this.italianoo = false;
      this.mostazamiell = false;
      this.vinagretaa = false;
      this.chimichurry = false;
    }

  }
  comprobarvinagreta(): any{
    const c = this.comandaform.value.aderezos.vinagreta;

    if (c === true){
     /*  console.log('se ha seleccionado vinagreta'); */
      /* Asignacion de valores */

      this.comandaform.patchValue(  { proteina: { ranch: null }} );
      this.comandaform.patchValue(  { aderezos: { millislas: null }} );
      this.comandaform.patchValue(  { aderezos: { cesar: null }} );
      this.comandaform.patchValue(  { aderezos: { italiano: null }} );
      this.comandaform.patchValue(  { aderezos: { mostazamiel: null }} );
      this.comandaform.patchValue(  { aderezos: { chimchurry: null }} );
      /* Asignacion de disable */
      this.ranchh = true;
      this.millislass = true;
      this.cesarr = true;
      this.italianoo = true;
      this.mostazamiell = true;
      this.vinagretaa = false;
      this.chimichurry = true;

      this.aderezos = 'Vinagreta';
    }else{
      this.ranchh = false;
      this.millislass = false;
      this.cesarr = false;
      this.italianoo = false;
      this.mostazamiell = false;
      this.vinagretaa = false;
      this.chimichurry = false;
    }

  }
  comprobarchimchurry(): any{
    const c = this.comandaform.value.aderezos.chimchurry;

    if (c === true){
     /*  console.log('se ha seleccionado chimchurry'); */
      /* Asignacion de valores */

      this.comandaform.patchValue(  { proteina: { ranch: null }} );
      this.comandaform.patchValue(  { proteina: { millislas: null }} );
      this.comandaform.patchValue(  { proteina: { cesar: null }} );
      this.comandaform.patchValue(  { proteina: { italiano: null }} );
      this.comandaform.patchValue(  { proteina: { mostazamiel: null }} );
      this.comandaform.patchValue(  { proteina: { vinagreta: null }} );
     
      /* Asignacion de disable */
      this.ranchh = true;
      this.millislass = true;
      this.cesarr = true;
      this.italianoo = true;
      this.mostazamiell = true;
      this.vinagretaa = true;
      this.chimichurry = false;

      this.aderezos = 'Chimchurry';
    }else{
      this.ranchh = false;
      this.millislass = false;
      this.cesarr = false;
      this.italianoo = false;
      this.mostazamiell = false;
      this.vinagretaa = false;
      this.chimichurry = false;
    }
  }


/* Comprobacion Complementos */
  comprobarparmesano(): any{
    const c = this.comandaform.value.complementos.parmesano;

    if (c === true){
     /*  console.log('se ha seleccionado Parmesano'); */
      /* Asignacion de valores */
      this.comandaform.patchValue(  { complementos: { chilequebrado: null }} );
      this.comandaform.patchValue(  { complementos: { crotones: null }} );
      this.comandaform.patchValue(  { complementos: { almendras: null }} );
      this.comandaform.patchValue(  { complementos: { aceitunas: null }} );

      /* Asignacion de disable */
      this.parmesanoo = false;
      this.crotoness = true;
      this.chilequebradoo = true;
      this.almendrass = true;
      this.aceitunass = true;

      this.complementos = 'Parmensano';
    }else{
      this.parmesanoo = false;
      this.crotoness = false;
      this.chilequebradoo = false;
      this.almendrass = false;
      this.aceitunass = false;

    }

  }
  comprobarcrotones(): any{
    const c = this.comandaform.value.complementos.crotones;

    if (c === true){
      /* console.log('se ha seleccionado Crotones'); */
      /* Asignacion de valores */
      this.comandaform.patchValue(  { complementos: { chilequebrado: null }} );
      this.comandaform.patchValue(  { complementos: { parmesano: null }} );
      this.comandaform.patchValue(  { complementos: { almendras: null }} );
      this.comandaform.patchValue(  { complementos: { aceitunas: null }} );

      /* Asignacion de disable */
      this.parmesanoo = true;
      this.crotoness = false;
      this.chilequebradoo = true;
      this.almendrass = true;
      this.aceitunass = true;

      this.complementos = 'Crotones';
    }else{
      this.parmesanoo = false;
      this.crotoness = false;
      this.chilequebradoo = false;
      this.almendrass = false;
      this.aceitunass = false;

    }
  }
  comprobarchilequebrado(): any{
    const c = this.comandaform.value.complementos.chilequebrado;

    if (c === true){
     /*  console.log('se ha seleccionado Crotones'); */
      /* Asignacion de valores */
      this.comandaform.patchValue(  { complementos: { crotones: null }} );
      this.comandaform.patchValue(  { complementos: { parmesano: null }} );
      this.comandaform.patchValue(  { complementos: { almendras: null }} );
      this.comandaform.patchValue(  { complementos: { aceitunas: null }} );

      /* Asignacion de disable */
      this.parmesanoo = true;
      this.crotoness = true;
      this.chilequebradoo = false;
      this.almendrass = true;
      this.aceitunass = true;

      this.complementos = 'Chile quebrado';
    }else{
      this.parmesanoo = false;
      this.crotoness = false;
      this.chilequebradoo = false;
      this.almendrass = false;
      this.aceitunass = false;

    }
  }
  comprobaralmendras(): any{
    const c = this.comandaform.value.complementos.almendras;

    if (c === true){
    /*   console.log('se ha seleccionado almendras'); */
      /* Asignacion de valores */
      this.comandaform.patchValue(  { complementos: { crotones: null }} );
      this.comandaform.patchValue(  { complementos: { parmesano: null }} );
      this.comandaform.patchValue(  { complementos: { chilequebrado: null }} );
      this.comandaform.patchValue(  { complementos: { aceitunas: null }} );

      /* Asignacion de disable */
      this.parmesanoo = true;
      this.crotoness = true;
      this.chilequebradoo = true;
      this.almendrass = false;
      this.aceitunass = true;

      this.complementos = 'almendras';
    }else{
      this.parmesanoo = false;
      this.crotoness = false;
      this.chilequebradoo = false;
      this.almendrass = false;
      this.aceitunass = false;

    }
  }
  comprobaraceitunas(): any{
    const c = this.comandaform.value.complementos.aceitunas;

    if (c === true){
      /* console.log('se ha seleccionado aceitunas'); */
      /* Asignacion de valores */
      this.comandaform.patchValue(  { complementos: { crotones: null }} );
      this.comandaform.patchValue(  { complementos: { parmesano: null }} );
      this.comandaform.patchValue(  { complementos: { chilequebrado: null }} );
      this.comandaform.patchValue(  { complementos: { almendras: null }} );

      /* Asignacion de disable */
      this.parmesanoo = true;
      this.crotoness = true;
      this.chilequebradoo = true;
      this.almendrass = true;
      this.aceitunass = false;

      this.complementos = 'Aceitunas';
    }else{
      this.parmesanoo = false;
      this.crotoness = false;
      this.chilequebradoo = false;
      this.almendrass = false;
      this.aceitunass = false;

    }
  }


/* Comprobacion de extras */
comprobarextporcion(): any{
  const c = this.comandaform.value.extras.extporcion;

  if (c === true){
   /*  console.log('se ha seleccionado extra de porcion'); */
    /* Asignacion de valores */
    this.comandaform.patchValue(  { extras: { extaderezo: null }} );
    this.comandaform.patchValue(  { extras: { extcomplemento: null }} );


    /* Asignacion de disable */
    this.extporcionn = false;
    this.extaderezoo = true;
    this.extcomplementoo = true;

    this.extras = 'proteina';
    this.precioExtra = 20;
    this.preciofinal = this.precio + this.precioExtra;
  }else{
    this.extporcionn = false;
    this.extaderezoo = false;
    this.extcomplementoo = false;
    this.precioExtra = 0;
    this.preciofinal = this.precio + this.precioExtra;


  }
}
comprobarextaderezo(): any{
  const c = this.comandaform.value.extras.extaderezo;

  if (c === true){
   /*  console.log('se ha seleccionado extra de aderezo'); */
    /* Asignacion de valores */
    this.comandaform.patchValue(  { extras: { extporcion: null }} );
    this.comandaform.patchValue(  { extras: { extcomplemento: null }} );


    /* Asignacion de disable */
    this.extporcionn = true;
    this.extaderezoo = false;
    this.extcomplementoo = true;

    this.extras = 'aderezo';
    this.precioExtra = 10;
    this.preciofinal = this.precio + this.precioExtra;
    /* console.log(this.preciofinal); */
  }else{
    this.extporcionn = false;
    this.extaderezoo = false;
    this.extcomplementoo = false;
    this.precioExtra = 0;
    this.preciofinal = this.precio + this.precioExtra;

  }

}
comprobarxtcomplemento(): any{
  const c = this.comandaform.value.extras.extcomplemento;

  if (c === true){
   /*  console.log('se ha seleccionado extra de complemento'); */
    /* Asignacion de valores */
    this.comandaform.patchValue(  { extras: { extporcion: null }} );
    this.comandaform.patchValue(  { extras: { extaderezo: null }} );


    /* Asignacion de disable */
    this.extporcionn = true;
    this.extaderezoo = true;
    this.extcomplementoo = false;

    this.extras = 'complemento';
    this.precioExtra = 10;

    this.preciofinal = this.precio + this.precioExtra;
  }else{
    this.extporcionn = false;
    this.extaderezoo = false;
    this.extcomplementoo = false;
    this.precioExtra = 0;
    this.preciofinal = this.precio + this.precioExtra;

  }

}


/* Filtros para que sea requerido */
 matchTamano(group: AbstractControl): { [key: string ]: any } | null {

   const chicaControl = group.get('chica');
   const medianaControl = group.get('mediana');
   const grandeControl = group.get('grande');

   if (chicaControl?.value === true) {
    return null;
   }else if (medianaControl?.value === true) {
    return null;
   }else if (grandeControl?.value === true) {
    return null;
   }else{
     return{' tamano ': true};
   }
}
matchProteinas(group: AbstractControl): { [key: string ]: any } | null {

  const pollonaturalControl = group.get('pollonatural');
  const pollobbqControl = group.get('pollobbq');
  const jamonControl = group.get('jamon');
  const pollofritoControl = group.get('pollofrito');
  const polloespeciesControl = group.get('polloespecies');
  const atunControl = group.get('atun');

  if (pollonaturalControl?.value === true) {
   return null;
  }else if (pollobbqControl?.value === true) {
   return null;
  }else if (jamonControl?.value === true) {
   return null;
  }else if (pollofritoControl?.value === true) {
    return null;
  }else if (polloespeciesControl?.value === true) {
    return null;
  }else if (atunControl?.value === true) {
    return null;
  }else{
    return{' proteina ': true};
  }
}

matchAderezos(group: AbstractControl): { [key: string ]: any } | null {

  const ranchControl = group.get('ranch');
  const millislasControl = group.get('millislas');
  const cesarControl = group.get('cesar');
  const italianoControl = group.get('italiano');
  const mostazamielControl = group.get('mostazamiel');
  const vinagretaControl = group.get('vinagreta');
  const chimchurryControl = group.get('chimchurry');

  if (ranchControl?.value === true) {
   return null;
  }else if (millislasControl?.value === true) {
   return null;
  }else if (cesarControl?.value === true) {
   return null;
  }else if (italianoControl?.value === true) {
    return null;
  }else if (mostazamielControl?.value === true) {
    return null;
  }else if (vinagretaControl?.value === true) {
    return null;
  }else if (chimchurryControl?.value === true) {
    return null;
  }else{
    return{' aderezo ': true};
  }
}

matchComplementos(group: AbstractControl): { [key: string ]: any } | null {

  const parmesanoControl = group.get('parmesano');
  const chilequebradoControl = group.get('chilequebrado');
  const crotonesControl = group.get('crotones');
  const almendrasControl = group.get('almendras');
  const aceitunasControl = group.get('aceitunas');
 
  if (parmesanoControl?.value === true) {
   return null;
  }else if (chilequebradoControl?.value === true) {
   return null;
  }else if (crotonesControl?.value === true) {
   return null;
  }else if (almendrasControl?.value === true) {
    return null;
  }else if (aceitunasControl?.value === true) {
    return null;
  }else{
    return{' complemento ': true};
  }
}

matchExtra(group: AbstractControl): { [key: string ]: any } | null {

  const extporcionControl = group.get('extporcion');
  const extaderezoControl = group.get('extaderezo');
  const extcomplementoControl = group.get('extcomplemento');

  if (extporcionControl?.value === true) {
   return null;
  }else if (extaderezoControl?.value === true) {
   return null;
  }else if (extcomplementoControl?.value === true) {
   return null;
  }else{
    return{' extra ': true};
  }
}

}
