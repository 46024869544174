import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Map }  from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import mapboxgl from 'mapbox-gl';
interface mensajeFirebase {
  nombre: string,
  numero: number,
  mensaje: string,
  fechaEnvio: any,
  articulosNombre: any,
  articulosPrecio: any,
  orden: string,
  tipo: number,
  total: number,
}
@Component({
  selector: 'app-dialog-carrito',
  templateUrl: './dialog-carrito.component.html',
  styleUrls: ['./dialog-carrito.component.css']
})


export class DialogCarritoComponent implements OnInit, AfterViewInit {

  precio: number;
  mostrarComplementos: boolean;
  comandaformulario: FormGroup;
  mostrarDireccionView = false;
  textoEditadoArray = [] as any;
  linkCompleto: string;
  /* var para crear mensaje final  */
  mensajeCliente: any;
  mensajedireccion: any;
  mensajeEspecificaciones: any;
  mensajepagocliente: any;
  mensajeopcionEnvio: any;
  ultimoMensaje: any;
  prueba: any;
  mapa:any;
  latitud:number;
  longitud:number;
  @ViewChild('mapDiv') 
  mapDivElement!:ElementRef


  constructor(
    public fb: FormBuilder,
    public firestore: AngularFirestore,
    private dialogRef: MatDialogRef<DialogCarritoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {


    this.linkCompleto = '';
    this.latitud = 0;
    this.longitud = 0;
    this.precio = this.data.Precio.reduce((a: number, b: number) => a + b, 0);
    this.mostrarComplementos = false;

    this.comandaformulario = this.fb.group({

      nombre: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z ñÑáÁéÉíÍóÓúÚ]*$'),
        Validators.maxLength(20)
      ]],
      telefono: ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(10)
      ]],
      /* CONDICIONES  */
      /* aceptarcondiciones: ['', Validators.required], */
      direccion: ['', [
        Validators.pattern('[-_a-zA-Z0-9 ,.#ñÑáÁéÉíÍóÓúÚ]*'),
        Validators.maxLength(100)
      ]],
      especificaciones: ['', [
        Validators.pattern('[-_a-zA-Z0-9 ,.#ñÑáÁéÉíÍóÓúÚ]*'),
        Validators.maxLength(100)
      ]],
      pagocliente: [0, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(7)

      ]],
      opcionEnvio: ['', Validators.required],
      mensaje: [''],
      precioVenta: ['']

    });

  }

  ngAfterViewInit(): void {

  
    this.obtenerGeolocalizacion();
   /*  this.mapaFuncion(); */
  this.mapaFuncion();
  
  }
  ngOnInit(): void {
    
    this.comandaformulario = this.fb.group({

      nombre: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z ñÑáÁéÉíÍóÓúÚ]*$'),
        Validators.maxLength(20)
      ]],
      telefono: ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(10)
      ]],
      /* aceptarcondiciones: ['', Validators.required], */
      direccion: ['', [
        Validators.pattern('[-_a-zA-Z0-9 ,.#ñÑáÁéÉíÍóÓúÚ]*'),
        Validators.maxLength(100)
      ]],
      especificaciones: ['', [
        Validators.pattern('[-_a-zA-Z0-9 ,.#ñÑáÁéÉíÍóÓúÚ]*'),
        Validators.maxLength(100)
      ]],
      pagocliente: [0, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(7)
      ]],
      opcionEnvio: ['', Validators.required],
      mensaje: [''],
      precioVenta: ['']

    });
    var nombreLocalStoage: any = localStorage.getItem('LocalNombreProducto');
    var precioLocalStoage: any = localStorage.getItem('LocalPrecioProducto');

    nombreLocalStoage = nombreLocalStoage ? nombreLocalStoage.split(',,') : [];

    precioLocalStoage = precioLocalStoage ? precioLocalStoage.split(',') : [];



  }
  mapaFuncion(){
    navigator.geolocation.getCurrentPosition((pos)=>{
      console.log(pos)
      this.latitud = pos.coords.latitude;
      this.longitud = pos.coords.longitude;
      this.mapa = new Map({
        container: this.mapDivElement.nativeElement,
        style: 'mapbox://styles/mapbox/streets-v11',
        center:[this.longitud, this.latitud],
        zoom: 13.8
      });
      this.crearMarcador(this.longitud, this.latitud);
    })

  }
  crearMarcador(lng:number,lat:number){

    const marker = new mapboxgl.Marker({
      draggable: true
      })
      .setLngLat([lng, lat])
      .addTo(this.mapa);

      marker.on('dragend', ()=>{
        console.log(marker.getLngLat());
        this.latitud = marker.getLngLat().lat;
        this.longitud = marker.getLngLat().lng;
      });
  }

  obtenerGeolocalizacion(){
    
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((pos)=>{
        console.log(pos)
        this.latitud = pos.coords.latitude;
        this.longitud = pos.coords.longitude;
      })
    }else{
      alert('Navegador no soportea la Geolocalización');
      console.log('Navegador no soportea la Geolocalización')
    }
  }
  

  guardar(i: any): any {


    const nombre = i.value.nombre;
    const telefono = i.value.telefono;
    const especificaciones = i.value.especificaciones;
    let direccion = i.value.direccion;
    const pagocliente = i.value.pagocliente;
    const numeroFresh = 529812072331;
    let opcionEnvio: number = i.value.opcionEnvio;
    let cambio: number = 0;
    const numeroSahid = 529811028203;
    const coma = ',';
    const espacio = '%20';
    /* console.log(opcionEnvio); */

    const articulosNombre = this.data.Texto;
    const articulosPrecios = this.data.Precio;
    const total = this.data.Precio.reduce((a: any, b: any) => a + b, 0);
    const mensajeFinal = this.data.Texto.reduce((a: any, b: any) => a + b);

    this.mensajeCliente = '*' + nombre + '*' + coma + espacio + telefono + coma;

    for (let a = 0; a < this.data.Texto.length; a++) {
      /* Se agrega %20 a cada espacio que existe */
      const texto = this.data.Texto[a].replace(/\s+/g, espacio);
      /* se agrega los valores editados a un nuevo array */
      this.textoEditadoArray.push(texto);
    }

    /* console.log(mensajeFinal); */
    const textoFinal = this.textoEditadoArray.join(',%20');

    /* console.log(textoFinal); */
    this.comandaformulario.controls.precioVenta.setValue(total);
    this.comandaformulario.controls.mensaje.setValue(textoFinal);

    /*  EL MENSAJE FINAL */
    if (especificaciones) {
      this.mensajeEspecificaciones = espacio + 'Nota:' + especificaciones.replace(/\s+/g, espacio);
    } else {
      this.mensajeEspecificaciones = '';
    }
    if (direccion || (this.latitud !=0 && this.longitud !=0)) {

      console.log(direccion);
      this.mensajedireccion = espacio + '*' + 'Envio:' + '*' + direccion.replace(/\s+/g, espacio);
      direccion = espacio + '*' + 'Envio:' + '*' + direccion.replace(/\s+/g, espacio);
      direccion = direccion.replace('#', 'No');
      if(navigator.geolocation){
 
        direccion = direccion + espacio + "https://maps.google.com/?q="+ this.latitud + "," +this.longitud + "&entry=gps" 

      }
    } else {
      direccion = '';
      this.mensajedireccion = '';
    }
    if (pagocliente > 0) {

      cambio = (pagocliente - total);

      this.mensajepagocliente = '*' + 'Pago' + '*' + ':' + espacio + '$' + pagocliente + espacio + 'el cambio es :$' + cambio;
    } else {
      this.mensajepagocliente = '';
    }
    if (opcionEnvio == 1) {
      this.mensajeopcionEnvio = '*' + 'Comer' + espacio + 'aquí' + '*' + espacio;
    }
    if (opcionEnvio == 2) {
      this.mensajeopcionEnvio = '*' + 'Pasar' + espacio + 'a' + espacio + 'buscar' + '*' + espacio;
    }
    if (opcionEnvio == 3) {
      this.mensajeopcionEnvio = '*' + 'Llevar' + '*' + espacio;
    }

    console.log(this.mensajedireccion);
    this.ultimoMensaje =
      this.mensajeCliente +
      textoFinal +
      this.mensajeEspecificaciones +
      this.mensajeopcionEnvio +
      '*El total :$ ' + total + '*' + espacio +
      this.mensajepagocliente +
      direccion;
    console.log(this.ultimoMensaje);

    const mensajeGuardar: mensajeFirebase = {
      nombre: nombre,
      numero: telefono,
      mensaje: this.ultimoMensaje,
      fechaEnvio: new Date(),
      articulosNombre: articulosNombre,
      articulosPrecio: articulosPrecios,
      total: total,
      tipo: opcionEnvio,
      orden: this.mensajeopcionEnvio

    }
    console.log(mensajeGuardar);

    setTimeout(() => {
      this.firestore.collection('Mensajes').doc(this.prueba).set({ mensajeGuardar });
    }, 500);
    setTimeout(() => {
      this.linkCompleto = 'https://api.whatsapp.com/send?phone=+' + numeroFresh + '&text=' + this.ultimoMensaje;
      window.location.href = this.linkCompleto;
    }, 1000);
    /* Borrar los datos de local storage */
    localStorage.removeItem('LocalNombreProducto');
    localStorage.removeItem('LocalPrecioProducto');
    setTimeout(this.reload, 5000);

  }
  reload() {
    window.location.reload();
  }
  mostrardireccion(): any {
    this.mapaFuncion()
    let c = this.comandaformulario.value.opcionEnvio;

    /* console.log(c); 
    */
    if (c == 3) {
      this.mostrarDireccionView = true;
    } else {
      this.mostrarDireccionView = false;
      this.comandaformulario.controls['direccion'].setValue('');
    }
  }

  delete(indice: number): any {
    console.log(indice);
    this.data.Precio.splice(indice, 1);
    this.data.Texto.splice(indice, 1);
    this.precio = this.data.Precio.reduce((a: number, b: number) => a + b, 0);



    var nombreLocalStoage: any = localStorage.getItem('LocalNombreProducto');
    var precioLocalStoage: any = localStorage.getItem('LocalPrecioProducto');

    nombreLocalStoage = nombreLocalStoage ? nombreLocalStoage.split(',,') : [];

    precioLocalStoage = precioLocalStoage ? precioLocalStoage.split(',') : [];


    
     /*  console.log(nombreLocalStoage)
      console.log(precioLocalStoage) */

      /* eliminar del carrito esa comida dependiento el numero que da el indice comienza por 0  */
      nombreLocalStoage.splice(indice, 1);
      precioLocalStoage.splice(indice, 1);

      var nuevotexto = nombreLocalStoage.join(',,');
      /* console.log(nuevotexto) */

      /* Hacer que guarde de nuevo el array que queda y agregar la separacion de doble coma ,, */

      localStorage.setItem('LocalNombreProducto', nuevotexto.toString());
      localStorage.setItem('LocalPrecioProducto', precioLocalStoage.toString());
    
  }
  clickOK(): any {
    this.mostrarComplementos = true;

  }
  guardarMensajeFirebase(Mensaje: mensajeFirebase) {
    this.firestore.collection('Mensajes').doc(this.prueba).set({ Mensaje });

  }

}
