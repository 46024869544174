<h2 mat-dialog-title>Huevo</h2>

<mat-dialog-content class="pt-3" [formGroup]="huevoForm">
    <mat-radio-group (change)="comprobarBagVegetaria($event)" formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="estrellado">Estrellado</mat-radio-button>
        <mat-radio-button value="a la mexicana">A la Mexicana</mat-radio-button>
        <mat-radio-button value="motulenos">Motuleños</mat-radio-button>
        <mat-radio-button value="divorciados">Divorciados</mat-radio-button>
        <mat-radio-button value="rancheros">Rancheros</mat-radio-button>
        <mat-radio-button value="revueltos">Revueltos</mat-radio-button>
        <mat-radio-button value="jamon">Jamón</mat-radio-button>
        <mat-radio-button value="chorizo">Chorizo</mat-radio-button>
        <mat-radio-button value="manchego">Queso Manchego</mat-radio-button>
    </mat-radio-group>
    <div *ngIf="toggleVeg == true">
        <h4>Selecciona tus 4 ingredientes</h4>

        <div class="ingredientes" *ngFor="let control of ingredientesArray.controls; let i = index;">

            <!-- AQUI HAY QUE HACE RQUE SE DEJEN DE MARCAR CUANDO YA HAY MAS DE 4  -->
            <mat-checkbox (change)="getselectedingredientes()" [formControl]="control" type="checkbox">{{ingredientess[i]}}</mat-checkbox>
        </div>
        <div *ngIf="selectedingredientes.length > 1">
            <small class="text-center ">{{selectedingredientes}}</small>
        </div>
        <div *ngIf="selectedingredientes.length >= 4">
            <small class="text-center text-danger">Solo se permite 4 ingredientes</small>
        </div>
        <br>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="huevoForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>