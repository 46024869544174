<h2 mat-dialog-title>Aguas de fruta combinadas</h2>

<mat-dialog-content class="pt-3" [formGroup]="aguafrutasCombinadaForm">
    <h3>Tamaño</h3>
    <mat-radio-group formControlName="tamano" aria-label="Select an option">
        <mat-radio-button value="copa">Copa</mat-radio-button>
        <mat-radio-button value="jarra">Jarra</mat-radio-button>
    </mat-radio-group>
    <br><br>
    <h3>Combinaciones</h3>
    <mat-radio-group formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="Piña/Apio">Piña/Apio</mat-radio-button>
        <mat-radio-button value="Piña/Pepino">Piña/Pepino</mat-radio-button>
        <mat-radio-button value="Pepino/Limón">Pepino/Limón</mat-radio-button>
        <mat-radio-button value="Pepino/Chaya">Pepino/Chaya</mat-radio-button>
        <mat-radio-button value="Pepinpo/Perejil">Pepinpo/Perejil</mat-radio-button>

    </mat-radio-group>

</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="aguafrutasCombinadaForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>