import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-ensalada',
  templateUrl: './dialog-ensalada.component.html',
  styleUrls: ['./dialog-ensalada.component.css']
})
export class DialogEnsaladaComponent implements OnInit {
  comandaform: FormGroup;
  TodosExtra = [

    /* proteina */
    {value: 'pollonatural', viewValue: 'pollo natural', precio: 40},
    {value: 'pollobbq', viewValue: 'pollo BBQ', precio: 40},
    {value: 'jamon', viewValue: 'Jamon', precio: 30},
    {value: 'pollofrito', viewValue: 'Pollo frito', precio: 40},
    {value: 'polloespecies', viewValue: 'Pollo especies', precio: 40},
    {value: 'atun', viewValue: 'Atun', precio: 35},
    {value: 'huevo', viewValue: 'Huevo', precio: 10},

    /* ingredientes */
    {value: 'zanahoria', viewValue: '*zanahoria*', precio: 10},
    {value: 'tomate', viewValue: 'tomate', precio: 10},
    {value: 'betabel', viewValue: 'betabel', precio: 10},
    {value: 'champinon', viewValue: 'champinon', precio: 10},
    {value: 'jalapeno', viewValue: 'jalapeno', precio: 10},
    {value: 'cebolla', viewValue: 'cebolla', precio: 10},
    {value: 'aceituna', viewValue: 'aceituna', precio: 10},
    {value: 'pina', viewValue: 'pina', precio: 10},
    {value: 'morron', viewValue: 'morron', precio: 10},
    {value: 'panela', viewValue: 'panela', precio: 10},
    {value: 'pasta', viewValue: 'pasta', precio: 10},
    {value: 'apio', viewValue: 'apio', precio: 10},
    {value: 'colmorado', viewValue: 'colmorado', precio: 10},
    {value: 'pepino', viewValue: 'pepino', precio: 10},
    {value: 'espinaca', viewValue: 'espinaca', precio: 10},
    {value: 'germinado', viewValue: 'germinado', precio: 10},
    {value: 'elote', viewValue: 'elote', precio: 10},
    {value: 'brocoli', viewValue: 'brocoli', precio: 10},

    /* Aderezos */
    {value: 'ranch', viewValue: 'Ranch', precio: 10},
    {value: 'millislas', viewValue: 'Millislas', precio: 10},
    {value: 'cesar', viewValue: 'Cesar', precio: 10},
    {value: 'italiano', viewValue: 'Italiano', precio: 10},
    {value: 'mostazamiel', viewValue: 'Mostazamiel', precio: 10},
    {value: 'vinagreta', viewValue: 'Vinagreta', precio: 10},
    {value: 'chimchurry', viewValue: 'Chimchurry', precio: 10},
    /* Complementos */
    {value: 'parmesano', viewValue: 'Parmesano', precio: 10},
    {value: 'chilequebrado', viewValue: 'Chile quebrado', precio: 10},
    {value: 'crotones', viewValue: 'Crotones', precio: 10},
    {value: 'almendras', viewValue: 'Almendras', precio: 10},
    {value: 'aceitunas', viewValue: 'Aceitunas', precio: 10},


  ];
  TodosExtra01 = [

    /* proteina */
    {
      nombre: 'Proteina',
      extra: [
        {value: 'pollonatural', viewValue: 'pollo natural', precio: 40},
        {value: 'pollobbq', viewValue: 'pollo BBQ', precio: 40},
        {value: 'jamon', viewValue: 'Jamon', precio: 30},
        {value: 'pollofrito', viewValue: 'Pollo frito', precio: 40},
        {value: 'polloespecies', viewValue: 'Pollo especies', precio: 40},
        {value: 'atun', viewValue: 'Atun', precio: 35},
        {value: 'huevo', viewValue: 'Huevo', precio: 10}
      ]
    },

    /* ingredientes */
    {
      nombre: 'Ingredientes',
      extra: [
        {value: 'zanahoria', viewValue: '*zanahoria*', precio: 10},
        {value: 'tomate', viewValue: 'tomate', precio: 10},
        {value: 'betabel', viewValue: 'betabel', precio: 10},
        {value: 'champiñon', viewValue: 'champiñon', precio: 10},
        {value: 'jalapeno', viewValue: 'jalapeno', precio: 10},
        {value: 'cebolla', viewValue: 'cebolla', precio: 10},
        {value: 'aceituna', viewValue: 'aceituna', precio: 10},
        {value: 'pina', viewValue: 'piña', precio: 10},
        {value: 'morron', viewValue: 'morron', precio: 10},
        {value: 'panela', viewValue: 'panela', precio: 10},
        {value: 'pasta', viewValue: 'pasta', precio: 10},
        {value: 'apio', viewValue: 'apio', precio: 10},
        {value: 'colmorado', viewValue: 'colmorado', precio: 10},
        {value: 'pepino', viewValue: 'pepino', precio: 10},
        {value: 'espinaca', viewValue: 'espinaca', precio: 10},
        {value: 'germinado', viewValue: 'germinado', precio: 10},
        {value: 'elote', viewValue: 'elote', precio: 10},
        {value: 'brocoli', viewValue: 'brocoli', precio: 10}
      ]
    },
    /* Aderezos */
    {
      nombre: 'Aderezos',
      extra: [
        {value: 'ranch', viewValue: 'Ranch', precio: 10},
        {value: 'millislas', viewValue: 'Millislas', precio: 10},
        {value: 'cesar', viewValue: 'Cesar', precio: 10},
        {value: 'italiano', viewValue: 'Italiano', precio: 10},
        {value: 'mostazamiel', viewValue: 'Mostazamiel', precio: 10},
        {value: 'vinagreta', viewValue: 'Vinagreta', precio: 10},
        {value: 'chimchurry', viewValue: 'Chimchurry', precio: 10}
      ]
    },
    /* Complementos */
    {
      nombre: 'Complementos',
      extra: [
        {value: 'parmesano', viewValue: 'Parmesano', precio: 10},
        {value: 'chilequebrado', viewValue: 'Chile quebrado', precio: 10},
        {value: 'crotones', viewValue: 'Crotones', precio: 10},
        {value: 'almendras', viewValue: 'Almendras', precio: 10},
        {value: 'aceitunas', viewValue: 'Aceitunas', precio: 10}
      ]
    },

  ];
  ingredientess: Array<any> = [
    'Zanahoria',
    'Tomate',
    'Betabel',
    'Champinon',
    'Jalapeno',
    'Cebolla',
    'Aceituna',
    'Piña',
    'Morron',
    'Panela',
    'Pasta',
    'Apio',
    'Colmorado',
    'Pepino',
    'Espinaca',
    'Germinado',
    'Elote',
    'Brocoli'
  ];
  todos: any;
  selectedingredientes = [] as any;

  get ingredientesArray(): any{

    return this.comandaform.get('ingredientes')as FormArray;

  }
  constructor(public fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogEnsaladaComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {

    this.comandaform = this.fb.group({
      tamano: new FormControl('', Validators.required),
      proteina: new FormControl('', Validators.required),
      aderezos: new FormControl('', Validators.required),
      complementos: new FormControl('', Validators.required),
      ingredientes : this.addingredientes(),
      seleccionados: new FormControl('', Validators.required),
      extras: new FormControl(),
    });
   }

  ngOnInit(): void {
  }

  getselectedingredientes(): any{
    this.selectedingredientes = [] as any ;
    this.ingredientesArray.controls.forEach((control: any, i: any) => {
      if (control.value){
        this.selectedingredientes.push(this.ingredientess[i]);
      }
    });
    this.comandaform.controls['seleccionados'].setValue(this.selectedingredientes.join(', '));

   /*  console.log(this.selectedingredientes.join(',')); */
  }
  addingredientes(): any{
    const arr = this.ingredientess.map( element => {
      return this.fb.control(false);
    });
    return this.fb.array(arr);
  }

  save(): any{
    if (
        this.comandaform.value.tamano === '' &&
        this.comandaform.value.proteina === '' &&
        this.comandaform.value.aderezos === '' &&
        this.comandaform.value.ingredientes === '' &&
        this.comandaform.value.seleccionados === '' &&
        this.comandaform.value.complementos === ''){
    }
    if (this.comandaform.value){
      this.dialogRef.close(this.comandaform.value);
    }
  }

}
