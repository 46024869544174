import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-paquete-desayuno',
  templateUrl: './dialog-paquete-desayuno.component.html',
  styleUrls: ['./dialog-paquete-desayuno.component.css']
})
export class DialogPaqueteDesayunoComponent implements OnInit {

  paquetedesayunoForm:FormGroup;
  constructor(public fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogPaqueteDesayunoComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.paquetedesayunoForm = this.fb.group({
        desayuno: new FormControl('', Validators.required),
        bebida: new FormControl('', Validators.required)
      });
     }

  ngOnInit(): void {
    this.paquetedesayunoForm = this.fb.group({
      desayuno: new FormControl('', Validators.required),
      bebida: new FormControl('', Validators.required)
    });
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.paquetedesayunoForm.value.desayuno === '' && this.paquetedesayunoForm.value.bebida === ''){
      console.log('no hay datos: secundario');
    }

    if (this.paquetedesayunoForm.value){
      this.dialogRef.close(this.paquetedesayunoForm.value);
    }

  }

}
