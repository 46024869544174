<h2 mat-dialog-title>Aguas de fruta</h2>

<mat-dialog-content class="pt-3 " [formGroup]="aguafrutasTemporadaForm">
    <h3>Tamaño</h3>
    <mat-radio-group class="tamano" formControlName="tamano" aria-label="Select an option">
        <mat-radio-button value="copa">Copa</mat-radio-button>
        <mat-radio-button value="jarra">Jarra</mat-radio-button>
    </mat-radio-group>

    <br><br>
    <h3>Frutas</h3>
    <mat-radio-group formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="fresa">Fresa</mat-radio-button>
        <mat-radio-button value="guayaba">Guayaba</mat-radio-button>
        <mat-radio-button value="pitaya">Pitaya</mat-radio-button>
        <mat-radio-button value="marañon">Marañon</mat-radio-button>
        <mat-radio-button value="nance">Nance</mat-radio-button>
        <mat-radio-button value="mango">Mango</mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="aguafrutasTemporadaForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>