import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-ord',
  templateUrl: './dialog-ord.component.html',
  styleUrls: ['./dialog-ord.component.css']
})
export class DialogOrdComponent implements OnInit {
  description = 'Orden de pollo';
  ordenDePolloForm: FormGroup;
  constructor(public fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogOrdComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {

    this.ordenDePolloForm = this.fb.group({
      proteina: new FormControl('', Validators.required),
      aderezo: new FormControl('' , Validators.required ),
    });

  }

  ngOnInit(): void {
    this.ordenDePolloForm = this.fb.group({
      proteina: new FormControl('', Validators.required),
      aderezo: new FormControl('', Validators.required),
    });

  }
  close(){
    this.dialogRef.close();
  }

  save(){

    if (this.ordenDePolloForm.value.aderezo === '' && this.ordenDePolloForm.value.proteina === ''){
      console.log('no hay datos: secundario');
    }
    if (this.ordenDePolloForm.value) {
      this.dialogRef.close(this.ordenDePolloForm.value);
    }

  }

}
