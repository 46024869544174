<h2 mat-dialog-title>Pasta</h2>

<mat-dialog-content class="pt-3" [formGroup]="pastaForm">
    <h3>Tipo de Pasta</h3>
    <mat-radio-group formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="Bolognesa">Bolognesa</mat-radio-button>
        <mat-radio-button value="Teriyaki">Teriyaki</mat-radio-button>
        <mat-radio-button value="3 Quesos">3 Quesos</mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="pastaForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>