import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-agua-frutascombinada',
  templateUrl: './dialog-agua-frutascombinada.component.html',
  styleUrls: ['./dialog-agua-frutascombinada.component.css']
})
export class DialogAguaFrutascombinadaComponent implements OnInit {

  aguafrutasCombinadaForm:FormGroup;
  constructor(public fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogAguaFrutascombinadaComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.aguafrutasCombinadaForm = this.fb.group({
        tamano: new FormControl('', Validators.required),
        tipo: new FormControl('', Validators.required)
      });
     }

  ngOnInit(): void {
    this.aguafrutasCombinadaForm = this.fb.group({
      tamano: new FormControl('', Validators.required),
      tipo: new FormControl('', Validators.required)
    });
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.aguafrutasCombinadaForm.value.tipo === '' && this.aguafrutasCombinadaForm.value.tamano === '' ){
      console.log('no hay datos: secundario');
    }

    if (this.aguafrutasCombinadaForm.value){
      this.dialogRef.close(this.aguafrutasCombinadaForm.value);
    }

  }

}
