import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-agua-frutas',
  templateUrl: './dialog-agua-frutas.component.html',
  styleUrls: ['./dialog-agua-frutas.component.css']
})
export class DialogAguaFrutasComponent implements OnInit {

  aguafrutasForm:FormGroup;
  constructor(public fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogAguaFrutasComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {


      this.aguafrutasForm = this.fb.group({
        tamano: new FormControl('', Validators.required),
        tipo: new FormControl('', Validators.required)
      });

  }

  ngOnInit(): void {
    this.aguafrutasForm = this.fb.group({
      tamano: new FormControl('', Validators.required),
      tipo: new FormControl('', Validators.required)
    });
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.aguafrutasForm.value.tipo === '' && this.aguafrutasForm.value.tamano === ''  ){
      console.log('no hay datos: secundario');
    }

    if (this.aguafrutasForm.value){
      this.dialogRef.close(this.aguafrutasForm.value);
    }

  }

}
