import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-tenders',
  templateUrl: './dialog-tenders.component.html',
  styleUrls: ['./dialog-tenders.component.css']
})
export class DialogTendersComponent implements OnInit {

  tendersForm: FormGroup;

  constructor(public fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogTendersComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) { 

    this.tendersForm = this.fb.group({
      aderezos: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.tendersForm = this.fb.group({
      aderezos: new FormControl('', Validators.required)
    });
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.tendersForm.value.aderezos === '' ){
      console.log('no hay datos: secundario');
    }

    if (this.tendersForm.value){
      this.dialogRef.close(this.tendersForm.value);
    }

  }

}
