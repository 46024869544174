<h2 mat-dialog-title>Omelet</h2>

<mat-dialog-content class="pt-3" [formGroup]="omeletForm">
    <h3>Selecciona tus ingredientes</h3>
    <div *ngIf="selectedingredientes.length >= 4">
        <p class="text-center text-danger">Solo se permite 4 ingredientes</p>
    </div>
    <div class="opciones" *ngIf="selectedingredientes.length >= 0">
        <p class="text-center ">{{selectedingredientes}}</p>
    </div>
    <div class="ingredientes" *ngFor="let control of ingredientesArray.controls; let i = index;">
        <mat-checkbox (change)="getselectedingredientes()" [formControl]="control" type="checkbox">{{ingredientess[i]}}</mat-checkbox>
    </div>
   
    

</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="omeletForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>