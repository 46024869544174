<h2 mat-dialog-title>Aguas de fruta</h2>

<mat-dialog-content class="pt-3" [formGroup]="aguafrutasForm">
    <h3>Tamaño</h3>
    <mat-radio-group formControlName="tamano" aria-label="Select an option">
        <mat-radio-button value="vaso">Vaso</mat-radio-button>
        <mat-radio-button value="copa">Copa</mat-radio-button>
    </mat-radio-group>
    <br><br>
    <h3>Fruta</h3>
    <mat-radio-group formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="piña">Piña</mat-radio-button>
        <mat-radio-button value="melon">Melón</mat-radio-button>
        <mat-radio-button value="sandia">Sandia</mat-radio-button>
        <mat-radio-button value="papaya">Papaya</mat-radio-button>
        <mat-radio-button value="limón">Limón</mat-radio-button>

    </mat-radio-group>

</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="aguafrutasForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>