<h2 mat-dialog-title>{{description}}</h2>

<mat-dialog-content class="pt-3" [formGroup]="ordenDePolloForm">
    <h3>Tipo de pollo</h3>
    <mat-radio-group formControlName="proteina" aria-label="Select an option">
        <mat-radio-button value="pollo natural">Pollo natural</mat-radio-button>
        <mat-radio-button value="pollo frito">Pollo frito</mat-radio-button>
        <mat-radio-button value="pollo especies">pollo especies</mat-radio-button>
        <mat-radio-button value="pollo bbq">Pollo bbq</mat-radio-button>
    </mat-radio-group>
    <br>
    <br>
    <h3>Aderezo</h3>
    <mat-radio-group formControlName="aderezo" aria-label="Select an option">
        <mat-radio-button value="cesar">César</mat-radio-button>
        <mat-radio-button value="Ranch">Ranch</mat-radio-button>
        <mat-radio-button value="italiano">Italiano</mat-radio-button>
        <mat-radio-button value="mil islas">Mil islas</mat-radio-button>
        <mat-radio-button value="chimichurry">Chimichurry</mat-radio-button>
        <mat-radio-button value="vinagre">Vinagre</mat-radio-button>
        <mat-radio-button value="mostaza miel">Mostaza miel</mat-radio-button>
    </mat-radio-group>

</mat-dialog-content>

<mat-dialog-actions align="center">

    <button class="mat-raised-button " mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="ordenDePolloForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>