<h2 mat-dialog-title>Wraps

</h2>

<mat-dialog-content class="pt-3" [formGroup]="wrapForm">
    <h3>Tipo de pollo</h3>
    <mat-radio-group (change)="comprobarBagVegetaria($event)" formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="jamón">Jamón</mat-radio-button>
        <mat-radio-button value="pollo natural">Pollo natural</mat-radio-button>
        <mat-radio-button value="pollo frito">Pollo frito</mat-radio-button>
        <mat-radio-button value="panela">Panela</mat-radio-button>
        <mat-radio-button value="vegetariano">Vegetariano</mat-radio-button>

    </mat-radio-group>
    <h3>Aderezo</h3>
    <mat-radio-group formControlName="aderezo" aria-label="Select an option">
        <mat-radio-button value="cesar">César</mat-radio-button>
        <mat-radio-button value="Ranch">Ranch</mat-radio-button>
        <mat-radio-button value="italiano">Italiano</mat-radio-button>
        <mat-radio-button value="mil islas">Mil islas</mat-radio-button>
        <mat-radio-button value="chimichurry">Chimichurry</mat-radio-button>
        <mat-radio-button value="vinagre">Vinagre</mat-radio-button>
        <mat-radio-button value="mostaza miel">Mostaza miel</mat-radio-button>
    </mat-radio-group>

    <div *ngIf="toggleVeg == true">
        <h4>Selecciona tus ingredientes</h4>
        <div *ngIf="selectedingredientes.length >= 4">
            <p class="text-center text-danger">Solo se permite 4 ingredientes</p>
        </div>
        <div class="opciones" *ngIf="selectedingredientes.length >= 0">
            <p class="text-center ">{{selectedingredientes}}</p>
        </div>

        <div class="ingredientes" *ngFor="let control of ingredientesArray.controls; let i = index;">

            <!-- AQUI HAY QUE HACE RQUE SE DEJEN DE MARCAR CUANDO YA HAY MAS DE 4  -->
            <mat-checkbox (change)="getselectedingredientes()" [formControl]="control" type="checkbox">{{ingredientess[i]}}</mat-checkbox>
        </div>

      
        <br>
    </div>


</mat-dialog-content>

<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="wrapForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>