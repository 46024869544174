<h2 mat-dialog-title>Baguette

</h2>



<mat-dialog-content class="pt-3" [formGroup]="baguetteForm">
    <h3>Tipo de Baguette</h3>
    <h5 class="fw-bold">Descripción: {{tipobaguette}}</h5>
    <mat-radio-group (change)="comprobarBagVegetaria($event)" formControlName="tipo" aria-label="Select an option">
        <mat-radio-button value="frances">Frances</mat-radio-button>
        <mat-radio-button value="marino">Marino</mat-radio-button>
        <mat-radio-button value="light">Light</mat-radio-button>
        <mat-radio-button value="italiano">Italiano</mat-radio-button>
        <mat-radio-button value="veneciano">Veneciano</mat-radio-button>
        <mat-radio-button value="suizo">Suizo</mat-radio-button>
        <mat-radio-button value="vegetariano">Vegetariano</mat-radio-button>
        <mat-radio-button value="hawaiiano">Hawaiiano</mat-radio-button>
        <mat-radio-button value="tradicional">Tradicional</mat-radio-button>
        <mat-radio-button value="mediterraneo">mediterraneo</mat-radio-button>
        <mat-radio-button value="napolitano">Napolitano</mat-radio-button>

    </mat-radio-group>

    <div *ngIf="toggleVeg == true">
        <h4>Selecciona tus 4 ingredientes</h4>
         <div *ngIf="selectedingredientes.length >= 4">
            <p class="text-center text-danger">Solo se permite 4 ingredientes</p>
        </div>
        <div  class="opciones" *ngIf="selectedingredientes.length >= 0">
            <p class="text-center ">{{selectedingredientes}}</p>
        </div>
        <div class="ingredientes" *ngFor="let control of ingredientesArray.controls; let i = index;">

            <!-- AQUI HAY QUE HACE RQUE SE DEJEN DE MARCAR CUANDO YA HAY MAS DE 4  -->
            <mat-checkbox (change)="getselectedingredientes()" [formControl]="control" type="checkbox">{{ingredientess[i]}}</mat-checkbox>
        </div>

        <br>
    </div>

    <h3>Aderezo</h3>
    <mat-radio-group formControlName="aderezo" aria-label="Select an option">
        <mat-radio-button value="cesar">César</mat-radio-button>
        <mat-radio-button value="Ranch">Ranch</mat-radio-button>
        <mat-radio-button value="italiano">Italiano</mat-radio-button>
        <mat-radio-button value="mil islas">Mil islas</mat-radio-button>
        <mat-radio-button value="chimichurry">Chimichurry</mat-radio-button>
        <mat-radio-button value="vinagre">Vinagre</mat-radio-button>
        <mat-radio-button value="mostaza miel">Mostaza miel</mat-radio-button>
    </mat-radio-group>
<div >
    <mat-checkbox *ngIf="baguetteForm.value.tipo === 'frances'"  [disabled]="baguetteForm.value.tipo === 'frances' " class="example-margin" (change)="voltear($event)">
        Pollo agregado
    </mat-checkbox>
    <mat-checkbox *ngIf="baguetteForm.value.tipo != 'frances'" [disabled]="baguetteForm.value.tipo === 'frances' " class="example-margin" (change)="voltear($event)">
        Agregar extra de pollo
    </mat-checkbox>
</div>

<div *ngIf="toggleFran===true">
  <h4>Selecciona tus proteina</h4>
  <mat-radio-group formControlName="proteina" aria-label="Select an option">
      <mat-radio-button value="pollo natural">Pollo natural</mat-radio-button>
      <mat-radio-button value="pollo bbq">Pollo bbq</mat-radio-button>
      <mat-radio-button value="pollo frito">Pollo frito</mat-radio-button>
      <mat-radio-button value="pollo especies">pollo especies</mat-radio-button>
  </mat-radio-group>

</div>
</mat-dialog-content>


<mat-dialog-actions align="center">

    <button mat-raised-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button class="verde" [disabled]="baguetteForm.invalid" (click)="save()">Agregar</button>

</mat-dialog-actions>
