import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import mapboxgl from 'mapbox-gl';
if (environment.production) {
  enableProdMode();
}

 
mapboxgl.accessToken = 'pk.eyJ1Ijoic3Jtb2F6IiwiYSI6ImNrcWlrdjNhNTA3OXAyb3IzM3QzNGhta3cifQ.BdPuiohGIbPknEV5i7RQ8g';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
